import React, { useEffect, useRef, useState, useCallback } from 'react'
//import { Box } from '@mui/material'
import * as fabric from 'fabric' // v6
//import { fabric } from 'fabric'; // v5
//import { fabric } from "fabric";
import { Box, ToggleButton, ToggleButtonGroup } from '@mui/material'
// import { IconButton } from '../ui'
// import { makeStyles } from '@mui/styles'

// import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
// import { ACTION_ALL_ACCESS, ACTION_EDIT } from '../app/appConstants'
import {
  CloseOutlined,
  DeleteOutlined,
  PentagonOutlined,
  RectangleOutlined,
} from '@mui/icons-material'

//declare module 'react-multiline-clamp'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
// import Clamp from 'react-multiline-clamp'
// import { Button } from '../ui'
//import { URL } from 'url'
import { v4 as uuidv4 } from 'uuid'
import { ReadOnlyLeftPanel } from '../map/MarkerNavigator'
import { WeAreMapFeature } from '../map/MapEdit'
import MarkerEditor from '../map/MarkerEditor'
import { feature } from '@turf/helpers'
// interface FabricToolboxProps {
//   fabricCanvas: fabric.Canvas | null
// }
//const FabricControls = forwardRef(({ someParam }, canvasRef) => {
import { fetchIndividual } from '../viewer/viewerSlice'
import { INSTANCE_TYPE_INDIVIDUAL } from '../app/links'
import { useDispatch, useSelector } from 'react-redux'
import { selectAuthorisedTreeSlug } from 'src/modules/auth/authSlice'
//import { createPortal } from 'react-dom'
import { useHistory } from 'react-router-dom'

import { generateLinkForObject } from 'src/modules/app/links'

const debug = true

const FabricToolbox = ({
  //fabricCanvas,
  activeTool,
  onToolChanged,
  onToolboxClosed,
}: {
  //fabricCanvas: fabric.Canvas | null
  activeTool: string | null
  onToolChanged?: CallableFunction
  onToolboxClosed?: CallableFunction
}) => {
  // const FabricToolbox = forwardRef(
  //   ({ fabricCanvas }: FabricToolboxProps, ref) => {
  //const [callMeToSetMode, setCallMeToSetMode] = useState(null)
  //const callMeToSetModeRef = useRef<CallableFunction | null>(null) // the function returned from setupToolboxCanvas() which sets (non react) state creationTool within setupToolboxCanvas()
  //var callMeToSetMode = null
  if (debug)
    console.debug(
      //`FabricToolbox: rendering... initialActiveTool: ${initialActiveTool}`
      `FabricToolbox: rendering... activeTool: ${activeTool}`
    )

  // const [activeTool, setActiveTool] = useState<string | null | undefined>(
  //   initialActiveTool
  // )

  // useImperativeHandle(ref, () => ({
  //   showAlert() {
  //     alert('Child Function Called')
  //   },
  // }))

  // const drawingModeCleared = () => {
  //   console.debug(`FabricToolbox.drawingModeCleared(): called!`)
  //   setActiveTool(null)
  // }

  // useEffect(() => {
  //   if (fabricCanvas) {
  //     console.debug(
  //       `FabricToolbox.useEffect(): fabricCanvas set, calling setupToolboxCanvas()...`
  //     )
  //     const fn = setupToolboxCanvas(fabricCanvas, drawingModeCleared)
  //     //setCallMeToSetMode(fn)
  //     //callMeToSetMode = fn
  //     callMeToSetModeRef.current = fn
  //     console.debug(
  //       `FabricToolbox.useEffect(): called setCallMeToSetMode with fn`,
  //       fn
  //     )
  //   }
  // }, [fabricCanvas])

  // if (!fabricCanvas) {
  //   return null
  // }

  // const addRectangle = () => {
  //   console.debug(
  //     `AnnotatedImageFabric.FabricControls.addRectangle(): canvas: `,
  //     fabricCanvas
  //   )
  //   if (!fabricCanvas) {
  //     return
  //   }
  //   const canvas = fabricCanvas
  //   // create a rectangle object
  //   var rect = new fabric.Rect({
  //     left: 100,
  //     top: 100,
  //     fill: 'red',
  //     width: 20,
  //     height: 20,
  //   })

  //   // "add" rectangle onto canvas
  //   canvas.add(rect)
  // }

  // const setRectangleMode = () => {
  //   console.debug(
  //     `AnnotatedImageFabric.FabricToolbox.setRectangleMode(): canvas: `,
  //     fabricCanvas
  //   )
  //   //setFabricToolboxMode('rectangle')
  //   if (callMeToSetModeRef.current) {
  //     console.debug(
  //       `AnnotatedImageFabric.FabricToolbox.setRectangleMode(): callMeToSetModeRef.current set, calling it with 'rect'...`
  //     )
  //     callMeToSetModeRef.current('rect')
  //   } else {
  //     console.debug(
  //       `AnnotatedImageFabric.FabricToolbox.setRectangleMode(): callMeToSetModeRef.current is NOT SET!`
  //     )
  //   }
  // }

  // const onClick = toolName: string => {
  //   setActiveTool(toolName)

  //   if (callMeToSetModeRef.current) {
  //     console.debug(
  //       `AnnotatedImageFabric.FabricToolbox.setRectangleMode(): callMeToSetModeRef.current set, calling it with 'rect'...`
  //     )
  //     callMeToSetModeRef.current(toolName)
  //   } else {
  //     console.debug(
  //       `AnnotatedImageFabric.FabricToolbox.setRectangleMode(): callMeToSetModeRef.current is NOT SET!`
  //     )
  //   }
  // }

  /*
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string | null*/

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newActiveTool: string | null
  ) => {
    console.debug(
      `AnnotatedImageFabric.FabricToolbox.handleChange(): called with newActiveTool`,
      newActiveTool
    )

    if (newActiveTool === 'close') {
      //setActiveTool(null)
      if (onToolChanged) {
        onToolChanged(null) // a FabricToolbox prop
      }
      if (onToolboxClosed) {
        onToolboxClosed() // a FabricToolbox prop
        return
      }
    }

    //setActiveTool(newActiveTool)

    if (onToolChanged) {
      onToolChanged(newActiveTool)
    }

    // if (callMeToSetModeRef.current) {
    //   console.debug(
    //     `AnnotatedImageFabric.FabricToolbox.handleChange(): callMeToSetModeRef.current set, calling it with 'rect'...`
    //   )
    //   callMeToSetModeRef.current(newActiveTool)
    // } else {
    //   console.debug(
    //     `AnnotatedImageFabric.FabricToolbox.handleChange(): callMeToSetModeRef.current is NOT SET!`
    //   )
    // }
  }

  return (
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        right: 0,
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'column',
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
        borderBottomLeftRadius: 4,
        borderBottomRightRadius: 4,
        padding: 0,
        marginRight: '20px',
        marginTop: '20px',
        zIndex: 2, // on top of the crop/edit buttons
      }}
    >
      <>
        {/* {...control}  */}
        <ToggleButtonGroup
          value={activeTool}
          size="small"
          aria-label="Small sizes"
          orientation="vertical"
          exclusive
          onChange={handleChange}
        >
          <ToggleButton value="close">
            {' '}
            <CloseOutlined />
          </ToggleButton>
          <ToggleButton value="rect">
            {' '}
            <RectangleOutlined />
          </ToggleButton>
          <ToggleButton value="poly">
            {' '}
            <PentagonOutlined />
          </ToggleButton>
          <ToggleButton value="delete">
            {' '}
            <DeleteOutlined />
          </ToggleButton>
        </ToggleButtonGroup>

        {/* <IconButton
            permissionAction={ACTION_ALL_ACCESS}
            onClick={() => onClick('rect')}
            //color="primary.darkGrey"
            backgroundColor="#000000"
          >
            <RectangleOutlined fontSize="small" background={'black'} />
          </IconButton>
          <IconButton
            permissionAction={ACTION_ALL_ACCESS}
            onClick={setRectangleMode}
          >
            <ArrowUpwardIcon color="primary.darkGrey" fontSize="small" />
          </IconButton> */}
      </>
      {/* <Typography
          onClick={setRectangleMode}
          fontWeight={600}
          sx={{ cursor: 'pointer', textAlign: 'center' }}
          pb={0.5}
        >
          {`Rect`}
        </Typography> */}
    </Box>
  )
}
//)

const newShapeDrawingProps = {
  fill: 'pink',
  strokeWidth: 1,
  stroke: 'red',
  opacity: 0.5,
  selectable: false,
  strokeUniform: true, // otherwise the border gets wider when the shape is scaled
}

const shapePropsEditing = {
  ...newShapeDrawingProps,
  stroke: 'black',
  selectable: true,
  transparentCorners: false,
  cornerColor: 'purple',
  cornerStrokeColor: 'black',
  cornerSize: 15,
  //borderColor: 'black',
}

const shapePropsViewOnly = {
  fill: '#ffffff00',
  stroke: '#ffffff00',
  selectable: false,
  // transparentCorners: false,
  // cornerColor: 'purple',
  // cornerStrokeColor: 'black',
  // cornerSize: 15,
  //borderColor: 'black',
}
const shapePropsViewOnlyHovered = {
  ...shapePropsViewOnly,
  fill: 'red',
  //stroke: '#ffffff00',
  stroke: 'black',
}

//const shapeMouseOverFillColourViewOnly = 'red'
//const shapeNormalFillColourViewOnly = '#ffffff00'

interface FabricJSCanvasProps {
  //callMeWithCanvas?: CallableFunction
  annotationSvg: string | null
  file: string
  originalImageWidthPx: number
  originalImageHeightPx: number
  calledByCanvasWhenActiveToolCleared: CallableFunction
  calledByCanvasWhenShapeSelected: CallableFunction
  calledByCanvasWhenShapeClickedInViewMode: CallableFunction
  //callMeToGetActiveTool: CallableFunction
  activeTool: string | null
  calledByCanvasWithSerialisedDrawingsOnDismount?: CallableFunction
  //onFabricObjectsCreated?: CallableFunction
  onFabricObjectsCreated?: (fabricObjects: fabric.Object[]) => void
  getExtraSvgProperties?: CallableFunction
  selectedObjectWeAreId?: string
  isEditMode?: boolean
}
//export const FabricJSCanvas = forwardRef(({ someParam }, ref) => {
//export

const serializableShapeProperties = [
  'weare_id',
  'weare_target_id',
  'weare_target_instance_type',
]

const FabricJSCanvas = ({
  //callMeWithCanvas, // passed AnnotatedImage.setCanvas
  annotationSvg,
  //  onCanvasClick
  file,
  originalImageWidthPx,
  originalImageHeightPx,
  calledByCanvasWhenActiveToolCleared,
  calledByCanvasWhenShapeSelected,
  calledByCanvasWhenShapeClickedInViewMode,
  //callMeToGetActiveTool,
  activeTool,
  calledByCanvasWithSerialisedDrawingsOnDismount,
  onFabricObjectsCreated,
  getExtraSvgProperties,
  selectedObjectWeAreId,
  isEditMode,
}: // TODO add calledByCanvasWithSerialisedDrawingsWhenToolboxClosed
FabricJSCanvasProps) => {
  const canvasEl = useRef<HTMLCanvasElement>(null)
  const fabricCanvasRef = useRef<fabric.Canvas | null>(null)

  // store the function returned from setupToolboxCanvas()
  // which sets (non react) state creationTool within
  // setupToolboxCanvas()
  const callMeToTellCanvasActiveToolChangedRef =
    useRef<CallableFunction | null>(null)

  console.debug(
    `FabricJSCanvas(): rendering... isEditMode: ${isEditMode}, file: ${file}`
  )

  // const findFeatureWithIdLocal = (
  //   featuresParam: Array<WeAreMapFeature> | undefined,
  //   id: string
  // ) => {
  //   return featuresParam?.find(si => si.id === id)
  // }

  //const patchFabricObjectToSVG = (fabricObject: fabric.FabricObject<Partial<fabric.FabricObjectProps>, fabric.SerializedObjectProps, fabric.ObjectEvents>) => {
  const patchFabricObjectsToSVGFn = useCallback(
    (
      fabricObject: fabric.FabricObject
      //findMapFeatureById: CallableFunction
    ) => {
      // custom 'weare_id' attribute in the XML
      // if (fabricObject.get('toSVGOverridden')) {
      //   return
      // }
      //fabricObject.set('toSVGOverridden', true)

      if (!fabricObject.get('toSVGOrig')) {
        fabricObject.set('toSVGOrig', fabricObject.toSVG)
      } else {
        fabricObject.toSVG = fabricObject.get('toSVGOrig')
      }
      fabricObject.toSVG = (function (toSVG) {
        return function () {
          // called as each Fabric object is serialised to SVG by serialiseDrawingsToSvg()
          if (!serializableShapeProperties) {
            return toSVG.call(this)
          }

          const extraSvgAttrs: Record<string, any> = {}

          const weareId = this.get('weare_id')
          if (!weareId) {
            console.error(
              `FabricJSCanvas.fabricObject.toSVG(): fabricObject.get('weare_id') not set, not patching generated SVG.`
            )
            return toSVG.call(this)
          }

          extraSvgAttrs['weare_id'] = weareId
          //const weareMapFeature = findMapFeatureById(weareId)
          if (getExtraSvgProperties) {
            const extraSvgProperties = getExtraSvgProperties(weareId)
            console.debug(
              `FabricJSCanvas.fabricObject.toSVG(): extraSvgProperties for weareId '${weareId}':`,
              extraSvgProperties
            )
            Object.entries(extraSvgProperties).forEach(([k, v]) => {
              extraSvgAttrs[k] = v
            })
          } else {
            console.warn(
              `FabricJSCanvas.fabricObject.toSVG(): getExtraSvgProperties() not set, not patching generated SVG.`
            )
          }

          // serializableShapeProperties.forEach(p => {
          //   const value = this.get(p)
          //   if (value) {
          //     values[p] = value
          //   }
          // })

          if (Object.keys(extraSvgAttrs).length === 0) {
            return toSVG.call(this)
          }

          // const weareId = values['weare_id']
          // if (!weareId) {
          //   console.error(
          //     `FabricJSCanvas.setupToolboxCanvas.endCreation(): fabricObject.toSVG(): fabricObject.get('weare_id') not set, not patching generated SVG.`
          //   )
          //   return toSVG.call(this)
          // }
          const svgString = toSVG.call(this)
          const domParser = new DOMParser()
          const doc = domParser.parseFromString(svgString, 'image/svg+xml')
          //const firstChild = doc.getRootNode().childNodes[0]
          const firstChildNode = doc.childNodes[0]
          //let parentG = doc.querySelector('path')
          // if (!parentG) {
          //   parentG = doc.querySelector('rect')
          // }
          console.debug(
            `FabricJSCanvas.fabricObject.toSVG(): doc.childNodes[0] returned: `,
            firstChildNode
          )
          // rootNode = rootNode.getRootNode()
          // console.debug(
          //   `object.toSVG(): doc.getRootNode().getRootNode() returned: `,
          //   rootNode
          // )
          if (firstChildNode && firstChildNode instanceof Element) {
            Object.entries(extraSvgAttrs).forEach(([k, v]) => {
              firstChildNode.setAttribute(k, v)
            })
            console.debug(
              `FabricJSCanvas.fabricObject.toSVG(): doc.childNodes[0] is an Element, patched generated SVG with values:`,
              extraSvgAttrs
            )
          } else {
            console.error(
              `FabricJSCanvas.fabricObject.toSVG(): doc.childNodes[0] is not an Element, not patching generated SVG with weare_id. firstChildNode:`,
              firstChildNode
            )
          }
          return doc.documentElement.outerHTML
        }
      })(fabricObject.toSVG)
    },
    [getExtraSvgProperties]
  )

  /**
   * Removes the background image
   * serializes the shapes to SVG
   * Restores the background image
   *
   * Called by useEffect dismount in FabricJSCanvas
   *
   * @param canvas
   * @returns
   */
  const serialiseDrawingsToSvg = useCallback(
    (
      canvas: fabric.Canvas
      //features?: Array<WeAreMapFeature>
    ) => {
      let oldBgImage
      if (canvas.backgroundImage) {
        oldBgImage = canvas.backgroundImage
        canvas.remove(canvas.backgroundImage)
        canvas.backgroundImage = undefined
      }
      //canvas.deactivateAll()
      canvas.setZoom(1)
      canvas.absolutePan(new fabric.Point(0, 0))
      canvas.renderAll()
      let svgString = undefined
      console.debug(
        `FabricJSCanvas.serialiseDrawingsToSvg(): canvas.getObjects().length: ${
          canvas.getObjects().length
        }`
      )
      if (canvas.getObjects().length > 0) {
        // const findFeatureWithIdCaptured = (weareId: string) => {
        //   return findFeatureWithIdLocal(features, weareId)
        // }
        canvas.getObjects().forEach(fabricObject => {
          patchFabricObjectsToSVGFn(fabricObject)
        })
        svgString = canvas.toSVG({}, null as any)
        console.debug(
          `FabricJSCanvas.serialiseDrawingsToSvg(): svgString: ${svgString}`
        )
      }

      if (oldBgImage) {
        canvas.backgroundImage = oldBgImage

        canvas.requestRenderAll()
      }

      return svgString
    },
    [patchFabricObjectsToSVGFn]
  )

  const canvasOnMouseOverViewOnly = useCallback(
    (
      e: fabric.TPointerEventInfo<fabric.TPointerEvent> //& OutEvent
    ) => {
      if (!e.target) {
        return
      }
      // for (const [key, value] of Object.entries(shapePropsViewOnlyHovered)) {
      //   e.target.set(key, value)
      // }
      console.debug(
        `FabricJSCanvas.canvasOnMouseOverViewOnly(): called with event:`,
        e
      )
      calledByCanvasWhenShapeSelected(e.target)
      e.target.set(shapePropsViewOnlyHovered)
      //e.target.selectable = false

      fabricCanvasRef.current?.renderAll()
    },
    [calledByCanvasWhenShapeSelected]
  )

  const canvasOnMouseOutViewOnly = useCallback(
    (
      e: fabric.TPointerEventInfo<fabric.TPointerEvent> //& OutEvent
    ) => {
      if (!e.target) {
        return
      }
      console.debug(
        `FabricJSCanvas.canvasOnMouseOutViewOnly(): called with event:`,
        e
      )
      //e.target?.set('fill', shapeNormalFillColourViewOnly)
      //Object.assign(e.target, shapePropsViewOnly)
      // for (const [key, value] of Object.entries(shapePropsViewOnly)) {
      //   e.target.set(key, value)
      // }
      calledByCanvasWhenShapeSelected(null)
      e.target.set(shapePropsViewOnly)
      //e.target.selectable = false
      fabricCanvasRef.current?.renderAll()
    },
    [calledByCanvasWhenShapeSelected]
  )

  const canvasOnMouseDownViewMode = useCallback(
    (fabricEvent: fabric.TPointerEventInfo<fabric.TPointerEvent>) => {
      calledByCanvasWhenShapeClickedInViewMode(fabricEvent.target)
    },
    [calledByCanvasWhenShapeClickedInViewMode]
  )

  const setCanvasEditable = useCallback(
    (canvas: fabric.Canvas, editable: boolean) => {
      console.debug(`setCanvasEditable(): called with editable: ${editable}`)

      canvas.selection = editable
      // canvas.forEachObject(function (o) {
      //   o.selectable = editable
      // })
      //canvas.hoverCursor = setTo ? 'move' : 'grab'

      const shapeProps = editable ? shapePropsEditing : shapePropsViewOnly

      canvas.forEachObject(fabricObject => {
        fabricObject.set(shapeProps)
      })

      canvas.hoverCursor = editable ? 'move' : 'pointer'
      if (!editable) {
        canvas.on('mouse:over', canvasOnMouseOverViewOnly)
        canvas.on('mouse:out', canvasOnMouseOutViewOnly)

        canvas.on('mouse:down', canvasOnMouseDownViewMode)
      } else {
        canvas.off('mouse:over', canvasOnMouseOverViewOnly)
        canvas.off('mouse:out', canvasOnMouseOutViewOnly)
        canvas.off('mouse:down', canvasOnMouseDownViewMode)
      }
      canvas.requestRenderAll()
      console.debug(
        `setCanvasEditable(): set canvas.selection to ${editable} and canvas.forEachObject() to set selectable to ${editable}.`
      )
    },
    [
      canvasOnMouseDownViewMode,
      canvasOnMouseOutViewOnly,
      canvasOnMouseOverViewOnly,
    ]
  )

  // this switches the canvas between view-only and editable
  useEffect(() => {
    console.debug(
      `FabricJSCanvas.useEffect([isEditable]): isEditable is ${isEditMode}...`
    )

    if (!fabricCanvasRef.current) {
      console.debug(
        `FabricJSCanvas.useEffect([isEditable]): fabricCanvasRef not set, doing nothing.`
      )
      return
    }

    const setTo = isEditMode ?? false
    setCanvasEditable(fabricCanvasRef.current, setTo)
  }, [isEditMode, setCanvasEditable])

  /**
   * Patches the toSVG() method of a fabric.FabricObject to add custom
   * attributes into the XML: 'weare_id', 'weare_target_id', 'weare_target_instance_type'
   *
   * Called by serialiseDrawingsToSvg()
   *
   */

  // on dismount, serialize all shapes to SVG
  // and call calledByCanvasWithSerialisedDrawingsOnDismount()...
  useEffect(() => {
    console.debug(
      `FabricJSCanvas.useEffect([calledByCanvasWithSerialisedDrawingsOnDismount, serialiseDrawingsToSvg]): mounting, noop`
    )

    return () => {
      console.debug(
        `FabricJSCanvas.useEffect([calledByCanvasWithSerialisedDrawingsOnDismount, serialiseDrawingsToSvg]): dismounting; calling calledByCanvasWithSerialisedDrawingsOnDismount() if provided...`
      )

      if (calledByCanvasWithSerialisedDrawingsOnDismount) {
        if (fabricCanvasRef.current) {
          // let features
          // setFeatures((existingFeatures: any) => {
          //   features = existingFeatures
          // })
          console.debug(
            `FabricJSCanvas.useEffect([calledByCanvasWithSerialisedDrawingsOnDismount, serialiseDrawingsToSvg]): calling serialiseDrawingsToSvg()...`
          )
          const svgString = serialiseDrawingsToSvg(
            fabricCanvasRef.current
            //features
          )
          calledByCanvasWithSerialisedDrawingsOnDismount(svgString)
        }
      }
    }
  }, [calledByCanvasWithSerialisedDrawingsOnDismount, serialiseDrawingsToSvg])

  useEffect(() => {
    return () => {
      console.debug(
        `FabricJSCanvas.useEffect().dismount: canvasEl changed - disposing of canvas and clearing fabricCanvasRef. canvasEl now: ${canvasEl}`,
        canvasEl
      )

      if (fabricCanvasRef.current) {
        fabricCanvasRef.current.dispose()
        fabricCanvasRef.current = null
      }
      // if (callMeWithCanvas) {
      //   callMeWithCanvas(null)
      // }
    }
  }, [canvasEl]) //, callMeWithCanvas])

  //const canvasRef = ref
  // react to selectedObjectWeAreId changing by setting the canvas' active object
  useEffect(() => {
    console.debug(
      `FabricJSCanvas.useEffect([selectedObjectWeAreId]): selectedObjectWeAreId: '${selectedObjectWeAreId}'`
    )

    // shapes not selectable in view-only mode
    if (!isEditMode) {
      return
    }

    if (!fabricCanvasRef.current) {
      return
    }

    const canvas = fabricCanvasRef.current
    if (!canvas) {
      return
    }

    const existingActiveObject = canvas.getActiveObject()

    if (selectedObjectWeAreId) {
      if (existingActiveObject) {
        if (existingActiveObject.get('weare_id') === selectedObjectWeAreId) {
          console.debug(
            `FabricJSCanvas.useEffect([selectedObjectWeAreId]): selectedObjectWeAreId '${selectedObjectWeAreId}' already active, doing nothing.`
          )
          return
        }
      }
      console.debug(
        `FabricJSCanvas.useEffect([selectedObjectWeAreId]): looking in canvas for object with weare_id: '${selectedObjectWeAreId}'...`
      )
      const canvasObject = canvas
        .getObjects()
        .find(o => o.get('weare_id') === selectedObjectWeAreId)
      if (canvasObject) {
        console.debug(
          `FabricJSCanvas.useEffect([selectedObjectWeAreId]): setting canvas' active object to object with weare_id: '${selectedObjectWeAreId}': `,
          canvasObject
        )

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        canvas.setActiveObject(canvasObject, { defaultPrevented2: true })
        canvas.requestRenderAll()
      }
    } else {
      if (existingActiveObject) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        canvas.discardActiveObject({ defaultPrevented2: true })
      }
    }
  }, [isEditMode, selectedObjectWeAreId])

  useEffect(() => {
    console.debug(
      `FabricJSCanvas.useEffect(): considering setting up fabric canvas...`
    )
    if (fabricCanvasRef.current) {
      console.debug(
        `FabricJSCanvas.useEffect(): fabricCanvasRef.current already set, doing nothing.`
      )
      return
    }
    console.debug(
      `FabricJSCanvas.useEffect(): fabricCanvasRef.current not set, creating Fabric.canvas()...`
    )
    const options = { uniformScaling: false }
    const canvas = new fabric.Canvas(canvasEl.current ?? undefined, options)

    fabricCanvasRef.current = canvas

    // make the fabric.Canvas instance available to your app
    //updateCanvasContext(canvas)
    //fabricCanvasRef.current = canvas
    //canvasRef.current = canvas

    //canvas.setBackgroundImage(file)
    //canvas.setDimensions({ width: '100%', height: '100%' }, { cssOnly: true })

    canvas.setDimensions(
      { width: originalImageWidthPx, height: originalImageHeightPx }
      //{ backstoreOnly: true }
    )
    console.debug(
      `FabricJSCanvas.useEffect(): set canvas backstore dimensions to ${originalImageWidthPx} x ${originalImageHeightPx}`
    )

    async function setBackgroundImage(canvas: fabric.Canvas, file: string) {
      const fi = await fabric.FabricImage.fromURL(file)
      console.debug(
        `FabricJSCanvas.useEffect().setBackgroundImage(): created FabricImage from url '${file}`,
        fi
      )
      canvas.backgroundImage = fi
      // canvas.backgroundImage.toSVG = () => {
      //   return ''
      // }
      //canvas.renderAll()
      canvas.requestRenderAll()
    }

    console.debug(
      `FabricJSCanvas.useEffect(): calling setBackgroundImage(file: '${file}')...`
    )
    setBackgroundImage(canvas, file)
    console.debug(`FabricJSCanvas.useEffect(): setBackgroundImage() returned.`)

    if (annotationSvg) {
      //async function populateCanvasFromSvg(
      const populateCanvasFromSvg = async (
        canvas: fabric.Canvas,
        annotationSvg: string
      ) => {
        //debugger

        // type TSvgReviverCallback = (
        //   element: Element,
        //   fabricObject: fabric.FabricObject
        // ) => void;

        const wearePropertiesReviver = (
          //: fabric.typedefs.TSvgReviverCallback
          element: Element,
          fabricObject: fabric.FabricObject
        ) => {
          console.debug(
            `populateCanvasFromSvg().weareIdReviver(): element, fabricObject:`,
            element,
            fabricObject
          )

          const parentElement = element.parentElement
          console.debug(
            `populateCanvasFromSvg().weareIdReviver():  element.parentElement:`,
            parentElement
          )
          if (parentElement) {
            //const values: Record<string, any> = {}
            //const weareShapeProps: WeareShapeProps = {}
            //const features = []
            serializableShapeProperties.forEach(p => {
              const value = parentElement.getAttribute(p)
              if (value) {
                fabricObject.set(p, value)
                console.debug(
                  `populateCanvasFromSvg().weareIdReviver(): set fabricObject.${p} to ${value}`
                )
                // if (p in weareShapeProps) {
                //   const wasp = weareShapeProps as any
                //   wasp[p] = value
                // }
              }
            })
            // const weareId = parentElement.getAttribute('weare_id')
            // if (weareId) {
            //   console.debug(
            //     `populateCanvasFromSvg().weareIdReviver():parentElement has weare_id: ${weareId}`
            //   )
            //   fabricObject.set('weare_id', weareId)
            // } else {
            //   console.debug(
            //     `populateCanvasFromSvg().weareIdReviver(): parentElement does not have attr weare_id - parentElement:`,
            //     parentElement
            //   )
            // }

            // const shapePropsCollection = []
            // if (weareShapeProps) {
            //   const newFeature = createFeature(weareShapeProps)
            //   features.push(newFeature)
            // }

            // console.debug(
            //   `populateCanvasFromSvg().weareIdReviver(): calling setFeatures() with features:`,
            //   features
            // )
            // setFeatures(features)
          }
        }

        const loadedSVG = await fabric.loadSVGFromString(
          annotationSvg,
          wearePropertiesReviver
        ) //, (objects, options) => {
        // const svgData = fabric.util.groupSVGElements(
        //   loadedSVG.objects as any,
        //   loadedSVG.options
        // )
        // canvas.add(svgData)
        //loadedSVG.objects.forEach(canvas.add)

        //const features = []

        loadedSVG.objects.forEach(fabricObject => {
          if (fabricObject) {
            // const shapeProps = isEditMode
            //   ? shapePropsEditing
            //   : shapePropsViewOnly
            // fabricObject.set(shapeProps)

            // // top-level XML should have a 'weare_id' attribute we set when we serialized it
            // console.debug(
            //   `FabricJSCanvas.populateCanvasFromSvg(): inflated o:`,
            //   o
            // )
            // const weareId = null
            // if (weareId) {
            //   o.set('weare_id', weareId)
            // }

            //patchFabricObjectsToSVGFn(fabricObject)

            canvas.add(fabricObject)

            //createFeature(o)
          }
        })

        console.debug(
          `FabricJSCanvas.useEffect(): annotationSvg converted to fabric objects:`,
          loadedSVG.objects
        )

        if (onFabricObjectsCreated && loadedSVG.objects) {
          onFabricObjectsCreated(loadedSVG.objects as fabric.Object[])
        }
      } // end populateCanvasFromSvg()

      console.debug(
        `FabricJSCanvas.useEffect(): annotationSvg prop set, calling populateCanvasFromSvg() to add to canvas...`,
        annotationSvg
      )
      populateCanvasFromSvg(canvas, annotationSvg).then(() => {
        setCanvasEditable(canvas, false)
      })

      //canvas.add([objects])
      //canvas.add

      // var group = []
      // var loadedObjects = new fabric.Group(group)

      // // loadedObjects.set({
      // //   left: 100,
      // //   top: 100,
      // //   width: 175,
      // //   height: 175,
      // // })

      // canvas.add(loadedObjects)
      // canvas.renderAll()
      //})
    }

    // returns a callback fn allowing caller to set the current mode
    const setupToolboxCanvas = (
      canvas: fabric.Canvas,
      callMeToClearCurrentDrawingMode: CallableFunction,
      callMeWhenShapeSelected: CallableFunction
    ): CallableFunction | null => {
      if (!canvas) {
        console.debug(`setupToolboxCanvas(): called with null canvas`)
        return null
      }
      console.debug(`setupToolboxCanvas(): called with non-null canvas`, canvas)
      const clearDrawingModeOnComplete = true

      canvas.defaultCursor = 'grab'

      const maxZoom = 20
      const minZoom = 0.5

      // interface CreationToolProps = {
      //   isCreating: boolean,
      //   type: null,
      //   creatingObject: undefined,
      //   isDragging: false,
      //   startPosX: null,
      //   startPosY: null,
      //   lastPosX: string | null,
      //   lastPosY: null,
      // }
      const creationTool = {
        isCreating: false,
        type: null as string | null,
        creatingObject: null as fabric.FabricObject | null,
        isDragging: false,
        startPosX: null as number | null,
        startPosY: null as number | null,
        lastPosX: null as number | null,
        lastPosY: null as number | null,
        // used when creating a polygon
        polyLines: [] as Array<fabric.Line>,
        polyPoints: [] as Array<fabric.Point>,
        //lineCounter: 0 as number,
      }

      const onMouseDownEditMode = (
        fabricEvent: fabric.TPointerEventInfo<fabric.TPointerEvent>
        //creationTool: any,
        //canvas: fabric.Canvas,
      ) => {
        console.log(
          `on mouse:down: isEditMode: '${isEditMode}', creationTool: `,
          creationTool
        )

        console.log(`on mouse:down: canvas: `, canvas)

        // if (!isEditMode) {
        //   calledByCanvasWhenShapeClickedInViewMode(fabricEvent.target)

        //   return
        // }

        if (creationTool.isCreating) {
          fabricEvent.e.preventDefault()
          fabricEvent.e.stopPropagation()
          if (creationTool.type === 'rect') {
            //creatingObject.actionName: 'drag'
            // Object.entries(creationTool.creatingObject?.controls ?? {}).forEach(
            //   ([k, v], i) => {
            //     //v.set('actionName', 'drag')
            //     //v.actionName = 'drag'
            //     //v.actionName = 'skew' //fabric.controlsUtils.scaleOrSkewActionName
            //     //v.actionHandler = fabric.controlsUtils.skewHandlerX

            //     //fabric.controlsUtils.createPolyPositionHandler

            //     console.debug(
            //       `on mouse:up: getActiveObject().controls[${i}]: key: ${k}, value:`,
            //       v
            //     )
            //   }
            // )
            if (creationTool.creatingObject) {
              endCreation(creationTool.creatingObject)
            } else {
              console.error(
                `on mouse:down: creationTool.creatingObject not set, doing nothing.`
              )
            }
          } else if (creationTool.type === 'poly') {
            addPolyPoint(fabricEvent.scenePoint.x, fabricEvent.scenePoint.y)
          }
        } else if (creationTool.type) {
          fabricEvent.e.preventDefault()
          fabricEvent.e.stopPropagation()
          console.log(
            `on mouse:down: about to call startCreation, fabricEvent:`,
            fabricEvent
          )
          const creatingObject = startCreation(fabricEvent)

          if (creatingObject) {
            canvas.add(creatingObject)

            // if (canvas?.elements?.upper?.el.style) {
            //   canvas.elements.upper.el.style.cursor = 'nwse-resize'
            // }
          } //else {
          //endCreation()
          //}
        } else if (!canvas.getActiveObject()) {
          //} else {
          fabricEvent.e.preventDefault()
          fabricEvent.e.stopPropagation()
          //const event = fabricEvent.e
          // console.debug(
          //   `on mouse:down: creationTool.type and activeObject not set, starting drag within zoom. event.clientX: ${event.clientX},  event.clientY: ${event.clientY}`
          // )
          creationTool.isDragging = true
          //creationTool.selection = false
          creationTool.lastPosX = fabricEvent.viewportPoint.x
          creationTool.lastPosY = fabricEvent.viewportPoint.y

          if (canvas?.elements?.upper?.el.style) {
            canvas.elements.upper.el.style.cursor = 'grabbing'
          }
        }
      }

      canvas.on('mouse:down', onMouseDownEditMode)

      function pan(fabricEvent: fabric.TPointerEventInfo) {
        // const x = fabricEvent.e.clientX
        // const y = fabricEvent.e.clientY

        const x = fabricEvent.viewportPoint.x
        const y = fabricEvent.viewportPoint.y

        // console.debug(
        //   `on mouse:move: creationTool.isDragging is true, e.clientX: ${e.clientX}, e.clientY: ${e.clientY}, fabricEvent:`,
        //   fabricEvent
        // )
        const vpt = canvas.viewportTransform
        //console.debug(`on mouse:move: vpt:`, vpt)
        if (!vpt[4]) {
          vpt[4] = 0
        }
        if (!vpt[5]) {
          vpt[5] = 0
        }
        vpt[4] += x - (creationTool.lastPosX ?? 0)
        vpt[5] += y - (creationTool.lastPosY ?? 0)
        //canvas.setViewportTransform(vpt)
        canvas.requestRenderAll()
        creationTool.lastPosX = x
        creationTool.lastPosY = y
      }

      // called on mouse move if we have started creating a rectangle
      function sizeNewRect(fabricEvent: fabric.TPointerEventInfo) {
        // const [adjustedX, adjustedY] = translateZoomedPannedCoords(
        //   fabricEvent.pointer.x,
        //   fabricEvent.pointer.y
        // )

        // if (adjustedX > creationTool.creatingObject.left) {
        //   width = adjustedX - creationTool.creatingObject.left
        // } else {
        //   width = creationTool.creatingObject.left - adjustedX
        // }
        // if (adjustedY > creationTool.creatingObject.top) {
        //   height = adjustedY - creationTool.creatingObject.top
        // } else {
        //   height = creationTool.creatingObject.top - adjustedY
        // }

        if (
          creationTool.creatingObject &&
          creationTool.startPosX !== null &&
          creationTool.startPosY !== null
        ) {
          const [adjustedX, adjustedY] = [
            fabricEvent.scenePoint.x,
            fabricEvent.scenePoint.y,
          ]

          let width, height

          if (adjustedX > creationTool.startPosX) {
            width = adjustedX - creationTool.startPosX
            creationTool.creatingObject.set('left', creationTool.startPosX)
          } else {
            width = creationTool.startPosX - adjustedX
            creationTool.creatingObject.set('left', adjustedX)
          }
          if (adjustedY > creationTool.startPosY) {
            height = adjustedY - creationTool.startPosY
            creationTool.creatingObject.set('top', creationTool.startPosY)
          } else {
            height = creationTool.startPosY - adjustedY
            creationTool.creatingObject.set('top', adjustedY)
          }

          // set the pointer style depending on where the pointer is relative to the start position
          if (
            (adjustedX > creationTool.startPosX &&
              adjustedY > creationTool.startPosY) ||
            (adjustedX < creationTool.startPosX &&
              adjustedY < creationTool.startPosY)
          ) {
            // below right of the start point or above left of the start point
            canvas.elements.upper.el.style.cursor = 'nwse-resize'
          } else {
            // above right of the start point or below left of the start point
            canvas.elements.upper.el.style.cursor = 'nesw-resize'
          }

          console.debug(
            `sizeNewObject(): fabricEvent.pointer.x: ${fabricEvent.pointer.x}, fabricEvent.pointer.y: ${fabricEvent.pointer.y}; adjustedX: ${adjustedX}, adjustedY: ${adjustedY}; creationTool.creatingObject.left: ${creationTool.creatingObject.left}, creationTool.creatingObject.top: ${creationTool.creatingObject.top}; width: ${width}   Height: ${height}`
          )
          creationTool.creatingObject.set('width', width)
          creationTool.creatingObject.set('height', height)
          creationTool.creatingObject.setCoords()
          //canvas.renderAll()
          canvas.requestRenderAll()
        }

        // if (adjustedX > creationTool.startPosX) {
        //   // to the right of the start point
        //   if (adjustedY > creationTool.startPosY) {
        //     // below right of the start point
        //     canvas.elements.upper.el.style.cursor = 'nwse-resize'
        //   } else {
        //     // above right of the start point
        //     canvas.elements.upper.el.style.cursor = 'nesw-resize'
        //   }
        // } else {
        //   // to the left of the start point
        //   if (adjustedY > creationTool.startPosY) {
        //     // below left of the start point
        //     canvas.elements.upper.el.style.cursor = 'nesw-resize'
        //   } else {
        //     //above left of the start point
        //     canvas.elements.upper.el.style.cursor = 'nwse-resize'
        //   }
        // }
      }

      canvas.on('mouse:move', fabricEvent => {
        //console.debug(`on mouse:move: canvas:`, canvas)

        if (creationTool.isCreating) {
          if (creationTool.type === 'rect') {
            sizeNewRect(fabricEvent)
          } else if (creationTool.type === 'poly') {
            //update the current line to have the new end coordinates
            moveEndOfNewestPolyLine(fabricEvent)
          }
        } else if (creationTool.isDragging) {
          pan(fabricEvent)
        } else {
          if (creationTool.type) {
            // not panning, not initiated creating yet, but has selected a tool
            canvas.elements.upper.el.style.cursor = 'crosshair'
          }
          // if (canvas?.elements?.upper?.el.style) {
          //   canvas.elements.upper.el.style.cursor = 'grab'
          // }
        }
      })

      canvas.on('mouse:up', function (fabricEvent) {
        if (creationTool.isDragging) {
          // on mouse up we want to recalculate new interaction
          // for all objects, so we call setViewportTransform
          canvas.setViewportTransform(canvas.viewportTransform)
          creationTool.isDragging = false
          //canvas.selection = true
          console.debug(
            `on mouse:up: canvas.viewportTransform[4]: ${canvas.viewportTransform[4]}, canvas.viewportTransform[5]: ${canvas.viewportTransform[5]}`,
            canvas.viewportTransform
          )
          // if (canvas?.elements?.upper?.el.style) {
          //   canvas.elements.upper.el.style.cursor = 'grab'
          // }
        } else {
          if (!creationTool.isCreating) {
            // this is costly and could make things a bit laggy, but is needed very occasionally
            // when a polygon's control is dragged
            // doesn't work :-/
            // const o = canvas.getActiveObject()
            // if (o) {
            //   Object.entries(o.controls).forEach(([k, v], i) => {
            //     console.debug(
            //       `on mouse:up: getActiveObject().controls[${i}]: key: ${k}, value:`,
            //       v
            //     )
            //   })
            //   console.debug(`on mouse:up: calling getActiveObject().setCoords()...`)
            //   //o.setCoords()
            // }
            // console.debug(`on mouse:up: calling canvas.requestRenderAll()...`)
            // canvas.requestRenderAll()
          }
        }
      })

      function endCreation(fabricObject: fabric.FabricObject) {
        // console.debug(
        //   `FabricJSCanvas.setupToolboxCanvas.endCreation(): creatingObject:`,
        //   creationTool.creatingObject
        // )
        console.debug(
          `FabricJSCanvas.setupToolboxCanvas.endCreation(): fabricObject:`,
          fabricObject
        )
        //if (fabricObject) {
        fabricObject.selectable = true
        // creationTool.creatingObject = {
        //   ...creationTool.creatingObject,
        //   ...shapeStyle,
        // }
        //Object.assign(creationTool.creatingObject, shapeStyle)

        //give the new shape our default styles
        fabricObject.set(shapePropsEditing)
        //creationTool.creatingObject.setBorder

        //give the new shape a unique ID
        fabricObject.set('weare_id', uuidv4())

        // extend new object's .toSVG() function to include

        //patchFabricObjectsToSVGFn(fabricObject)

        console.debug(
          `FabricJSCanvas.setupToolboxCanvas.endCreation(): after applying shapeStyle, fabricObject: `,
          fabricObject
        )
        //creationTool.creatingObject
        //creationTool.creatingObject.borderColor('black')

        //creationTool.creatingObject.setCoords()
        //canvas.renderAll()
        creationTool.creatingObject = null
        //}
        creationTool.isCreating = false
        if (clearDrawingModeOnComplete) {
          //creationTool.type = 'unknown'
          creationTool.type = null
          callMeToClearCurrentDrawingMode()
        }

        if (onFabricObjectsCreated) {
          onFabricObjectsCreated([fabricObject])
        }
      }

      canvas.on('mouse:wheel', function (fabricEvent) {
        const delta = fabricEvent.e.deltaY
        //var zoom = canvas.getZoom()
        //zoom *= 0.999 ** delta
        // if (zoom > maxZoom) zoom = maxZoom
        // if (zoom < minZoom) zoom = minZoom
        const zoom = Math.max(
          Math.min(canvas.getZoom() * 0.999 ** delta, maxZoom),
          minZoom
        )
        //if (zoom > 0.96 && zoom < 1.03) zoom = 1
        canvas.zoomToPoint(
          //{ x: fabricEvent.e.offsetX, y: fabricEvent.e.offsetY },
          new fabric.Point(fabricEvent.e.offsetX, fabricEvent.e.offsetY),
          zoom
        )
        console.debug(`on mouse:wheel: delta; ${delta}, set zoom to ${zoom}`)
        fabricEvent.e.preventDefault()
        fabricEvent.e.stopPropagation()
      })

      // function translateZoomedPannedCoords(pointerX, pointerY) {
      //   const vpt = canvas.viewportTransform
      //   console.debug(
      //     `translateZoomedPannedCoords(): called with pointerX: ${pointerX}, pointerY: ${pointerY}, canvas.viewportTransform:`,
      //     vpt
      //   )
      //   var adjustedX = pointerX
      //   var adjustedY = pointerY
      //   if (vpt) {
      //     adjustedX = pointerX - (vpt[4] ?? 0)
      //     adjustedY = pointerY - (vpt[5] ?? 0)
      //   }

      //   console.debug(
      //     `translateZoomedPannedCoords(): returning adjustedX: ${adjustedX}, adjustedY: ${adjustedY}`
      //   )
      //   return [adjustedX, adjustedY]
      // }

      function startCreation(fabricEvent: fabric.TPointerEventInfo) {
        console.debug(`startCreation(): called, fabricEvent:`, fabricEvent)
        console.debug(`startCreation(): called, creationTool:`, creationTool)
        creationTool.isCreating = true
        //const [adjustedX, adjustedY] = translateZoomedPannedCoords(fabricEvent.pointer.x, fabricEvent.pointer.y)

        const [adjustedX, adjustedY] = [
          fabricEvent.scenePoint.x,
          fabricEvent.scenePoint.y,
        ]

        //var pointer = canvas.getPointer(opt.e)

        creationTool.startPosX = adjustedX
        creationTool.startPosY = adjustedY
        if (creationTool.type === 'rect') {
          console.log(
            `startCreation(): drawing rect from: ${adjustedX}:${adjustedY}`,
            fabricEvent
          )
          creationTool.creatingObject = startRectCreation(adjustedY, adjustedX)
          return creationTool.creatingObject
        } else if (creationTool.type === 'poly') {
          startPolyCreation(adjustedX, adjustedY)
        } else if (creationTool.type === 'delete') {
          // do nothing
        } else {
          //endCreation()
          console.error(`startCreation(): Unknown type: ${creationTool.type}`)
        }

        return null
      }

      function startPolyCreation(x: number, y: number) {
        creationTool.polyPoints = []
        creationTool.polyLines = []
        //creationTool.lineCounter = 0
        addPolyPoint(x, y)

        // add a listener to draw the final line on double-click
        //fabric.util.addListener(window,'dblclick', function(){
        canvas.once('mouse:dblclick', function (fabricEvent) {
          // const makePolygon = (
          //   roofPoints: Array<fabric.Point>
          // ): fabric.Polyline => {
          //   // function findTopPaddingForRoof(roofPoints: Array<fabric.Point>) {
          //   //   let result = 999999
          //   //   for (let f = 0; f < creationTool.lineCounter; f++) {
          //   //     if (roofPoints[f].y < result) {
          //   //       result = roofPoints[f].y
          //   //     }
          //   //   }
          //   //   return Math.abs(result)
          //   // }

          //   // function findLeftPaddingForRoof(roofPoints: Array<fabric.Point>) {
          //   //   let result = 999999
          //   //   // finds min x value in all roofPoints
          //   //   for (let i = 0; i < creationTool.lineCounter; i++) {
          //   //     if (roofPoints[i].x < result) {
          //   //       result = roofPoints[i].x
          //   //     }
          //   //   }
          //   //   return Math.abs(result)
          //   // }

          //   // const left = findLeftPaddingForRoof(roofPoints)
          //   // const top = findTopPaddingForRoof(roofPoints)

          //   // make a copy of the first point at the end to close the polygon
          //   roofPoints.push(new fabric.Point(roofPoints[0].x, roofPoints[0].y))
          //   const polygon = new fabric.Polyline(roofPoints, {
          //     // fill: 'rgba(0,0,0,0)',
          //     // stroke: '#58c',
          //     ...newShapeProps,
          //   })
          //   // roof.set({
          //   //   left: left,
          //   //   top: top,
          //   // })

          //   return polygon
          // }

          //drawingObject.type = "";
          // clean up the temporary lines created when drawing the polygon
          creationTool.polyLines.forEach(function (value, index, ar) {
            canvas.remove(value)
          })
          //canvas.remove(lines[lineCounter - 1]);
          //const polygon = makePolygon(creationTool.polyPoints)

          // make a copy of the first point at the end to close the polygon
          creationTool.polyPoints.push(
            new fabric.Point(
              creationTool.polyPoints[0].x,
              creationTool.polyPoints[0].y
            )
          )
          const polygon = new fabric.Polyline(creationTool.polyPoints, {
            // fill: 'rgba(0,0,0,0)',
            // stroke: '#58c',
            ...shapePropsEditing,
          })

          polygon.controls = fabric.controlsUtils.createPolyControls(
            polygon.points.length,
            { cursorStyle: 'crosshair' } //pass custom options
          )

          canvas.add(polygon)
          canvas.requestRenderAll()

          //createPolyControls
          //createPolyControls.hello()
          //fabric.createPolyControls()
          // polygon.controls = fabric.controlsUtils.PolyControl.createPolyControls(
          //   polygon.points.length,
          //   { cursorStyle: 'crosshair' } //pass custom options
          // );

          creationTool.polyPoints = []
          creationTool.polyLines = []
          //creationTool.lineCounter = 0

          endCreation(polygon)
        })
      }

      //called as the mouse moves in-between points, just updates the newest
      //line's end point to follow the mouse
      function moveEndOfNewestPolyLine(
        fabricEvent: fabric.TPointerEventInfo<fabric.TPointerEvent>
      ) {
        const x = fabricEvent.scenePoint.x
        const y = fabricEvent.scenePoint.y
        if (
          creationTool.polyLines &&
          creationTool.polyLines.length > 0 &&
          creationTool.polyLines[0] !== null &&
          creationTool.polyLines[0] !== undefined
        ) {
          // creationTool.startPosX = x
          // creationTool.startPosY = y
          creationTool.polyLines[creationTool.polyLines.length - 1].set({
            x2: x,
            y2: y,
          })

          canvas.elements.upper.el.style.cursor = 'crosshair'

          canvas.requestRenderAll()
        }
      }

      function addPolyPoint(x: number, y: number) {
        console.log(
          `addPolyPoint(): called with ${x}, ${y}; creationTool:`,
          creationTool
        )

        canvas.selection = false
        //setStartingPoint(options) // set x,y
        creationTool.startPosX = x
        creationTool.startPosY = y
        //creationTool.roofPoints.push(new Point(x, y))
        creationTool.polyPoints.push(new fabric.Point(x, y))
        //const points = [x, y, x, y]
        const line = new fabric.Line([x, y, x, y], {
          // strokeWidth: 3,
          // selectable: false,
          // stroke: 'red',

          ...newShapeDrawingProps,
        })
        //line.originX = x
        //line.originY = y
        creationTool.polyLines.push(line)
        canvas.add(line)
        console.log(`addPolyPoint(): added line to canvas`, line)
        //creationTool.lineCounter++
        canvas.on('mouse:up', function (options) {
          canvas.selection = true
        })
      }

      function startRectCreation(top: number, left: number) {
        const rect = new fabric.Rect({
          top,
          left,
          ...newShapeDrawingProps,
        })
        //rect.controls
        //rect.selectable = false
        //rect.borderColor('black')
        // rect.setColor("green");
        // rect.opacity = 0.25;
        canvas.elements.upper.el.style.cursor = 'nwse-resize'

        return rect
      }

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      canvas.on('object:selected', (event: any) => {
        const selectedObject = event.target
        console.debug(
          'FabricJSCanvas.setupToolboxCanvas.onObjectSelected(): Selected object:',
          selectedObject
        )
      })
      // //canvas.on('selection:updated', function (fabricEvent) {
      // canvas.on('object:selected', function (fabricEvent) {
      //   console.debug(`selection:updated(): called with fabricEvent:`, fabricEvent)
      // })

      // called by selection:updated, selection:created, selection:cleared
      function onShapeSelected(event: any) {
        console.debug(
          `FabricJSCanvas.onShapeSelected(): called with event, creationTool:`,
          event,
          creationTool
        )
        if (!event.e || !event.e.defaultPrevented2) {
          if (event.selected) {
            if (event.selected.length === 1) {
              const fabricObject = event.selected[0]
              if (creationTool?.type === 'delete') {
                console.debug(
                  `FabricJSCanvas.onShapeSelected(): called when creationTool.type is delete - fabricObject:`,
                  fabricObject
                )
                canvas.remove(fabricObject)
                return
              }
              if (callMeWhenShapeSelected) {
                callMeWhenShapeSelected(fabricObject)
              }
            } else if (event.selected.length === 0) {
              if (callMeWhenShapeSelected) {
                callMeWhenShapeSelected(null)
              }
            }
          } else if (event.deselected) {
            if (canvas.getActiveObjects().length === 0) {
              if (callMeWhenShapeSelected) {
                callMeWhenShapeSelected(null)
              }
            }
          }
        }
      }

      canvas.on({
        'selection:updated': onShapeSelected,
        'selection:created': onShapeSelected,
        'selection:cleared': onShapeSelected,
      })

      // return a callback fn allowing caller to set the current mode
      return (mode: string | null) => {
        creationTool.type = mode
        //if (canvas?.elements?.upper?.el.style) {
        canvas.elements.upper.el.style.cursor = 'crosshair'
        //}
      }
    }

    console.debug(`FabricJSCanvas.useEffect(): calling setupToolboxCanvas()...`)
    const callbackFn = setupToolboxCanvas(
      canvas,
      calledByCanvasWhenActiveToolCleared,
      calledByCanvasWhenShapeSelected
    )
    //setCallMeToSetMode(fn)
    //callMeToSetMode = fn
    callMeToTellCanvasActiveToolChangedRef.current = callbackFn
    console.debug(
      `FabricJSCanvas.useEffect(): setupToolboxCanvas() returned, set callMeToTellCanvasActiveToolChangedRef with callbackFn`
    )

    canvas.requestRenderAll()

    //callback to the parent so they can have a reference to canvas
    // if (callMeWithCanvas) {
    //   callMeWithCanvas(canvas)
    // }

    //canvas.onClick = onCanvasClick
    //const callMeToSetMode = setupToolboxCanvas(canvas)

    //onCanvasClick({})
    //canvas.onClick = onCanvasClick
  }, [
    annotationSvg,
    originalImageWidthPx,
    originalImageHeightPx,
    file,
    calledByCanvasWhenActiveToolCleared,
    calledByCanvasWhenShapeSelected,
    onFabricObjectsCreated,
    setCanvasEditable,
    isEditMode,
    calledByCanvasWhenShapeClickedInViewMode,
  ])

  useEffect(() => {
    if (callMeToTellCanvasActiveToolChangedRef.current) {
      callMeToTellCanvasActiveToolChangedRef.current(activeTool)
    } else {
      console.debug(
        `FabricJSCanvas.useEffect([activeTool]): called when callMeToTellCanvasActiveToolChangedRef is not set - activeTool: ${activeTool}`
      )
    }
  }, [activeTool])

  return (
    <canvas
      // width="600"
      // height="600"
      // width="100%"
      // height="100%"
      ref={canvasEl}
      style={
        {
          //border: 'solid green 2px',
          //cursor: 'grab',
          //backgroundColor: 'yellow',
          // fabric.js' canvas overrides width/height - see https://github.com/fabricjs/fabric.js/issues/3344
          // width: '100% !important',
          // height: '100% !important',
          // marginLeft: auto,
          // marginRight: auto,
        }
      }
      //onClick={onCanvasClick}
    />
  )
}
//)

// const testShapeInfos: Array<ShapeInfo> = [
//   {
//     id: 'a',
//     linkTarget: {
//       id: 'sfsgqcfdg',
//       title: 'a hardcoded place',
//       type: 'place',
//       thumbnailUrl:
//         'https://www.telegraph.co.uk/multimedia/archive/02313/field_2313589b.jpg',
//     },
//   },
//   {
//     id: 'b',
//     linkTarget: {
//       id: 'yiuyiyorg',
//       title: 'a second hardcoded place',
//       type: 'place',
//       thumbnailUrl:
//         'https://www.telegraph.co.uk/multimedia/archive/02313/field_2313589b.jpg',
//     },
//   },
// ]

// const testFeature: WeAreMapFeature = {
//   id: '9mpejjt2ae5n',
//   title: 'Chapel Royal of St Peter Ad Vincula',
//   instanceType: 'location',
//   target: {
//     id: 'notUsed',
//     photo: {
//       id: '1234',
//       fileMedium: 'string',
//       fileThumbnail: '',
//     },
//   },
// }
// const testFeatures = [testFeature]

export type OnAnnotationSvgUpdatedCallback = (newSvgString?: string) => void

interface AnnotatedImageProps {
  file: string
  type: string
  annotationSvg: string | null
  //shapeInfos: Array<ShapeInfo>
  features: Array<WeAreMapFeature>
  originalImageWidthPx: number
  originalImageHeightPx: number
  editingImageAnnotations: boolean
  setEditingImageAnnotations: CallableFunction
  onAnnotationSvgUpdated: OnAnnotationSvgUpdatedCallback
  treeSlug: string
}
const AnnotatedImage = ({
  file,
  type,
  annotationSvg,
  //shapeInfos = testShapeInfos,
  originalImageWidthPx,
  originalImageHeightPx,
  editingImageAnnotations, // the caller will re-render this component with this prop 'true' when the user clicks the edit button
  setEditingImageAnnotations,
  onAnnotationSvgUpdated,
  treeSlug,
}: AnnotatedImageProps) => {
  //const fabricCanvasRef = useRef(null)
  //ref={fabricCanvasRef}
  //const fabricToolModeRef = useRef(null)

  // copy of canvas kept only so that onAnnotationSvgUpdated() can be called
  // when this component dismounts
  //const [canvas, setCanvas] = useState<fabric.Canvas | null>(null)
  // const [isEditingImageAnnotationsLocal, setIsEditingImageAnnotationsLocal] =
  //   useState<boolean | undefined>()
  const [currentSidebarShapeIdx, setCurrentSidebarShapeIdx] = useState(-1)

  //const [features, setFeatures] = useState(testFeatures)

  const [features, setFeatures] = useState<WeAreMapFeature[]>([])

  const history = useHistory()

  const treeSlugFromRedux = useSelector(selectAuthorisedTreeSlug)
  if (!treeSlug) {
    treeSlug = treeSlugFromRedux
  }

  // const [editingImageAnnotations, setEditingImageAnnotations] = useState(
  //   initialEditingImageAnnotations
  // )

  // useEffect(() => {
  //   if (
  //     isEditingImageAnnotationsLocal === null &&
  //     editingImageAnnotations !== null
  //   ) {
  //     setIsEditingImageAnnotationsLocal(editingImageAnnotations)
  //   }
  // }, [isEditingImageAnnotationsLocal, editingImageAnnotations])

  console.debug(
    `AnnotatedImage: rendering, treeSlug: ${treeSlug}, editingImageAnnotations: ${editingImageAnnotations}, features:`, //, isEditingDrawing: ${isEditingDrawing}`
    features
  )

  //const toolboxRef = useRef()

  //const [fabricToolboxMode, setFabricToolboxMode] = useState(null)

  // const onCanvasClick = useCallback(
  //   e => {
  //     console.debug(
  //       `onCanvasClick(): fabricToolboxMode: ${fabricToolboxMode}, event:`,
  //       e
  //     )
  //   },
  //   [fabricToolboxMode]
  // )

  // const serialiseDrawingsToSvg = (canvas: fabric.Canvas) => {
  //   if (canvas.backgroundImage) {
  //     canvas.remove(canvas.backgroundImage)
  //     canvas.backgroundImage = undefined
  //   }
  //   //canvas.deactivateAll()
  //   canvas.renderAll()
  //   let svgString = undefined
  //   console.debug(
  //     `AnnotatedImage.useEffect([canvas, onAnnotationSvgUpdated]): dismounting; calling onAnnotationSvgUpdated(), canvas.getObjects().length: ${
  //       canvas.getObjects().length
  //     }`
  //   )
  //   if (canvas.getObjects().length > 0) {
  //     svgString = canvas.toSVG({}, null as any)
  //     console.debug(
  //       `AnnotatedImage.useEffect([canvas, onAnnotationSvgUpdated]): dismounting; svgString: ${svgString}`
  //     )
  //   }

  //   return svgString
  // }

  // on dismount, serialize all shapes to SVG
  // and call onAnnotationSvgUpdated()...
  // useEffect(() => {
  //   console.debug(
  //     `AnnotatedImage.useEffect([canvas, onAnnotationSvgUpdated]): mounting`
  //   )

  //   return () => {
  //     console.debug(
  //       `AnnotatedImage.useEffect([canvas, onAnnotationSvgUpdated]): dismounting; calling onAnnotationSvgUpdated() if provided...`
  //     )

  //     if (onAnnotationSvgUpdated) {
  //       if (canvas) {
  //         const svgString = serialiseDrawingsToSvg(canvas)
  //         onAnnotationSvgUpdated(svgString)
  //       }
  //     }
  //   }
  // }, [canvas, onAnnotationSvgUpdated]) //, isEditingDrawing])

  // store the function returned from setupToolboxCanvas() which sets (non react) state creationTool within setupToolboxCanvas()
  // const callMeToTellCanvasActiveToolChangedRef =
  //   useRef<CallableFunction | null>(null)

  const [activeTool, setActiveTool] = useState<string | null>(null)

  // // this is passed to setupToolboxCanvas() and is called when a shape draw is completed or cancelled
  // const calledByToolboxWhenActiveToolChanged = (newActiveTool?: string) => {
  //   console.debug(
  //     `AnnotatedImage.calledByToolboxWhenActiveToolChanged(): called!`
  //   )
  //   setActiveTool(newActiveTool ?? null)
  // }

  //   // need to tell the canvas that the active tool has changed so it is ready to start shape creation on the next click

  //   if (callMeToTellCanvasActiveToolChangedRef.current) {
  //     console.debug(
  //       `AnnotatedImage.calledByToolboxWhenActiveToolCleared(): callMeToTellCanvasActiveToolChangedRef.current set, calling it with '${newActiveTool}'...`
  //     )
  //     callMeToTellCanvasActiveToolChangedRef.current(newActiveTool)
  //   } else {
  //     console.debug(
  //       `AnnotatedImage.calledByToolboxWhenActiveToolCleared(): callMeToTellCanvasActiveToolChangedRef.current is NOT SET!`
  //     )
  //   }
  // }

  // useEffect(() => {
  //   if (currentSidebarShapeIdx > -1) {
  //     const feature = features[currentSidebarShapeIdx]
  //     if (feature) {
  //       calledByCanvasWhenShapeSelected(feature)
  //     }
  //     const id = feature.id
  //     if (id) {
  //     }
  //   }
  // }, [currentSidebarShapeIdx])

  const calledByCanvasWhenActiveToolCleared = () => {
    setActiveTool(null)
  }

  const findFeatureWithIdParam = (
    id: string,
    featuresParam: WeAreMapFeature[]
  ): WeAreMapFeature | null => {
    let f = null
    // this can be called from a Fabric event which is outside the react context
    // so can't access local state directly

    f = featuresParam.find(si => si.id === id)

    console.debug(
      `AnnotatedImage.findFeatureWithIdParam(): done - called with id: '${id}', found feature:`,
      f
    )
    return f ?? null
  }

  const findFeatureWithIdSync = useCallback(
    (id: string): WeAreMapFeature | null => {
      let f = null
      // this can be called from a Fabric event which is outside the react context
      // so can't access local state directly

      f = findFeatureWithIdParam(id, features)

      console.debug(
        `AnnotatedImage.findFeatureWithIdSync(): done - called with id: '${id}', found feature:`,
        f
      )
      return f
    },
    [features]
  )

  // const findFeatureWithIdAsync = async (
  //   id: string
  // ): Promise<WeAreMapFeature | null> => {
  //   let f = null
  //   // this can be called from a Fabric event which is outside the react context
  //   // so can't access local state directly

  //   let localFeatures
  //   const fr = await setFeatures(existingFeatures => {
  //     localFeatures = existingFeatures
  //     console.debug(
  //       `AnnotatedImage.findFeatureWithIdAsync(): in setFeatures(), existingFeatures:`,
  //       existingFeatures
  //     )
  //     return existingFeatures
  //   })

  //   console.debug(
  //     `AnnotatedImage.findFeatureWithIdAsync(): awaited setFeatures(), localFeatures:`,
  //     localFeatures
  //   )
  //   if (localFeatures) {
  //     f = findFeatureWithIdParam(id, localFeatures)
  //   }
  //   console.debug(
  //     `AnnotatedImage.findFeatureWithIdAsync(): done - called with id: '${id}', found feature:`,
  //     f
  //   )
  //   return f
  // }

  const findFeatureWithIdViaSet = useCallback(
    (id: string) => {
      let feature
      setFeatures(existingFeatures => {
        feature = existingFeatures.find(si => si.id === id)
        if (!feature) {
          console.warn(
            `AnnotatedImage.findFeatureWithIdViaSet(): feature with id '${id}' not found in existingFeatures`,
            existingFeatures
          )
        } else {
          console.debug(
            `AnnotatedImage.findFeatureWithIdViaSet(): feature with id '${id}' found in existingFeatures:`,
            feature
          )
        }
        return existingFeatures
      })

      return feature
    },
    [] //features]
  )

  const findIndexOfFeatureWithId = useCallback(
    (id: string) => {
      let idx = -1
      setFeatures(existingFeatures => {
        idx = existingFeatures.findIndex(si => si.id === id)
        if (idx === -1) {
          console.warn(
            `AnnotatedImage.findIndexOfFeatureWithId(): feature with id '${id}' not found in existingFeatures`,
            existingFeatures
          )
        } else {
          console.debug(
            `AnnotatedImage.findIndexOfFeatureWithId(): feature with id '${id}' found in existingFeatures at idx ${idx}`
          )
        }
        return existingFeatures
      })

      return idx
    },
    [] //features]
  )

  // if the user clicks on a shape in the canvas, set that
  // shape as the one seen in the side info panel
  // This is called by FabricJSCanvas so outside the react context - will not have
  // up-to-date local state
  const calledByCanvasWhenShapeSelected = useCallback(
    (shape: fabric.FabricObject | null) => {
      console.debug(
        `AnnotatedImage.calledByCanvasWhenShapeSelected(): called with shape`,
        shape
      )
      if (!shape) {
        setCurrentSidebarShapeIdx(-1)
        return
      }
      const id = shape.get('weare_id')
      console.debug(
        `AnnotatedImage.calledByCanvasWhenShapeSelected(): called with shape with weare_id: ${id}`
      )
      if (id) {
        const idx = findIndexOfFeatureWithId(id)

        if (idx > -1) {
          console.debug(
            `AnnotatedImage.calledByCanvasWhenShapeSelected(): shape with weare_id ${id} is in features at index ${idx}`
          )
        } else {
          console.debug(
            `AnnotatedImage.calledByCanvasWhenShapeSelected(): shape with weare_id ${id} not found in features`
          )
        }
        //if (idx !== null && idx !== undefined) {
        // if (idx === -1) {
        //   idx = null
        // }

        console.debug(
          `AnnotatedImage.calledByCanvasWhenShapeSelected(): calling setCurrentSidebarShapeIdx(${idx})...`
        )
        setCurrentSidebarShapeIdx(idx)
        //}
      }
    },
    [findIndexOfFeatureWithId]
  )

  //TODO replace calledByCanvasWhenShapeClickedInViewMode with a href on the shape?
  const calledByCanvasWhenShapeClickedInViewMode = useCallback(
    (shape: fabric.FabricObject | null) => {
      console.debug(
        `AnnotatedImage.calledByCanvasWhenShapeClickedInViewMode(): called with shape`,
        shape
      )
      if (!shape) {
        return
      }
      const id = shape.get('weare_id')
      console.debug(
        `AnnotatedImage.calledByCanvasWhenShapeClickedInViewMode(): called with shape with weare_id: ${id}`
      )
      if (id) {
        const feature: any = findFeatureWithIdViaSet(id)
        console.debug(
          `AnnotatedImage.calledByCanvasWhenShapeClickedInViewMode(): found feature with id '${id}'`,
          feature
        )
        if (treeSlug && feature && feature.instanceType && feature.target?.id) {
          const newItemUrl = generateLinkForObject(
            treeSlug,
            feature.instanceType,
            feature.target.id
          )
          if (newItemUrl) {
            history.push(newItemUrl)
          }
        }
      }
    },
    [findFeatureWithIdViaSet, history, treeSlug]
  )

  // useEffect(() => {
  //   if (canvas) {
  //     console.debug(
  //       `AnnotatedImage.useEffect(): fabricCanvas set, calling setupToolboxCanvas()...`
  //     )

  //     // returns a callback fn allowing caller to set the current mode
  //     // so the canvas knows to start drawing a shape on the next click
  //     const callbackFn = setupToolboxCanvas(
  //       canvas,
  //       calledByCanvasWhenActiveToolCleared,
  //       calledByCanvasWhenShapeSelected
  //     )
  //     //setCallMeToSetMode(fn)
  //     //callMeToSetMode = fn
  //     callMeToTellCanvasActiveToolChangedRef.current = callbackFn
  //     console.debug(
  //       `AnnotatedImage.useEffect(): called setCallMeToSetMode with callbackFn`,
  //       callbackFn
  //     )
  //   }
  // }, [canvas, calledByCanvasWhenShapeSelected])

  // interface WeareShapeProps {
  //   weare_id?: string
  //   weare_target_id?: string
  //   weare_target_instance_type?: string
  // }

  //const createFeatureFromFabricObject = (weareShapeProps: WeareShapeProps): WeAreMapFeature => {
  const createFeatureFromFabricObject = (
    fabricObject: fabric.Object
  ): WeAreMapFeature => {
    //   const feature: WeAreMapFeature = {
    //   id: weareShapeProps.weare_id ?? '',
    //   // type: 'Feature',
    //   // geometry: {
    //   //   type: 'Point',
    //   //   coordinates: [newShapeProps.left, newShapeProps.top],
    //   // },
    //   //targetId: weareShapeProps.weare_target_id,
    //   instanceType: weareShapeProps.weare_target_instance_type,
    //   target: {
    //     id: weareShapeProps.weare_target_id,
    //   },
    //   // properties: {
    //   //   // title: weareShapeProps.title,
    //   //   // description: weareShapeProps.description,
    //   // },
    // }

    const feature: WeAreMapFeature = {
      id: fabricObject.get('weare_id') ?? '',
      // type: 'Feature',
      // geometry: {
      //   type: 'Point',
      //   coordinates: [newShapeProps.left, newShapeProps.top],
      // },
      //targetId: weareShapeProps.weare_target_id,
      target: {},
      // properties: {
      //   // title: weareShapeProps.title,
      //   // description: weareShapeProps.description,
      // },
    }

    if (
      fabricObject.get('weare_target_instance_type') &&
      fabricObject.get('weare_target_id')
    ) {
      feature.instanceType = fabricObject.get('weare_target_instance_type')
      feature.target.id = fabricObject.get('weare_target_id')
    }
    console.debug(
      `AnnotatedImage.createFeatureFromFabricObject(): called with fabricObject:`,
      fabricObject
    )
    console.debug(
      `AnnotatedImage.createFeatureFromFabricObject(): returning feature:`,
      feature
    )
    return feature
  }

  const onFabricObjectsCreated = async (fabricObjects: fabric.Object[]) => {
    console.debug(
      `AnnotatedImage.onFabricObjectsCreated(): called with fabricObjects:`,
      fabricObjects
    )
    const createdFeatures = fabricObjects.map(fo =>
      createFeatureFromFabricObject(fo)
    )
    console.debug(
      `AnnotatedImage.onFabricObjectsCreated(): createdFeatures:`,
      createdFeatures
    )
    console.debug(
      `AnnotatedImage.onFabricObjectsCreated(): existing features:`,
      features
    )
    // setFeatures(existingFeatures => {
    //   f = existingFeatures.find(si => si.id === id)
    //   return existingFeatures
    // })

    setFeatures(existingFeatures => {
      console.debug(
        `AnnotatedImage.onFabricObjectsCreated(): existingFeatures:`,
        existingFeatures
      )
      const newFeaturesToAdd = createdFeatures.filter(
        f => !findFeatureWithIdParam(f.id, existingFeatures)
      )
      //const newFeatures =
      console.debug(
        `AnnotatedImage.onFabricObjectsCreated(): adding features:`,
        newFeaturesToAdd
      )
      if (!newFeaturesToAdd.length) {
        console.debug(
          `AnnotatedImage.onFabricObjectsCreated().setFeatures(): newFeaturesToAdd empty, leaving features as they are`
        )
        return existingFeatures
      } else {
        const res = [...existingFeatures, ...newFeaturesToAdd]
        console.debug(
          `AnnotatedImage.onFabricObjectsCreated().setFeatures(): setting features to:`,
          res
        )
        return res
      }
    })
    console.debug(
      `AnnotatedImage.onFabricObjectsCreated(): features now:`,
      features
    )
  }

  const dispatch = useDispatch()

  useEffect(() => {
    console.debug(`AnnotatedImage.useEffect([features]): features:`, features)
    for (const f of features) {
      if (f.target && f.instanceType && f.target.id && !f.target.title) {
        console.debug(`AnnotatedImage.useEffect(): feature:`, f)
        if (f.instanceType === INSTANCE_TYPE_INDIVIDUAL) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          dispatch(fetchIndividual({ individualId: f.target.id })).then(
            (response: any) => {
              console.debug(
                `AnnotatedImage.useEffect([features]): fetched individual:`,
                response.payload
              )
              if (response.payload) {
                // const givenNameWithSpace = response.payload.givenName
                //   ? `${response.payload.givenName} `
                //   : ''
                // f.title = `${givenNameWithSpace}${response.payload.surame}`
                const givenName = response.payload.givenName || ''
                const surname = response.payload.surname || ''
                f.target.title =
                  givenName && surname
                    ? `${givenName} ${surname}`
                    : givenName || surname

                f.target.photo = {
                  id: response.payload.photo?.id,
                  fileThumbnail: response.payload.photo?.fileThumbnail,
                }
                console.debug(
                  `AnnotatedImage.useEffect([features]): set feature to:`,
                  feature
                )
                //force re-render because features have changed
                setFeatures([...features])
              }
            }
          )
        } else {
          const item: any = null //fetchItem(f.target.id, f.instanceType)
          //TODO lookup any item to get the title and photo
          console.debug(`AnnotatedImage.useEffect(): fetched item:`, item)
          if (item) {
            f.title = item.title
            if (item.fileMedium || item.fileThumbnail) {
              f.target.photo = {
                id: '',
                fileMedium: item.fileMedium,
                fileThumbnail: item.fileThumbnail,
              }
            }
          }
        }
      }
    }
  }, [dispatch, features])

  // called as each Fabric object is serialised to SVG from .toSVG() which is called by serialiseDrawingsToSvg called by a useEffect dismount
  const getExtraSvgProperties = useCallback(
    (weareId: string) => {
      console.debug(
        `AnnotatedImage.getExtraSvgProperties(): called with weareId: '${weareId}', features:`,
        features
      )
      const extraSvgProperties: Record<string, any> = {}

      const feature = findFeatureWithIdSync(weareId)
      console.debug(
        `AnnotatedImage.getExtraSvgProperties(): called with weareId: '${weareId}', found feature:`,
        feature
      )
      if (
        feature &&
        feature.target &&
        feature.target.id &&
        feature.instanceType
      ) {
        extraSvgProperties['weare_target_id'] = feature.target.id
        extraSvgProperties['weare_target_instance_type'] = feature.instanceType
      }
      return extraSvgProperties
    },
    [features, findFeatureWithIdSync]
  )

  return (
    <>
      <Box
        style={{
          background: 'black',
          width: '100%',
          height: '100%',
          display: 'flex',
          //alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <FabricJSCanvas //ref={fabricCanvasRef}
          //callMeWithCanvas={setCanvas} // this fn is called with the created fabric.js canvas
          annotationSvg={annotationSvg}
          //onCanvasClick={onCanvasClick}
          file={file}
          originalImageWidthPx={originalImageWidthPx}
          originalImageHeightPx={originalImageHeightPx}
          calledByCanvasWhenActiveToolCleared={
            calledByCanvasWhenActiveToolCleared
          }
          calledByCanvasWhenShapeSelected={calledByCanvasWhenShapeSelected}
          calledByCanvasWhenShapeClickedInViewMode={
            calledByCanvasWhenShapeClickedInViewMode
          }
          activeTool={activeTool}
          calledByCanvasWithSerialisedDrawingsOnDismount={
            onAnnotationSvgUpdated
          }
          onFabricObjectsCreated={onFabricObjectsCreated}
          getExtraSvgProperties={getExtraSvgProperties}
          selectedObjectWeAreId={features[currentSidebarShapeIdx]?.id}
          isEditMode={editingImageAnnotations}
        />

        {editingImageAnnotations && (
          <FabricToolbox
            //ref={toolboxRef} // canvasRef={fabricCanvasRef}
            //fabricCanvas={canvas}
            activeTool={activeTool}
            onToolChanged={setActiveTool}
            //onToolChanged={calledByToolboxWhenActiveToolChanged}
            //setFabricToolboxMode={setFabricToolboxMode}
            onToolboxClosed={() => {
              //setIsEditingDrawing(false)
              console.debug(
                `AnnotatedImage. onToolboxClosed(): calling setEditingImageAnnotations(false)`
              )
              setEditingImageAnnotations(false)
            }}
          />
        )}

        {features && (
          <DrawingInfosSidePanel
            features={features}
            setFeatures={setFeatures}
            currentIndex={currentSidebarShapeIdx}
            setCurrentIndex={setCurrentSidebarShapeIdx}
            //            initialFeatureIdx={currentSidebarShapeIdx}
            editMode={editingImageAnnotations}
            treeSlug={treeSlug}
          />
        )}
      </Box>
    </>
  )
}

// const canvas = new fabric.Canvas("fabric");
// canvas.setWidth(500).setHeight(500);

// let rect = new fabric.Rect({ height: 100, width: 100, fill: "red" });
// canvas.add(rect);

// interface LinkTarget {
//   id: string
//   type: string
//   title: string
//   thumbnailUrl?: string
// }

// interface ShapeInfo {
//   id: string
//   linkTarget?: LinkTarget
// }

// interface DrawingInfoPanelROProps {
//   shapeInfo: ShapeInfo
// }

interface DrawingSidePanelProps {
  //shapeInfos: Array<ShapeInfo>
  features: Array<WeAreMapFeature>
  setFeatures: CallableFunction
  //initialFeatureIdx?: number
  currentIndex: number
  setCurrentIndex: CallableFunction
  treeSlug: string
  editMode?: boolean
}

//const maxTitleLines = 4

// const DrawingInfoPanelRO = ({ shapeInfo }: DrawingInfoPanelROProps) => {
//   return (
//     <>
//       {/* <Link to={itemUrl} key={currentMarker.id}> */}
//       <Box p={2}>
//         {shapeInfo.linkTarget?.title && (
//           <Typography variant="h4">
//             <Clamp lines={maxTitleLines}>{shapeInfo.linkTarget.title}</Clamp>
//           </Typography>
//         )}
//         {shapeInfo.linkTarget?.type && (
//           <Typography variant="subtitle1" color="primary" lineHeight={1.4}>
//             {shapeInfo.linkTarget.type}
//           </Typography>
//         )}
//         {/* {showLatLong && currentMarker?.lat && currentMarker?.lng && (
//           <Typography variant="subtitle2" mt={0.5}>
//             {formatLocationCoords(currentMarker)}
//           </Typography>
//         )}
//         <Typography mt={1}>{currentMarker?.desc}</Typography> */}
//       </Box>

//       {shapeInfo.linkTarget?.thumbnailUrl && (
//         <Box style={{ flexGrow: 1 }}>
//           <img
//             src={shapeInfo.linkTarget.thumbnailUrl}
//             //width="100%"
//             //width="auto"
//             //height="auto"
//             style={{
//               objectFit: 'contain',
//               //maxWidth: '100px',
//               maxWidth: '100%',
//               //maxHeight: '100%',
//               maxHeight: '150px',
//               margin: 'auto',
//             }}
//             alt={shapeInfo.linkTarget.title}
//           />
//         </Box>
//       )}
//       {/* </Link> */}
//     </>
//   )
// }

// eslint-disable-next-line @typescript-eslint/no-empty-interface
//interface DrawingInfoPanelFormProps extends DrawingInfoPanelROProps {}

// interface DrawingInfoPanelFormProps {
//   shapeInfo: ShapeInfo
// }

// const DrawingInfoPanelSelectItem = ({
//   shapeInfo,
// }: DrawingInfoPanelFormProps) => {
//   return (
//     <>
//       {/* <Link to={itemUrl} key={currentMarker.id}> */}
//       <Box p={2}>
//         {shapeInfo.linkTarget?.title && (
//           <Typography variant="h4">
//             <Clamp lines={maxTitleLines}>{shapeInfo.linkTarget.title}</Clamp>
//           </Typography>
//         )}
//         {shapeInfo.linkTarget?.type && (
//           <Typography variant="subtitle1" color="primary" lineHeight={1.4}>
//             {shapeInfo.linkTarget.type}
//           </Typography>
//         )}
//         {/* {showLatLong && currentMarker?.lat && currentMarker?.lng && (
//           <Typography variant="subtitle2" mt={0.5}>
//             {formatLocationCoords(currentMarker)}
//           </Typography>
//         )}
//         <Typography mt={1}>{currentMarker?.desc}</Typography> */}
//       </Box>

//       {shapeInfo.linkTarget?.thumbnailUrl && (
//         <Box style={{ flexGrow: 1 }}>
//           <img
//             src={shapeInfo.linkTarget.thumbnailUrl}
//             //width="100%"
//             //width="auto"
//             //height="auto"
//             style={{
//               objectFit: 'contain',
//               //maxWidth: '100px',
//               maxWidth: '100%',
//               //maxHeight: '100%',
//               maxHeight: '150px',
//               margin: 'auto',
//             }}
//             alt={shapeInfo.linkTarget.title}
//           />
//         </Box>
//       )}
//       {/* </Link> */}
//     </>
//   )
// }

const DrawingInfosSidePanel = ({
  features,
  setFeatures,
  //initialFeatureIdx = -1,
  currentIndex,
  setCurrentIndex,
  treeSlug,
  editMode = false,
}: DrawingSidePanelProps) => {
  //const [currentIndex, setCurrentIndex] = useState(initialFeatureIdx)
  // const [currentShapeInfo, setCurrentShapeInfo] = useState<WeAreMapFeature>(
  //   features[currentIndex]
  // )

  const [currentFeature, setCurrentFeature] = useState<WeAreMapFeature>(
    features[currentIndex]
  )

  console.debug(
    `DrawingInfosSidePanel: rendering with currentIndex: ${currentIndex}, local state currentFeature already:`,
    currentFeature
  )

  // causes infinite re-render loop
  // setCurrentFeature(features[currentIndex])

  // console.debug(
  //   `DrawingInfosSidePanel: rendering with currentIndex: ${currentIndex}, local state currentFeature now:`,
  //   currentFeature
  // )
  // const navigate = (direction: bool) => {
  //   if (direction) {
  //     setCurrentIndex(currentIndex - 1))
  //   }
  // }
  useEffect(() => {
    console.debug(
      `DrawingInfosSidePanel.useEffect([currentIndex, setCurrentFeature, features]): called with currentIndex: ${currentIndex}, features:`,
      features
    )
    const cf = features[currentIndex]
    console.debug(
      `DrawingInfosSidePanel.useEffect([currentIndex, setCurrentFeature, features]): called with currentIndex: ${currentIndex}, calling setCurrentFeature() with cf:`,
      cf
    )
    setCurrentFeature(cf)
  }, [currentIndex, setCurrentFeature, features])

  //const imageFeatures: Array<WeAreMapFeature> = []
  const navigateMarkers = (direction: boolean) => {
    console.debug(
      `DrawingInfosSidePanel.navigateMarkers(): called with direction: ${direction}`
    )
    const newIndex = direction ? currentIndex + 1 : currentIndex - 1

    if (newIndex < 0) {
      setCurrentIndex(features.length - 1)
    } else if (newIndex >= features.length) {
      setCurrentIndex(0)
    } else {
      setCurrentIndex(newIndex)
    }
  }

  return (
    <>
      <Box
        sx={{
          position: 'absolute', // absolute allows this box to overlay the map
          top: 0,
          left: 0,
          marginLeft: '54px', // go to the right of the 'previous image' arrow button
          marginTop: '60px', // drop below the X close button mounted in MediaDetail
          display: 'flex',
          flexDirection: 'column',
          //height: '100%',
          //position: 'relative',
          maxWidth: '400px',
          //padding: '10px',
          //border: 'solid 1px black',
          borderRadius: '4px',
          backgroundColor: 'white',
        }}
      >
        {editMode ? (
          <MarkerEditor
            //id={id}
            //currentMap={undefined}
            locations={features}
            setLocations={setFeatures}
            //editableMapTitle={undefined}
            //setMapTitle={setMapTitle}
            currentMarker={currentFeature}
            setCurrentMarkerIndex={setCurrentIndex}
            flyToEditMode={() => {
              //noop
            }}
            dropPinMode={false}
            setDropPinMode={undefined}
            offerLinkSelector={true}
          />
        ) : (
          <ReadOnlyLeftPanel
            weareMapFeatures={features}
            navigateMarkers={navigateMarkers}
            navigateToMarkerIndex={setCurrentIndex}
            currentSelectedFeature={currentFeature}
            treeSlug={treeSlug}
            allowThreeD={false}
            flyToMarkersIn2d={undefined}
            setFlyToMarkersIn2d={undefined}
            smallMode={undefined}
            allowList={false}
            allowPrevNext={false}
          />
        )}

        {/* {currentShapeInfo && (
          <DrawingInfoPanelRO shapeInfo={currentShapeInfo} />
        )}
        <Box
          sx={{
            display: 'flex',
            //justifyContent: 'space-between',
            //gap: '5px',
            gap: 1,

            // stick the buttons to the bottom of the side panel
            position: 'sticky',
            bottom: 0,
            //width: '100%',
            backgroundColor: 'white',
            p: 1,
          }}
        >
          <Button
            permissionAction={ACTION_ALL_ACCESS}
            onClick={() => setCurrentIndex(currentIndex - 1)}
            disabled={currentIndex === 0}
            isLoading={false}
            permissionParams={undefined}
            className={undefined}
            sx={undefined}
          >
            Prev
          </Button>
          <Button
            permissionAction={ACTION_ALL_ACCESS}
            onClick={() => setCurrentIndex(currentIndex + 1)}
            disabled={shapeInfos.length <= currentIndex + 1}
            isLoading={false}
            permissionParams={undefined}
            className={undefined}
            sx={undefined}
          >
            Next
          </Button>
        </Box>*/}
      </Box>
    </>
  )
}

export default AnnotatedImage
