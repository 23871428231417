import { createSlice } from '@reduxjs/toolkit'
import pickBy from 'lodash/pickBy'

import api, { createWrappedAsyncThunk, handlePaginatedAction } from 'src/api'
import { INSTANCE_TYPE_PHOTO_ALBUM } from 'src/modules/app/links'
import { getTreeSlugFromStore } from 'src/modules/auth/utils'
import { fetchGoalsShortly } from 'src/modules/goals/goalsSlice'
import { setVisibility } from 'src/modules/visibility/visibilitySlice'
import { ga4Events, sendEvent } from '../analytics/AnalyticsUtils'

const SLICE_NAME = 'photo'
const PHOTO_PAGE_SIZE = 10

export const removeMediaFromAlbum = createWrappedAsyncThunk(
  `${SLICE_NAME}/removeMediaFromAlbum`,
  ({ contentId, mediaId, order }) => {
    return api.del(
      `/history/${getTreeSlugFromStore()}/content/${contentId}/album-media/${mediaId}/`,
      {
        body: { order },
      }
    )
  }
)

export const reorderPhotos = createWrappedAsyncThunk(
  `${SLICE_NAME}/reorderPhotos`,
  ({ contentId, id, photo, putBelowPhoto }) =>
    api.patch(
      `/history/${getTreeSlugFromStore()}/content/${contentId}/blocks/${id}/reorder/`,
      {
        body: { photo: photo, putBelowPhoto: putBelowPhoto },
      }
    )
)
export const createCroppedPhoto = createWrappedAsyncThunk(
  `${SLICE_NAME}/createCroppedPhoto`,
  ({ originalId, file, fileName, x, y, width, height }) => {
    const formData = new FormData()
    formData.append('original', originalId)
    formData.append('cropped', file, fileName)
    formData.append('x', x)
    formData.append('y', y)
    formData.append('height', height)
    formData.append('width', width)
    return api.post(
      `/history/${getTreeSlugFromStore()}/media/create/cropped/`,
      { body: formData }
    )
  }
)

export const cropExistingPhoto = createWrappedAsyncThunk(
  `${SLICE_NAME}/cropExistingPhoto`,
  ({ originalId, file, fileName }) => {
    const formData = new FormData()
    formData.append('cropped', file, fileName)
    return api.patch(
      `/history/${getTreeSlugFromStore()}/media/${originalId}/crop/`,
      { body: formData }
    )
  }
)

export const rotatePhotoLeft = createWrappedAsyncThunk(
  `${SLICE_NAME}/rotatePhotoLeft`,
  ({ photoId }) => {
    return api.patch(
      `/history/${getTreeSlugFromStore()}/media/${photoId}/rotate-left/`
    )
  }
)

export const rotatePhotoRight = createWrappedAsyncThunk(
  `${SLICE_NAME}/rotatePhotoRight`,
  ({ photoId }) => {
    return api.patch(
      `/history/${getTreeSlugFromStore()}/media/${photoId}/rotate-right/`
    )
  }
)
export const revertToOriginal = createWrappedAsyncThunk(
  `${SLICE_NAME}/revertToOriginal`,
  ({ photoId }) => {
    return api.patch(
      `/history/${getTreeSlugFromStore()}/media/${photoId}/revert/`
    )
  }
)

export const createMedia = createWrappedAsyncThunk(
  `${SLICE_NAME}/createMedia`,
  (
    {
      albumId,
      albumTitle,
      existingMedia = [],
      photosWithDescription = [],
      binaryFilesWithDescription = [],
      youTubeIdsWithDescription = [],
      targets = [],
      videoObjectNames = [],
    },
    { dispatch }
  ) => {
    const formData = new FormData()
    if (videoObjectNames.length) {
      formData.append('videoObjectNames', videoObjectNames)
    }
    existingMedia.forEach(existingId => {
      formData.append('existingMedia', existingId)
    })
    if (albumTitle) {
      formData.append('albumTitle', albumTitle)
    }
    if (albumId) {
      formData.append('album', albumId)
    }
    targets.forEach(targetId => {
      formData.append('targets', targetId)
    })
    photosWithDescription.forEach(({ file, id }) => {
      formData.append(id, file)
    })
    const photosData = {}
    photosWithDescription.forEach(
      ({ dateTakenGed, description, id, name, setCategory }) => {
        photosData[id] = {
          name,
          description,
          dateTakenGed,
          setCategory,
        }
      }
    )
    formData.append('photosData', JSON.stringify(photosData))

    binaryFilesWithDescription.forEach(({ file, id }) => {
      formData.append(id, file)
    })
    const binaryData = {}
    binaryFilesWithDescription.forEach(
      ({ dateTakenGed, description, id, name, setCategory }) => {
        binaryData[id] = {
          name,
          description,
          dateTakenGed,
          setCategory,
        }
      }
    )

    formData.append('binaryData', JSON.stringify(binaryData))

    youTubeIdsWithDescription.forEach(({ file, id }) => {
      formData.append(id, file)
    })
    const youtubeData = {}
    youTubeIdsWithDescription.forEach(
      ({ dateTakenGed, description, id, name, youtubeVideoId, timestamp }) => {
        youtubeData[id] = {
          name,
          description,
          dateTakenGed,
          youtubeVideoId: youtubeVideoId,
          timestamp,
        }
      }
    )

    formData.append('youtubeData', JSON.stringify(youtubeData))

    fetchGoalsShortly(dispatch)
    return api.put(
      `/history/${getTreeSlugFromStore()}/media/create/multiple/`,
      { body: formData }
    )
  }
)

export const uploadVideoToS3 = createWrappedAsyncThunk(
  `${SLICE_NAME}/uploadVideoToS3`,
  async ({ media, file, uploadData }) => {
    const formData = new FormData()

    // NOTE: S3 upload API is sensitive to parameter ordering. Do not
    // change the order in which fields are added to formData.
    for (const [key, value] of Object.entries(uploadData.fields)) {
      formData.append(key, value)
    }
    formData.append('file', file)
    formData.append('Content-Type', file.type)

    const response = await fetch(uploadData.url, {
      method: 'POST',
      mode: 'cors',
      body: formData,
    })
    await response.text()

    // Tell the backend that the upload has completed
    return api.patch(`/history/media/${media.id}/`, {
      body: { state: 'UPLOADED_S3' },
    })
  }
)

export const createPhotoComment = createWrappedAsyncThunk(
  `${SLICE_NAME}/createPhotoComment`,
  ({ mediaId, text }) => {
    return api.post(
      `/history/${getTreeSlugFromStore()}/media/${mediaId}/comment/`,
      {
        body: { text },
      }
    )
  }
)

export const deletePhotoComment = createWrappedAsyncThunk(
  `${SLICE_NAME}/deletePhotoComment`,
  ({ mediaId, commentId }) => {
    return api.del(
      `/history/${getTreeSlugFromStore()}/media/${mediaId}/comment/${commentId}/delete/`
    )
  }
)

export const fetchAlbum = createWrappedAsyncThunk(
  `${SLICE_NAME}/fetchAlbum`,
  ({ id, allMedia = true }) => {
    const queryStringParameters = {
      allMedia,
    }

    return api.get(`/history/${getTreeSlugFromStore()}/content/${id}/`, {
      queryStringParameters,
    })
  }
)

export const fetchContentBlockMedia = createWrappedAsyncThunk(
  `${SLICE_NAME}/fetchContentBlockMedia`,
  ({ id, page = 0 }) => {
    const limit = PHOTO_PAGE_SIZE
    const offset = page * limit
    const queryStringParameters = pickBy({
      offset,
      limit,
    })
    return api.get(
      `/history/${getTreeSlugFromStore()}/content_block/${id}/media/`,
      {
        queryStringParameters,
      }
    )
  }
)

export const deleteAlbum = createWrappedAsyncThunk(
  `${SLICE_NAME}/deleteAlbum`,
  ({ albumId }) => {
    return api.del(`/history/${getTreeSlugFromStore()}/content/${albumId}/`)
  }
)
export const updateAlbum = createWrappedAsyncThunk(
  `${SLICE_NAME}/updateAlbum`,
  ({ albumId, title, targets }) => {
    return api.patch(`/history/${getTreeSlugFromStore()}/content/${albumId}/`, {
      body: { title, targets: Array.from(targets) },
    })
  }
)

export const fetchMediaDetail = createWrappedAsyncThunk(
  `${SLICE_NAME}/mediaDetail`,
  ({ mediaId }) => {
    return api.get(`/history/${getTreeSlugFromStore()}/media/${mediaId}/`)
  }
)
export const getPhotoInstances = createWrappedAsyncThunk(
  `${SLICE_NAME}/getPhotoInstances`,
  ({ mediaId }) => {
    return api.get(
      `/history/${getTreeSlugFromStore()}/media/${mediaId}/get-instances/`
    )
  }
)
export const deletePhoto = createWrappedAsyncThunk(
  `${SLICE_NAME}/deletePhoto`,
  ({ mediaId, photoInstances }) => {
    return api.del(`/history/${getTreeSlugFromStore()}/media/${mediaId}/`, {
      body: {
        photoInstances,
      },
    })
  }
)
export const updatePhoto = createWrappedAsyncThunk(
  `${SLICE_NAME}/updatePhoto`,
  ({
    mediaId,
    title,
    description,
    dateTakenGed,
    targets,
    transcription,
    annotationsSvg,
  }) => {
    return api.patch(`/history/${getTreeSlugFromStore()}/media/${mediaId}/`, {
      body: {
        title,
        description,
        dateTakenGed,
        targets,
        transcription,
        annotationsSvg,
      },
    })
  }
)

export const fetchPhotosForSelector = createWrappedAsyncThunk(
  `${SLICE_NAME}/fetchPhotosForSelector`,
  ({
    targets,
    page,
    type,
    ordering,
    exclude_no_date_of_origin,
    pageId,
    treeSlug,
    includeOptionalFields,
  }) => {
    const limit = 7 // the whole page is restricted to a max width which fits 7
    let offset = page * limit
    if (!page) {
      offset = 0
    }
    const queryStringParameters = pickBy({
      offset,
      limit,
      target: Array.from(targets || []),
      type,
      ordering,
      exclude_no_date_of_origin,
      page_id: pageId,
      optional_fields: includeOptionalFields,
    })

    const slug = treeSlug || getTreeSlugFromStore()

    return api.get(`/history/${slug}/media/`, {
      queryStringParameters,
    })
  }
)

export const emptyAlbumState = {
  album: null,
  mediaDetail: {},
  photoOptions: {
    page: null,
    count: null,
    results: null,
  },
  contentBlockMedia: {
    page: null,
    count: null,
    results: null,
  },
}

export const initialState = {
  currentAlbumId: undefined,
  currentAlbumState: emptyAlbumState,
  albumCache: {},
}

const updateStateAfterPhotoAltered = (state, alteredMedia) => {
  state.currentAlbumState.mediaDetail = alteredMedia
  if (state.album) {
    state.currentAlbumState.album.media =
      state.currentAlbumState.album.media?.map(photo => {
        return photo.id === alteredMedia.id ? alteredMedia : photo
      })
  }
  state.currentAlbumState.photoOptions.results =
    state.currentAlbumState.photoOptions.results?.map(photo => {
      return photo.id === alteredMedia.id ? alteredMedia : photo
    })
}

const clearPhotoOptionsResultsLocal = state => {
  state.currentAlbumState.photoOptions.page = null
  state.currentAlbumState.photoOptions.count = null
  state.currentAlbumState.photoOptions.results = null
  state.currentAlbumState.contentBlockMedia.page = null
  state.currentAlbumState.contentBlockMedia.count = null
  state.currentAlbumState.contentBlockMedia.results = null
}

export const photosSlice = createSlice({
  name: SLICE_NAME,
  initialState,
  reducers: {
    resetAlbumToInitialState: state => initialState,
    resetAlbumState: (state, action) => {
      if (state.currentAlbumId) {
        state.albumCache[state.currentAlbumId] = state.currentAlbumState
      }
      state.currentAlbumState =
        state.albumCache[action.payload?.id] || emptyAlbumState
      state.currentAlbumId = action.payload?.id
    },
    setMediaDetailVisibility: (state, action) => {
      state.currentAlbumState.mediaDetail.visibility = action.payload
    },
    clearPhotoOptionsResults: (state, action) => {
      clearPhotoOptionsResultsLocal(state)
    },
  },
  extraReducers: {
    ...handlePaginatedAction(
      fetchContentBlockMedia,
      state => state.currentAlbumState.contentBlockMedia
    ),
    [fetchAlbum.fulfilled]: (state, { payload }) => {
      state.currentAlbumState.album = payload
    },
    [fetchMediaDetail.fulfilled]: (state, { payload }) => {
      state.currentAlbumState.mediaDetail = payload
    },
    [cropExistingPhoto.fulfilled]: (state, { payload }) => {
      updateStateAfterPhotoAltered(state, payload)
    },
    [rotatePhotoLeft.fulfilled]: (state, { payload }) => {
      updateStateAfterPhotoAltered(state, payload)
    },
    [rotatePhotoRight.fulfilled]: (state, { payload }) => {
      updateStateAfterPhotoAltered(state, payload)
    },
    [revertToOriginal.fulfilled]: (state, { payload }) => {
      updateStateAfterPhotoAltered(state, payload)
    },
    [createPhotoComment.fulfilled]: (state, { payload }) => {
      state.currentAlbumState.mediaDetail.comments.unshift(payload)
    },
    ...handlePaginatedAction(
      fetchPhotosForSelector,
      state => state.currentAlbumState.photoOptions,
      {
        setOnRequest: emptyAlbumState.photoOptions,
        fulfilled: (state, { meta }) => {
          //store the args that were used to filter/sort so that we know we can reuse the cached results
          state.currentAlbumState.photoOptions.args = {
            targets: meta.arg.targets,
            ordering: meta.arg.ordering,
            type: meta.arg.type,
            pageId: meta.arg.pageId,
          }
        },
      }
    ),
    [deletePhotoComment.fulfilled]: (state, { meta }) => {
      const deletedCommentId = meta.arg.commentId
      state.currentAlbumState.mediaDetail.comments =
        state.currentAlbumState.mediaDetail.comments.filter(
          comment => comment.id !== deletedCommentId
        )
      state.currentAlbumState.deletedCommentId = null
    },
    [createMedia.fulfilled]: (state, { payload: { album } }) => {
      // reducer also called for articles with Gallery blocks (documents) - ignore them!
      if (
        album?.albumContentBlockId &&
        state?.currentAlbumState &&
        state?.currentAlbumState?.album?.id === album?.id
      ) {
        state.currentAlbumState.album = album
      }
      clearPhotoOptionsResultsLocal(state)
      sendEvent(ga4Events.MEDIA_ADDED)
    },
    [deletePhoto.fulfilled]: (state, { payload: { album } }) => {
      clearPhotoOptionsResultsLocal(state)
    },
    [removeMediaFromAlbum.fulfilled]: (state, { payload }) => {
      // reducer also called for articles with Gallery blocks (documents) - ignore them!
      if (
        payload.albumContentBlockId &&
        state.currentAlbumState &&
        state.currentAlbumState.album.id === payload.id
      ) {
        state.currentAlbumState.album = payload
      }
    },
    [updateAlbum.fulfilled]: (state, { payload }) => {
      state.currentAlbumState.album = payload
    },
    [reorderPhotos.fulfilled]: (state, { payload }) => {
      const { media } = payload
      if (payload.type.toLowerCase() === INSTANCE_TYPE_PHOTO_ALBUM) {
        state.currentAlbumState.album.media = media
      }
    },
    [updatePhoto.fulfilled]: (state, { payload }) => {
      if (state.currentAlbumState.album) {
        const updatedMedia = state.currentAlbumState.album.media.map(m =>
          m.id === payload.id ? { ...m, ...payload } : m
        )
        state.currentAlbumState.album.media = updatedMedia
      }

      if (payload.transcription) {
        state.currentAlbumState.mediaDetail.transcription =
          payload.transcription
      }

      if (payload.annotationsSvg) {
        state.currentAlbumState.mediaDetail.annotationsSvg =
          payload.annotationsSvg
        console.debug(
          `photosSlice.updatePhoto.fulfilled(): state.currentAlbumState.mediaDetail:`,
          state.currentAlbumState.mediaDetail
        )
      }
    },
    [setVisibility.fulfilled]: (state, { meta }) => {
      const { instanceId: id, visibility } = meta.arg
      if (state.currentAlbumStatemediaDetail?.id === id) {
        state.mediaDetail.visibility = visibility
      }
      if (state.album?.id === id) {
        state.album.visibility = visibility
      }
    },
  },
})

export const {
  setMediaDetailVisibility,
  resetAlbumToInitialState,
  resetAlbumState,
  clearPhotoOptionsResults,
} = photosSlice.actions

export const selectAlbum = state => state[SLICE_NAME].currentAlbumState.album
export const selectAlbumMedia = state =>
  state[SLICE_NAME].currentAlbumState.contentBlockMedia
export const selectMediaDetail = state =>
  state[SLICE_NAME].currentAlbumState.mediaDetail
export const selectPhotoSelectorOptions = state =>
  state[SLICE_NAME].currentAlbumState.photoOptions

export default photosSlice.reducer
