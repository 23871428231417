import React, { useEffect, useState } from 'react'
import {
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  InputAdornment,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
} from '@mui/material'
import { makeStyles } from '@mui/styles'
import {
  Cancel as CancelIcon,
  ChevronRight as ChevronRightIcon,
  Link as LinkIcon,
} from '@mui/icons-material'
import { EditorState, Modifier } from 'draft-js'
import {
  getEntityRange,
  getSelectionEntity,
  getSelectionText,
} from 'draftjs-utils'
import { Formik } from 'formik'

import * as Yup from 'yup' // text entry validation
import { useSelector } from 'react-redux'

import { generateLinkForObject } from 'src/modules/app'
import { Button, FormikTextField, IconButton } from 'src/modules/ui'

import { MenuIconButton } from './MenuIconButton'
import { familiesDescendedFrom } from 'src/modules/viewer/api/nodeDirectory'
import {
  selectFamilies as selectAllFamilies,
  selectNodeDirectory,
} from 'src/modules/viewer/viewerSlice'
import {
  selectUserIndividualOnTree,
  selectAuthorisedTreeSlug,
} from 'src/modules/auth/authSlice'

import SearchLinkType, { useSearchLinkTypes } from './SearchLinkType'
import { ACTION_ALL_ACCESS } from '../../app/appConstants'

const useStyles = makeStyles(theme => ({
  linkForm: {
    minWidth: 300,
  },
}))

const InsertLink = ({ editorState, onChange }) => {
  const [open, setOpen] = useState(false)
  const [closing, setClosing] = useState(false)
  const treeSlug = useSelector(selectAuthorisedTreeSlug)

  const handleOpenModal = () => setOpen(true)
  const handleCloseModal = () => setOpen(false)
  const handleSubmit = values => {
    const newLink = !!text
    insertLink(treeSlug, editorState, onChange, newLink, {
      text: values.text,
      url: values.url,
      ...values.link,
    })
    setClosing(true)
  }

  const text = getExistingText(editorState)
  const link = getExistingLink(editorState)
  const url = getExistingWebLink(editorState)

  useEffect(() => {
    if (closing) {
      setOpen(false)
      setClosing(false)
    }
  }, [closing])

  return (
    <>
      <MenuIconButton onClick={handleOpenModal}>
        <LinkIcon fontSize="small" />
      </MenuIconButton>
      <Dialog
        disableAutoFocus={true}
        open={open}
        onClose={handleCloseModal}
        hideBackdrop
      >
        {open && (
          <LinkFormContainer
            link={link}
            onSubmit={handleSubmit}
            text={text}
            url={url}
          />
        )}
      </Dialog>
    </>
  )
}

export const LinkFormContainer = ({
  link,
  onSubmit,
  text,
  url,
  showTextField = true,
  buttonLabelCreate,
  buttonLabelUpdate,
  autoSubmit,
  hideListHeader,
}) => {
  const classes = useStyles()

  const initialValues = {
    text: text || '',
    link: link || null,
    url: url || '',
  }

  const linkTypes = useSearchLinkTypes()

  const validationSchema = Yup.object().shape({
    url: Yup.string()
      .nullable()
      .url('Must be a valid website URL beginning http:// or https://'),
  })

  return (
    <div className={classes.linkForm}>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
      >
        {formik => (
          <LinkForm
            {...formik}
            linkTypes={linkTypes}
            showTextField={showTextField}
            buttonLabelCreate={buttonLabelCreate}
            buttonLabelUpdate={buttonLabelUpdate}
            autoSubmit={autoSubmit}
            hideListHeader={hideListHeader}
          />
        )}
      </Formik>
    </div>
  )
}

const LinkForm = ({
  handleSubmit,
  initialValues,
  linkTypes,
  setFieldValue,
  values,
  showTextField = true,
  buttonLabelCreate = 'Insert Link',
  buttonLabelUpdate = 'Update Link',
  autoSubmit = false,
  hideListHeader = false,
}) => {
  const nodeDirectory = useSelector(selectNodeDirectory)
  const allFamilies = useSelector(selectAllFamilies)
  const userIndividualOnTree = useSelector(selectUserIndividualOnTree)

  const [type, setType] = useState()
  const [webLink, setWebLink] = useState(!!initialValues.url)

  const linkType = linkTypes.find(t => t.id === type)

  const descendedFamilies = familiesDescendedFrom(
    nodeDirectory,
    userIndividualOnTree,
    allFamilies
  )
  const descendedFromFamilyIds = new Set(
    descendedFamilies.map(({ family }) => family)
  )

  const handleBack = () => {
    setType()
  }

  const performSearch = value => {
    if (linkType && linkType.fetchData) {
      linkType.fetchData(value)
    }
  }

  const handleSelectType = type => {
    setType(type.id)
    setWebLink(false)
    if (type.fetchData) {
      type.fetchData('')
    }
  }

  const handleSelectWebLink = () => {
    setType()
    setWebLink(true)
    if (autoSubmit) {
      handleSubmit(values)
    }
  }

  const handleSelectLink = link => {
    console.debug(`LinkForm.handleSelectLink: called with link:`, link)
    setFieldValue('text', link.display)
    setFieldValue('link', link)
    setType()
    if (autoSubmit) {
      handleSubmit(values)
    }
  }

  const handleRemoveLink = () => {
    setFieldValue('text', initialValues.text)
    setFieldValue('link', null)
    if (autoSubmit) {
      handleSubmit(values)
    }
  }

  const handleRemoveWebLink = () => {
    setWebLink(false)
    setFieldValue('text', initialValues.text)
    setFieldValue('link', null)
    setFieldValue('url', '')
    if (autoSubmit) {
      handleSubmit(values)
    }
  }

  const submitText = initialValues.text ? buttonLabelUpdate : buttonLabelCreate
  const canSubmit = values.text && (values.link || values.url)

  return (
    <>
      {!type ? (
        <>
          {showTextField && (
            <DialogContent>
              <FormikTextField label="Text" name="text" fullWidth />
            </DialogContent>
          )}
          <List>
            {!hideListHeader && <ListSubheader>Link to...</ListSubheader>}
            {!values.link && !webLink ? (
              <>
                <ListItem button onClick={handleSelectWebLink}>
                  <ListItemText primary="Web Page" />
                  <ListItemSecondaryAction>
                    <ChevronRightIcon />
                  </ListItemSecondaryAction>
                </ListItem>
                <Divider />
                {linkTypes.map((type, idx) => (
                  <ListItem
                    button
                    onClick={() => handleSelectType(type)}
                    key={`${type}-${idx}`}
                  >
                    <ListItemText primary={type.label} />
                    <ListItemSecondaryAction>
                      <ChevronRightIcon />
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </>
            ) : webLink ? (
              <ListItem>
                <FormikTextField
                  label="Url"
                  name="url"
                  placeholder="Type link..."
                  fullWidth
                  helperText="The URL to the third-party resource, e.g. https://nationalarchives.org.uk/documents/23245345.pdf"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          permissionAction={ACTION_ALL_ACCESS}
                          size="small"
                          onClick={handleRemoveWebLink}
                        >
                          <CancelIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </ListItem>
            ) : (
              <ListItem>
                <Chip label={values.link.display} onDelete={handleRemoveLink} />
              </ListItem>
            )}
          </List>
          <DialogActions>
            <Button
              permissionAction={ACTION_ALL_ACCESS}
              color="primary"
              disabled={!canSubmit}
              onClick={handleSubmit}
            >
              {submitText}
            </Button>
            <>
              {!autoSubmit && (
                <Button
                  permissionAction={ACTION_ALL_ACCESS}
                  color="primary"
                  disabled={!canSubmit}
                  onClick={handleSubmit}
                >
                  {submitText}
                </Button>
              )}
            </>
          </DialogActions>
        </>
      ) : (
        <SearchLinkType
          linkType={linkType}
          onClickBack={handleBack}
          onSearch={performSearch}
          onSelectLink={handleSelectLink}
          highlightedOptionIDs={descendedFromFamilyIds}
        />
      )}
    </>
  )
}

const getExistingText = editorState => {
  const currentEntity = getSelectionEntity(editorState)
  if (
    currentEntity &&
    editorState.getCurrentContent().getEntity(currentEntity).get('type') ===
      'LINK'
  ) {
    const entityRange =
      currentEntity && getEntityRange(editorState, currentEntity)
    return entityRange && entityRange.text
  } else {
    return getSelectionText(editorState)
  }
}

const getExistingLink = editorState => {
  const currentEntity = getSelectionEntity(editorState)
  if (currentEntity) {
    const entity = editorState.getCurrentContent().getEntity(currentEntity)
    if (entity.get('type') === 'LINK') {
      const data = entity.get('data')
      // Support `contentType` for backwards compatibility
      if (data.contentType || data.instanceType) {
        const instanceType = data.contentType || data.instanceType
        return {
          instanceType: instanceType,
          display: data.contentDisplay,
          id: data.contentId,
        }
      }
    }
  }
}

const getExistingWebLink = editorState => {
  const currentEntity = getSelectionEntity(editorState)
  if (currentEntity) {
    const entity = editorState.getCurrentContent().getEntity(currentEntity)
    if (entity.get('type') === 'LINK') {
      const data = entity.get('data')
      if (!(data.contentType || data.instanceType)) {
        return data.url
      }
    }
  }
}

const insertLink = (treeSlug, editorState, onChange, newLink, link) => {
  // Taken from https://github.com/jpuri/react-draft-wysiwyg/blob/master/src/controls/Link/index.js
  let selection = editorState.getSelection()
  const currentEntity = getSelectionEntity(editorState)

  // Get current link if hovering over
  if (currentEntity) {
    const entityRange = getEntityRange(editorState, currentEntity)
    const isBackward = selection.getIsBackward()
    if (isBackward) {
      selection = selection.merge({
        anchorOffset: entityRange.end,
        focusOffset: entityRange.start,
      })
    } else {
      selection = selection.merge({
        anchorOffset: entityRange.start,
        focusOffset: entityRange.end,
      })
    }
  }

  // Create link entity
  let entityOptions
  if (link.instanceType) {
    let { display, text, instanceType, id } = link
    const url = generateLinkForObject(treeSlug, instanceType, id)
    entityOptions = {
      url,
      targetOption: '_self',
      title: text,
      instanceType,
      contentId: id,
      contentDisplay: display,
    }
  } else if (link.url) {
    const { text, url } = link
    entityOptions = {
      url,
      targetOption: '_blank',
      title: text,
    }
  }

  const entityKey = editorState
    .getCurrentContent()
    .createEntity('LINK', 'MUTABLE', entityOptions)
    .getLastCreatedEntityKey()

  // Insert/replace text for entity into editor
  let contentState = Modifier.replaceText(
    editorState.getCurrentContent(),
    selection,
    `${link.text}`,
    editorState.getCurrentInlineStyle(),
    entityKey
  )
  let newEditorState = EditorState.push(
    editorState,
    contentState,
    'insert-characters'
  )

  // insert a blank space after link
  if (newLink) {
    selection = newEditorState.getSelection().merge({
      anchorOffset: selection.get('anchorOffset') + link.text.length,
      focusOffset: selection.get('anchorOffset') + link.text.length,
    })
    newEditorState = EditorState.acceptSelection(newEditorState, selection)
    contentState = Modifier.insertText(
      newEditorState.getCurrentContent(),
      selection,
      ' ',
      newEditorState.getCurrentInlineStyle(),
      undefined
    )
  }
  onChange(EditorState.push(newEditorState, contentState, 'insert-characters'))
}

export default InsertLink
