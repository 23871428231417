import React, { useCallback, useEffect, useRef, useState, useMemo } from 'react'
import { createPortal } from 'react-dom'
import { useSelector } from 'react-redux'
import mapboxgl from '!mapbox-gl' // eslint-disable-line import/no-webpack-loader-syntax
import { Box, Typography } from '@mui/material'
import { IconButton } from '../ui'
import { makeStyles } from '@mui/styles'
import { ACTION_ALL_ACCESS } from '../app/appConstants'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'
import MapSidePanel from './MapSidePanel'
import PlaceIcon from '@mui/icons-material/Place'

import HouseIcon from '@mui/icons-material/House'
import CribIcon from '@mui/icons-material/Crib'
import EventIcon from '@mui/icons-material/Event'
import MilitaryTechIcon from '@mui/icons-material/MilitaryTech'

import config from '../../config'
import FullscreenIcon from '@mui/icons-material/Fullscreen'
//import { MapboxStyleSwitcherControl } from 'mapbox-gl-style-switcher'
//import 'mapbox-gl-style-switcher/styles.css'
import { selectAuthorisedTreeSlug } from 'src/modules/auth/authSlice'
import { MapEditComponent, WeAreMap } from './MapEdit'
import { useMapImage } from './MapImage'
import ReactDOMServer from 'react-dom/server'
import { getCenterCoords } from './MapImageHelpers'

import { GeocoderBox } from '../services/GeocodeInputMapControl'

export const mapbox_access_token = config?.mapbox?.token

mapboxgl.accessToken = mapbox_access_token

const ZINDEX_MAP_FULL_WINDOW = 1300

const PRELOAD_FLYBY = false

const useStyles = makeStyles(theme => ({
  mapContainer: {
    height: '100%',
    '& .active': {
      backgroundColor: '#89579C',
    },
    '& .mapboxgl-ctrl button:not(:disabled):hover': {
      backgroundColor: '#C7C2C8',
    },
  },
  passClicks: {
    pointerEvents: 'none !important', // important because if this class is used again on a child the 'auto' overrides!
    // '& *': {
    //   pointerEvents: 'auto',
    // },
    '& > *': {
      pointerEvents: 'auto',
    },
  },
}))

export const createTemporaryMarkerId = (lat, lng) => {
  return `SORTABLE-ID-${Math.random()}-${lat}-${lng}-${Math.random()}`
}

const MapControls = ({
  map,
  allowThreeD,
  flyToMarkersIn2d,
  setFlyToMarkersIn2d,
}) => {
  const debug = false
  const increaseAltitude = () => {
    const camera = map.getFreeCameraOptions()
    const currentPosZ = camera._position.z
    camera._position.z = currentPosZ + 0.000001
    map.setFreeCameraOptions(camera)
  }

  const decreaseAltitude = () => {
    const camera = map.getFreeCameraOptions()
    const currentPosZ = camera._position.z
    camera._position.z = currentPosZ - 0.000001
    map.setFreeCameraOptions(camera)
  }

  const toggleCamera3D = () => {
    if (debug)
      console.debug(
        `toggleCamera3D(): previous flyToMarkersIn2d: ${flyToMarkersIn2d}`
      )
    setFlyToMarkersIn2d(!flyToMarkersIn2d)
  }

  return (
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        right: 0,
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'column',
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
        borderBottomLeftRadius: 4,
        borderBottomRightRadius: 4,
        padding: 0,
        marginRight: '44px',
        marginTop: '10px',
      }}
    >
      {allowThreeD && (
        <>
          <IconButton
            permissionAction={ACTION_ALL_ACCESS}
            onClick={increaseAltitude}
          >
            <ArrowUpwardIcon color="primary.darkGrey" fontSize="small" />
          </IconButton>
          <IconButton
            permissionAction={ACTION_ALL_ACCESS}
            onClick={decreaseAltitude}
          >
            <ArrowDownwardIcon color="primary.darkGrey" fontSize="small" />
          </IconButton>
          <Typography
            onClick={toggleCamera3D}
            fontWeight={600}
            sx={{ cursor: 'pointer', textAlign: 'center' }}
            pb={0.5}
          >
            {flyToMarkersIn2d ? `2D` : `3D`}
          </Typography>
        </>
      )}
    </Box>
  )
}

// const getUserLatLng = async () => {
//   try {
//     const response = await fetch(
//       'https://ssl.geoplugin.net/json.gp?k=5412b3c1581c5252'
//     )
//     const data = await response.json()
//     if (data?.geoplugin_latitude) {
//       const lat = Number(data?.geoplugin_latitude)
//       const lon = Number(data?.geoplugin_longitude)
//       return { lat: lat, lon: lon }
//     }
//   } catch (err) {
//     console.log(err)
//   }
//   // default USA
//   const latUS = 37.0902
//   const lngUS = -95.7129
//   return { lat: latUS, lon: lngUS }
// }

export const formatDegreesAsString = (degrees, dp = 6) => {
  if (degrees !== null && degrees !== undefined) {
    return `${parseFloat(degrees).toFixed(dp)}°`
  }
  return ''
}

const formatLatiLongAsString = (lati, long, dp = 6) => {
  return `${formatDegreesAsString(lati, dp)}, ${formatDegreesAsString(
    long,
    dp
  )}`
}

const coordsToArray = coords => {
  if (Array.isArray(coords)) {
    return coords
  } else {
    return [coords.lng, coords.lat]
  }
}

const formatCoordsAsString = (coords, dp = 6) => {
  if (Array.isArray(coords)) {
    return formatLatiLongAsString(coords[1], coords[0])
  } else {
    return formatLatiLongAsString(coords.lat, coords.lng)
  }
}

export const formatLocationCoords = weareMapFeature => {
  const coords = getWeareMapFeatureLongLat(weareMapFeature)
  if (coords) {
    return formatCoordsAsString(coords)
  }
}

/**
 * Returns an array [longitude, latitude]
 *
 */
const getWeareMapFeatureLongLat = weareMapFeature => {
  // the Ged suffix is being phased out as it is incorrect, allow either for now

  if (!weareMapFeature?.target) {
    return
  }

  if (weareMapFeature.target.longGed) {
    console.error(
      `getWeareMapFeatureLongLat(): weareMapFeature contains longGed property`,
      getWeareMapFeatureLongLat
    )
  } else if (weareMapFeature.target.address?.longGed) {
    console.error(
      `getWeareMapFeatureLongLat(): weareMapFeature contains address.longGed property`,
      getWeareMapFeatureLongLat
    )
  }

  if (weareMapFeature.target.long) {
    return [weareMapFeature.target.long, weareMapFeature.target.lati]
  } else if (weareMapFeature.target.longGed) {
    return [weareMapFeature.target.longGed, weareMapFeature.target.latiGed]
  } else if (weareMapFeature.target.address?.long) {
    return [
      weareMapFeature.target.address.long,
      weareMapFeature.target.address.lati,
    ]
  } else if (weareMapFeature.target.address?.longGed) {
    return [
      weareMapFeature.target.address.longGed,
      weareMapFeature.target.address.latiGed,
    ]
  }
}

/**
 * Returns an array of long/lat pairs
 * Will only be one entry in the outer array if the feature is a point feature
 * Will be four if it's an image or a rect
 * Could be any if it's a line or a polygon
 *
 */
const getWeareMapFeatureLongLats = weareMapFeature => {
  const debug = false
  // the Ged suffix is being phased out as it is incorrect, allow either for now

  if (debug)
    console.debug(
      `Map.getWeareMapFeatureLongLats(): called with weareMapFeature:`,
      weareMapFeature
    )

  if (!weareMapFeature) {
    return
  }

  // returns an array of (arrays of 2 numbers)
  const deNestCoordinateArray = coordinatesArray => {
    if (
      coordinatesArray.length === 2 &&
      typeof coordinatesArray[0] === 'number'
    ) {
      return [coordinatesArray]
    } else {
      if (debug)
        console.debug(
          `Map.getWeareMapFeatureLongLats().deNestCoordinateArray(): weareMapFeature.coordinates.length is not 2 containing a number:`,
          coordinatesArray
        )
      if (coordinatesArray.length > 0) {
        const nested = coordinatesArray[0]
        if (nested && nested.length > 0) {
          if (nested.length === 2 && typeof nested[0] === 'number') {
            return coordinatesArray
          } else {
            //nested could be an array of say 7 corners of a polygon
            const nested2 = nested[0]
            if (
              nested2 &&
              nested2.length === 2 &&
              typeof nested2[0] === 'number'
            ) {
              // coordinatesArray is: [[[1,1],[2,2],[3,3]]]
              // nested is: [[1,1],[2,2],[3,3]]
              // nested2 is: [1,1]
              return nested
            }
          }
        }
        console.error(
          `Map.getWeareMapFeatureLongLats().deNestCoordinateArray(): weareMapFeature has unexpected coordinates`,
          coordinatesArray
        )
      }
    }
  }

  if (weareMapFeature.target) {
    if (weareMapFeature.target.longGed) {
      console.error(
        `getWeareMapFeatureLongLat(): weareMapFeature contains longGed property`,
        getWeareMapFeatureLongLat
      )
    } else if (weareMapFeature.target.address?.longGed) {
      console.error(
        `getWeareMapFeatureLongLat(): weareMapFeature contains address.longGed property`,
        getWeareMapFeatureLongLat
      )
    }
    if (weareMapFeature.target.feature?.geometry?.coordinates) {
      return deNestCoordinateArray(
        weareMapFeature.target.feature.geometry.coordinates
      )
    }

    if (weareMapFeature.target.long) {
      return [[weareMapFeature.target.long, weareMapFeature.target.lati]]
    }

    if (weareMapFeature.target.longGed) {
      return [[weareMapFeature.target.longGed, weareMapFeature.target.latiGed]]
    }

    if (weareMapFeature.target.address?.long) {
      return [
        [
          weareMapFeature.target.address.long,
          weareMapFeature.target.address.lati,
        ],
      ]
    }
    if (weareMapFeature.target.address?.longGed) {
      return [
        [
          weareMapFeature.target.address.longGed,
          weareMapFeature.target.address.latiGed,
        ],
      ]
    }
  } else {
    if (debug)
      console.debug(
        `Map.getWeareMapFeatureLongLats(): called with weareMapFeature with no target:`,
        weareMapFeature
      )

    if (weareMapFeature.coordinates) {
      return deNestCoordinateArray(weareMapFeature.coordinates)
    }

    console.debug(
      `Map.getWeareMapFeatureLongLats(): called with weareMapFeature with no positioning:`,
      weareMapFeature
    )
  }
}

//TODO is there already a re-usable 'getLinkTypeDescription' function somewhere?
export const getTypeDisplayText = (type, unknown = 'marker') => {
  switch (type) {
    case 'location':
      return 'place'
    case 'event':
      return 'occasion'
    case 'individual':
      return 'individual'
    default:
      return unknown
  }
}

const Map = ({
  id,
  inlineMapHeight = '100%',
  lng = -2.436, // default to USA
  lat = 53.3781,
  isEditing: initialIsEditingCompat = false,
  initialIsEditing = initialIsEditingCompat,
  zoom = 4, //used when map is empty, and on reset when returning from preview
  singleCoordZoomLevel = 13, // 13 is city ringroad size. used when single feature with single coord
  closeMap, // optional, called with 2 parameters - the second is a function which will switch away from fullscreen/fullwindow
  onSave, // optional, called with 2 parameters - the first is the saved mapblock and the second is the closeMap function
  currentMap: initialCurrentMap = new WeAreMap(),
  initialFlyToMarkersIn2d = true,
  flyOnMarkerClick = true,
  sidePanelShowLatLong,
  fitViewToMarkersPadding, // defaults to 0.0005 in fitViewToMarkers(),
  clickableMarkers = true,
  initialInteractive,
  markerInfoPanelEnabled = true,
  maxSidepanelTitleLines,
  onFullWindowButtonClick,
  interactiveWhenFullWindow = false,
  showMaximizeToFullwindowButton = false,
  showMaximizeToFullscreenButton = false,
  fullwindowOnMapClick = false,
  initialMapIsFullWindow,
  singlePoint = false, // don't show the info card, use different exit/close buttons at the bottom right
  treeSlug,
  //markerItemType,
  preferAddressAsMarkerPopup, // otherwise the location title or description will be preferred if set
  allowThreeD: initialAllowThreeD = true,
  allowThreeDWhenFullWindow = true,
  isEditingWhenFullWindow = initialIsEditing,
  addSearchBoxMapControl,
  addSearchBoxMapControlWhenFullWindow,
  userLatLng = { lat: 37.0902, lon: -95.7129 },
  allowEditButton = true,
  useDefaultMarkerIconsOnMap,
}) => {
  const debug = false

  //if (debug)
  console.debug(
    `Map: rendering, treeSlug: ${treeSlug}, initialIsEditing: ${initialIsEditing}, addSearchBoxMapControl: ${addSearchBoxMapControl}, initialCurrentMap:`,
    initialCurrentMap
  )
  const classes = useStyles()
  const mapRoot = useRef(null)
  const mapContainer = useRef(null)
  const map = useRef(null)
  // const [currentMapLocal, setCurrentMap] = useState(currentMap)
  // currentMap = currentMapLocal
  const initMapStartedRef = useRef(null)
  const threeDTerrainConfiguredRef = useRef(false)
  const [currentMap] = useState(() => {
    if (!(initialCurrentMap instanceof WeAreMap)) {
      return new WeAreMap(initialCurrentMap)
    }
    return initialCurrentMap
  })

  //if (debug)
  console.debug(`Map: rendering, currentMap:`, currentMap)

  const [, setInitMapCompleted] = useState() // so JSX re-renders when (async) initMap() is done
  const [currentMarkerIndex, setCurrentMarkerIndex] = useState(-1)
  const [locations, setLocations] = useState(currentMap.mapLinks)
  const [preview, setPreview] = useState(false)
  const [dropPinMode, setDropPinMode] = useState(false) // set to true in initMap() if singlePoint is true and there are no markers
  const isEditingRef = useRef(initialIsEditing) // used by callbacks executed outside of react context e.g. from Mapbox

  const [showSidePanel, setShowSidePanel] = useState(true)
  const [interactive, setInteractive] = useState(initialInteractive)
  const [allowThreeD, setAllowThreeD] = useState(initialAllowThreeD)

  const [mapRootStyle, setMapRootStyle] = useState({})

  const [mapIsFullWindow, setMapIsFullWindow] = useState(initialMapIsFullWindow)
  const [mapIsFullScreen, setMapIsFullScreen] = useState(false)

  const [isEditing, setIsEditing] = useState(
    initialIsEditing ||
      ((mapIsFullWindow || mapIsFullScreen) && isEditingWhenFullWindow)
  )

  const [mapHeight, setMapHeight] = useState(
    initialMapIsFullWindow ? '100%' : inlineMapHeight
  )

  if (debug)
    console.debug(
      `Map: rendering, mapHeight: ${mapHeight}, mapIsFullWindow: ${mapIsFullWindow}, initialMapIsFullWindow: ${initialMapIsFullWindow}, inlineMapHeight: ${inlineMapHeight}`
    )

  //const [threeD, setThreeD] = useState(false)
  //const threeDRef = useRef(false)

  const [hideUi, setHideUi] = useState(false) // set when editing an overlay image placement to clear the cruft off the screen

  const prevFlybyBearingRef = useRef()
  const nextFlybyBearingRef = useRef()

  const treeSlugFromRedux = useSelector(selectAuthorisedTreeSlug)
  if (!treeSlug) {
    treeSlug = treeSlugFromRedux
  }
  const [flyToMarkersIn2d, setFlyToMarkersIn2d] = useState(
    initialFlyToMarkersIn2d
  )

  //when a result is selected from the geocoder we show a marker with a popup that has a 'add to map' button
  //because the popup HTML is generated from outside React we have to hand-code the onClick for that button.
  //As that hand-written javascript doesn't have access to anything React-y we temporarily place a function on
  //document that it can call. That needs a handle to the popup so it can close it.
  const addMarkerPopupRef = useRef()

  const geocoderResultRef = useRef()

  // Mapbox does not provide an API to get the Markers on the map so we need to store references to them
  const markersByFeatureIdRef = useRef({})

  // If an item (place/artefact/fact etc) hasn't been geolocated yet we remember it and prompt
  // the user to set its location to the next one selected.
  // Also request the sidepanel hide itself when locating is in progress as the screen
  // is quite cluttery.
  const [geocodingItem, setGeocodingItem] = useState()

  const toggleMapboxInteractivity = enable => {
    // see https://docs.mapbox.com/mapbox-gl-js/example/toggle-interaction-handlers/
    if (!map.current) {
      return
    }
    const keys = [
      'scrollZoom',
      'boxZoom', //'dragRotate',
      'dragPan',
      'keyboard',
      'doubleClickZoom',
      'touchZoomRotate',
    ]
    if (map.current) {
      keys.forEach(key =>
        enable ? map.current[key].enable() : map.current[key].disable()
      )
    }
  }

  const enableInteractive = () => {
    if (debug) console.debug(`Map.enableInteractive(): called`)
    if (map.current) {
      if (!map.current._navigationControl) {
        const navigationControl = new mapboxgl.NavigationControl()
        map.current.addControl(navigationControl)
        map.current._navigationControl = navigationControl
      }
      toggleMapboxInteractivity(true)
    }
  }
  const disableInteractive = () => {
    if (map.current) {
      if (map.current._navigationControl) {
        map.current.removeControl(map.current._navigationControl)
        delete map.current._navigationControl
      }
      toggleMapboxInteractivity(false)
    }
  }

  useEffect(() => {
    if (debug)
      console.debug(
        `Map.useEffect([interactive]): interactive now: ${interactive}`
      )

    if (interactive) {
      enableInteractive()
    } else {
      disableInteractive()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interactive]) // disableInteractive, enableInteractive,

  useEffect(() => {
    if (debug)
      console.debug(
        `Map.useEffect([allowThreeD]): allowThreeD now: ${allowThreeD}`
      )

    if (!allowThreeD) {
      if (!flyToMarkersIn2d) {
        setFlyToMarkersIn2d(true)
        // useEffect observing flyToMarkersIn2d calls setCamera2D()
      } else {
        setCamera2D(true)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allowThreeD])

  useEffect(() => {
    if (debug)
      console.debug(
        `Map.useEffect([mapIsFullWindow, mapIsFullScreen]): interactiveWhenFullWindow: ${interactiveWhenFullWindow}, mapIsFullWindow: ${mapIsFullWindow}, mapIsFullScreen: ${mapIsFullScreen}`
      )

    if (!map.current) {
      return
    }
    if (mapIsFullWindow || mapIsFullScreen) {
      if (interactiveWhenFullWindow) {
        //if (!interactive) {
        console.debug(
          `Map.useEffect([mapIsFullWindow, mapIsFullScreen]): calling setInteractive(true)...`
        )
        //enableInteractive()
        setInteractive(true)
        //}
      }
      if (allowThreeDWhenFullWindow) {
        setAllowThreeD(true)
      }
    } else {
      console.debug(
        `Map.useEffect([mapIsFullWindow, mapIsFullScreen]): calling setInteractive(initialInteractive: ${initialInteractive})...`
      )
      setInteractive(initialInteractive)
      if (allowThreeD !== initialAllowThreeD) {
        setAllowThreeD(initialAllowThreeD)
      }

      // need to wait until HTML is re-rendered to give new map viewport size before fitting view
      setTimeout(() => {
        if (debug)
          console.debug(
            `Map.useEffect([mapIsFullWindow, mapIsFullScreen]): calling fitViewToFeaturesAndImages()...`
          )
        fitViewToFeaturesAndImages(fitViewToMarkersPadding, false)
      }, 100)
    }

    setIsEditing(
      initialIsEditing ||
        ((mapIsFullWindow || mapIsFullScreen) && isEditingWhenFullWindow)
    )

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapIsFullWindow, mapIsFullScreen])

  useEffect(() => {
    //cache isEditing into a ref because it's used from Mapbox events that don't
    //have access to up-to-date react state
    isEditingRef.current = isEditing
  }, [isEditing])

  useEffect(() => {
    //automatically show the side panel when entering edit mode
    if (isEditing) {
      setShowSidePanel(true)
    }
  }, [isEditing])

  useEffect(() => {
    currentMap.mapLinks = locations
  }, [locations, currentMap])

  //called outside of the react context
  const addMarkerForGeocodeSearchResult = ({ singlePoint }) => {
    // console.debug(
    //   `addMarkerForAddress(): mostRecentResult:`,
    //   mostRecentResult
    // )
    console.debug(
      `document.addMarkerForAddress(): geocoderResultRef.current:`,
      geocoderResultRef.current
    )
    const newWeareMapFeature = addFeatureFromGeocoderResult(
      geocoderResultRef.current
    )

    if (singlePoint) {
      if (debug)
        console.debug(
          `Map.addMarkerForGeocodeSearchResult(): singlePoint is true, calling closeMap() with newWeareMapFeature:`,
          newWeareMapFeature
        )
      if (closeMap) {
        closeMap(newWeareMapFeature) // in singlepoint mode there is only the currentMarker
      }
      return
    }

    geocoderResultRef.current = undefined
    if (addMarkerPopupRef.current) {
      addMarkerPopupRef.current.remove()
      addMarkerPopupRef.current = undefined
    }
  }

  const updateItemLocation = async () => {
    console.debug(
      `Map.updateItemLocation: called, geocoderResultRef.current:`,
      geocoderResultRef.current
    )
    let currentGeocodingItem
    await setGeocodingItem(currentValue => {
      currentGeocodingItem = currentValue
      return currentValue
    })
    console.debug(
      `Map.updateItemLocation: called, geocodingItem:`,
      currentGeocodingItem
    )
    //TODO implement updateFactLocation()

    //no need to clear geocodingItem or geocoderResultRef because these are done by the popup's onClose
  }

  useEffect(() => {
    console.debug(`Map.useEffect([]): Map mounting, setting global functions`)
    // the popup showing the geocoder result is created by Mapbox outside of React so has no access
    // to React context. The buttons in the popup's HTML are hand-coded and again has no access to React
    // context. So reference these functions globally in document so the onClick js can call them easily.
    document.addMarkerForGeocodeSearchResult = addMarkerForGeocodeSearchResult
    document.updateItemLocation = updateItemLocation

    return () => {
      console.debug(
        `Map.useEffect([]) dismount: dismounting, clearing global functions`
      )
      delete document.addMarkerForGeocodeSearchResult
      delete document.updateItemLocation
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  //hooks cannot be called inside a callback, must be called inside a FC or hook
  const mapImageHookResponse = useMapImage()

  // useEffect(() => {
  //   console.debug(`Map.useEffect([threeD]): threeD: ${threeD}`)
  //   if (!threeD) {
  //     setFlyToMarkersIn2d(true)
  //   }
  // }, [threeD])
  // const setThreeD = newThreeDvalue => {
  //   threeDRef.current = newThreeDvalue
  //   if (!newThreeDvalue) {
  //     setFlyToMarkersIn2d(true)
  //   }
  // }

  const flyTo = async options => {
    //useCallback(
    //const debug = true
    if (debug) console.debug(`Map.flyTo(): called with options:`, options)
    if (!map.current) {
      return
    }
    let center = map?.current?.getCenter()
    if (options?.center?.[0] >= -180 && options?.center?.[1] >= -90) {
      center = options?.center
    }

    const flytoParams = { ...options, center }

    if (debug)
      console.debug(
        `Map.flyTo(): awaiting map.current.flyTo() with:`,
        flytoParams
      )
    await map.current.flyTo(flytoParams)
    if (debug) console.debug(`Map.flyTo(): map.current.flyTo() returned`)
  }
  //[debug]
  //)

  const getWeAreFeaturesBounds = weareFeaturesOrImages => {
    //useCallback(
    let swCorner = []
    let neCorner = []

    if (debug)
      console.debug(
        `getWeAreFeaturesBounds(): called with weareFeaturesOrImages:`,
        weareFeaturesOrImages
      )

    weareFeaturesOrImages.forEach(mapOrImageLink => {
      const arrayOfCoords = getWeareMapFeatureLongLats(mapOrImageLink)
      if (arrayOfCoords) {
        if (debug)
          console.debug(
            `Map.getWeAreFeaturesBounds(): getWeareMapFeatureLongLat() returned arrayOfCoords (array of n arrays each with 2 numbers): arrayOfCoords, mapOrImageLink`,
            arrayOfCoords,
            mapOrImageLink
          )

        arrayOfCoords.forEach((coords, index) => {
          if (debug)
            console.debug(
              `Map.getWeAreFeaturesBounds(): getWeareMapFeatureLongLat() considering point coords:`,
              coords
            )

          if (coords.length !== 2) {
            console.error(
              `getWeareMapFeatureLongLat() for feature returned arrayOfCords where item at position ${index} is not an array of two numbers but:`,
              coords,
              mapOrImageLink
            )
            return
          }

          try {
            const locationObj = {
              lng: Number(coords[0]),
              lat: Number(coords[1]),
            }
            if (!swCorner[0] || locationObj.lng < swCorner[0]) {
              swCorner[0] = locationObj.lng
            }
            if (!neCorner[0] || locationObj.lng > neCorner[0]) {
              neCorner[0] = locationObj.lng
            }
            if (!swCorner[1] || locationObj.lat < swCorner[1]) {
              swCorner[1] = locationObj.lat
            }
            if (!neCorner[1] || locationObj.lat > neCorner[1]) {
              neCorner[1] = locationObj.lat
            }

            if (debug)
              console.debug(
                `Map.getWeAreFeaturesBounds(): after feature, corners are: swCorner: '${swCorner}', neCorner: '${neCorner}'`,
                mapOrImageLink
              )
          } catch (err) {
            console.error(
              `Map.getWeAreFeaturesBounds(): could not parse coords to long/lat, skipping:`,
              coords
            )
          }
        })
      }
    })

    if (swCorner.length && neCorner.length) {
      return [swCorner, neCorner]
    }
  }
  //, [debug]
  // )

  const resetMap = () => {
    flyTo({
      center: [lng, lat],
      zoom,
      pitch: 0,
      duration: 3000,
      bearing: 0,
      //essential: true, // this animation is considered essential with respect to prefers-reduced-motion
    })
  }

  const getNextIndex = (direction, overrideCurrentMarkerIndex) => {
    //useCallback(
    if (
      overrideCurrentMarkerIndex === null ||
      overrideCurrentMarkerIndex === undefined
    ) {
      overrideCurrentMarkerIndex = currentMarkerIndex //local state
    }
    var newIndex
    if (direction === 'next') {
      if (overrideCurrentMarkerIndex === locations.length - 1) {
        newIndex = 0
      } else newIndex = overrideCurrentMarkerIndex + 1
    } else if (direction === 'prev') {
      if (overrideCurrentMarkerIndex < 1) {
        newIndex = locations.length - 1
      } else newIndex = overrideCurrentMarkerIndex - 1
    }

    if (debug)
      console.debug(
        `Map.getNextIndex(): called with direction: '${direction}', currentMarkerIndex: ${currentMarkerIndex}, returning newIndex: ${newIndex}`
      )
    return newIndex
  }
  //,[currentMarkerIndex, debug, locations.length]
  //)

  const getRandom3DFlybyBearing = () => {
    const min = -90
    const max = 90
    const range = max - min
    const bearing = min + Math.random() * range

    return bearing
  }

  const flyToFeature3DNoPreload = async (weareMapFeature, options = {}) => {
    //useCallback(
    //const debug = true
    if (debug) {
      console.debug(
        `Map.flyToFeature3DNoPreload(): called with weareMapFeature: `,
        weareMapFeature
      )
      console.debug(
        `Map.flyToFeature3DNoPreload(): called with options: `,
        options
      )
    }

    let bounds = getWeAreFeaturesBounds([weareMapFeature])
    if (!bounds) {
      if (debug)
        console.debug(
          `Map.flyToFeature3DNoPreload(): weareMapFeature did not contain any features or images to zoom to `,
          weareMapFeature
        )
      return
    }

    const [swCorner, neCorner] = bounds
    let newCenter
    if (swCorner[0] === neCorner[0] && swCorner[1] === neCorner[1]) {
      newCenter = [swCorner[0], swCorner[1]]
    } else {
      newCenter = getCenterCoords(bounds)
      //TODO work out zoom to fit the given bounds
    }

    let bearing
    if (!options.bearing) {
      bearing = getRandom3DFlybyBearing()
    } else {
      bearing = options.bearing
    }

    const flytoOpts = {
      ...options,
      center: newCenter,
      zoom: 17,
      pitch: 65,
      duration: 6000,
      bearing: bearing,
      //essential: true, // this animation is considered essential with respect to prefers-reduced-motion
    }

    if (debug)
      console.debug(
        `Map.flyToFeature3DNoPreload(): awaiting flyTo()`,
        flytoOpts
      )
    await flyTo(flytoOpts)
    if (debug)
      console.debug(`Map.flyToFeature3DNoPreload(): await flyTo() returned`)
  }
  //, [flyTo, getWeAreFeaturesBounds]
  //)

  const preloadFlyby = async (currentIndex, direction) => {
    //useCallback(
    //const debug = true
    const preloadForIndex = getNextIndex(direction, currentIndex)

    if (
      preloadForIndex !== null &&
      preloadForIndex !== undefined &&
      preloadForIndex !== currentIndex
    ) {
      const preloadToFeature = locations[preloadForIndex]

      const preloadBearing = getRandom3DFlybyBearing()
      if (direction === 'next') {
        nextFlybyBearingRef.current = preloadBearing
      } else {
        prevFlybyBearingRef.current = preloadBearing
      }
      if (debug)
        console.debug(
          `Map.preloadFlyby(): currentIndex ${currentIndex}, calling flyToFeature3DNoPreload() again with preloadOnly=true to preload for the ${direction} feature at index ${preloadForIndex}... preloadBearing: ${preloadBearing}`,
          preloadToFeature
        )
      await flyToFeature3DNoPreload(preloadToFeature, {
        preloadOnly: true,
        bearing: preloadBearing,
      })
    }
    return preloadForIndex
  } //,
  //[flyToFeature3DNoPreload, getNextIndex, locations]
  //)

  const preload3dFlybyNextAndPrev = async currentIndex => {
    //useCallback(
    //
    // disabled preloading next flyby animation because we need to pre-determine the new bearing and store it for future use
    if (PRELOAD_FLYBY && locations.length > 1) {
      //setTimeout(() => {

      if (debug)
        console.debug(
          `Map.preload3dFlybyNextAndPrev(): awaiting preloadFlyby(currentIndex: ${currentIndex}, 'next')...`
        )
      await preloadFlyby(currentIndex, 'next')
      if (debug)
        console.debug(
          `Map.preload3dFlybyNextAndPrev(): preloadFlyby(currentIndex: ${currentIndex}, 'next') returned.`
        )

      if (locations.length > 2) {
        if (debug)
          console.debug(
            `Map.preload3dFlybyNextAndPrev(): awaiting preloadFlyby(currentIndex: ${currentIndex}, 'prev')...`
          )
        preloadFlyby(currentIndex, 'prev')
        if (debug)
          console.debug(
            `Map.preload3dFlybyNextAndPrev(): preloadFlyby(currentIndex: ${currentIndex}, 'prev') returned.`
          )
      } else {
        // only 2 features in the list - next and prev will go to the same other feature
        if (debug)
          console.debug(
            `Map.preload3dFlybyNextAndPrev(): only 2 features in the list, prev is same as next.`
          )
        prevFlybyBearingRef.current = nextFlybyBearingRef.current
      }
    }
  } //,
  //[debug, locations.length, preloadFlyby]
  //)

  const flyToFeature3D = async (weareMapFeature, options = {}) => {
    //useCallback(
    //const debug = true
    if (debug) {
      console.debug(
        `Map.flyToFeature3D(): called with weareMapFeature: `,
        weareMapFeature
      )
      console.debug(`Map.flyToFeature3D(): called with options: `, options)
      console.debug(
        `Map.flyToFeature3D(): calling flyToFeature3DNoPreload()...`
      )
    }

    await flyToFeature3DNoPreload(weareMapFeature, options)

    if (!options.preloadOnly && PRELOAD_FLYBY && locations.length > 1) {
      if (debug)
        console.debug(
          `Map.flyToFeature3D(): awaiting map idle before calling preload3dFlybyNextAndPrev()...`
        )
      await map.current.once('idle')
      if (debug)
        console.debug(
          `Map.flyToFeature3D(): map idle fired, getting currentMarkerIndex then calling preload3dFlybyNextAndPrev()...`
        )

      //this fn can be called from a Mapbox callback which is not in the react context so
      //local state vars don't have the up-to-date value
      let currentMarkerIndex
      await setCurrentMarkerIndex(existingValue => {
        currentMarkerIndex = existingValue
        return existingValue
      })

      if (debug)
        console.debug(
          `Map.flyToFeature3D(): calling preload3dFlybyNextAndPrev(currentMarkerIndex: ${currentMarkerIndex})...`
        )
      await preload3dFlybyNextAndPrev(currentMarkerIndex)
    }
  }
  //,[flyToFeature3DNoPreload, locations.length, preload3dFlybyNextAndPrev]
  //)

  const flyTo2D = weareMapFeature => {
    //const debug = true
    if (debug)
      console.debug(
        `Map.flyTo2D(): called with weareMapFeature: `,
        weareMapFeature
      )

    let bounds = getWeAreFeaturesBounds([weareMapFeature])
    if (!bounds) {
      if (debug)
        console.debug(
          `flyTo2D(): weareMapFeature did not contain any features or images to zoom to `,
          weareMapFeature
        )
      return
    }
    const [swCorner, neCorner] = bounds
    if (swCorner[0] === neCorner[0] && swCorner[1] === neCorner[1]) {
      //one point, not a box
      let zoom = map?.current?.getZoom()
      if (zoom < 8) {
        zoom = 8
        if (debug)
          console.debug(
            `flyTo2D(): moving to a single point, current zoom is less than 8, using zoom of 8...`,
            currentMap
          )
      } else {
        if (debug)
          console.debug(
            `flyTo2D(): moving to a single point, using current zoom of ${zoom}...`
          )
      }
      flyTo({
        center: [swCorner[0], swCorner[1]],
        zoom,
        duration: 3000,
        //essential: true, // this animation is considered essential with respect to prefers-reduced-motion
      })
    } else {
      // a box!
      fitBoundsWithPadding(swCorner, neCorner)
    }
  }

  //needs to call flyTo, and flyTo
  const setCamera2D = alsoResetBearing => {
    //useCallback(
    //unfortunately due to the useEffect below that depends on flyToMarkersIn2d this gets called on every render
    //so check we actually need to do anything first
    if (map?.current) {
      if (map.current.getPitch() !== 0) {
        map.current['dragRotate'].disable()

        const flyToOpts = {
          pitch: 0,
          duration: 2000,
        }
        if (alsoResetBearing) {
          flyToOpts['bearing'] = 0
        }
        flyTo(flyToOpts)
      }
    }
  } //, [flyTo])

  const setCamera3D = async () => {
    //useCallback(
    //const debug = true

    if (map?.current) {
      //unfortunately due to the useEffect below that depends on flyToMarkersIn2d this gets called on every render
      //so check we actually need to do anything first
      if (map.current.getPitch() === 0) {
        create3DTerrain() // does nothing if run again
        if (interactive) {
          map.current['dragRotate'].enable()
        }

        flyTo({
          pitch: 30,
          duration: 2000,
        })

        if (PRELOAD_FLYBY && locations.length > 1) {
          if (debug)
            console.debug(
              `Map.setCamera3D(): PRELOAD_FLYBY true, awaiting map idle...`
            )
          await map.current.once('idle')
          if (debug) console.debug(`Map.setCamera3D(): map idle fired.`)

          if (debug)
            console.debug(
              `Map.setCamera3D(): calling preload3dFlybyNextAndPrev(currentMarkerIndex: ${currentMarkerIndex})...`
            )
          await preload3dFlybyNextAndPrev(currentMarkerIndex)
        }
      }
    }
  } //, [currentMarkerIndex, flyTo, locations.length, preload3dFlybyNextAndPrev])
  // setCamera3D is depended on by a useEffect which intends to fire and call it when flyToMarkersIn2d changes
  // BUT NOT when setCamera3D changes.

  useEffect(() => {
    if (!flyToMarkersIn2d) {
      console.debug(
        `Map.useEffect([flyToMarkersIn2d]): flyToMarkersIn2d: ${flyToMarkersIn2d} - calling setCamera3D()...`
      )
      setCamera3D()
    } else {
      console.debug(
        `Map.useEffect([flyToMarkersIn2d]): flyToMarkersIn2d: ${flyToMarkersIn2d} - calling setCamera2D()...`
      )
      setCamera2D()
    }
    //this uses setCamera2D and setCamera3D BUT we don't want this to run/fire/execute when
    //either of them change, which setCamera3D does whenever currentMarkerIndex changes
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flyToMarkersIn2d]) //  , setCamera2D, setCamera3D])

  const navigateToMarkerIndex = async (index, flyTo = true) => {
    if (debug)
      console.debug(
        `Map.navigateToMarkerIndex(): called with index: ${index}...`
      )

    setCurrentMarkerIndex(index)

    const newFeature = locations[index]
    if (flyTo) {
      if (flyToMarkersIn2d) {
        // && !force3D) {
        flyTo2D(newFeature)
      } else {
        await flyToFeature3D(newFeature)
      }
    }
  }

  const navigateMarkers = async (direction, force3D = false) => {
    //const debug = true
    if (debug)
      console.debug(`Map.navigateMarkers(): called with direction: `, direction)

    const newIndex = getNextIndex(direction)
    const newFeature = locations[newIndex]

    setCurrentMarkerIndex(newIndex)

    if (flyToMarkersIn2d && !force3D) {
      flyTo2D(newFeature)
    } else {
      const bearing =
        direction === 'next'
          ? nextFlybyBearingRef.current
          : prevFlybyBearingRef.current

      if (debug)
        console.debug(
          `Map.navigateMarkers(): awaiting flyTo3D() with bearing: ${bearing}`,
          newFeature
        )

      await flyToFeature3D(newFeature, {
        bearing: bearing,
      })
      if (debug)
        console.debug(
          `Map.navigateMarkers(): awaited flyToFeature3D() for new weareMapFeature`,
          newFeature
        )

      if (debug)
        console.debug(
          `Map.navigateMarkers(): calling setCurrentMarkerIndex(${newIndex})`
        )

      // if (PRELOAD_FLYBY && locations.length > 1) {
      //   if (debug) console.debug(`Map.navigateMarkers(): awaiting map idle...`)
      //   await map.current.once('idle')
      //   if (debug) console.debug(`Map.navigateMarkers(): map idle fired.`)

      //   await preload3dFlybyNextAndPrev(newIndex)
      // }
    }
  }

  // in non-edit mode, highlight the current shape with a white outline
  // also want this to run in edit mode to de-highlight any already highlighted shapes
  useEffect(() => {
    if (map.current) {
      locations.forEach((weareMapFeature, idx) => {
        if (weareMapFeature.target?.feature) {
          const outlineLayerId = getReadOnlyShapeOutlineLayerId(
            weareMapFeature.id
          )
          if (outlineLayerId) {
            try {
              if (map.current.getLayer(outlineLayerId)) {
                map.current.setLayoutProperty(
                  outlineLayerId,
                  'visibility',
                  currentMarkerIndex === idx && !isEditing ? 'visible' : 'none'
                )
              }
            } catch (err) {
              //don't care - layers might not exist
            }
          }
        }
      })
    }
  }, [currentMarkerIndex, locations, isEditing])

  // NOTE!! This is called from outside the react context so any local state will be out of date!
  const handleMarkerClick = async weareMapFeature => {
    //const debug = true
    if (debug)
      console.debug(
        `Map.handleMarkerClick(): called with weareMapFeature`,
        weareMapFeature
      )
    if (clickableMarkers) {
      const idx = findIndexOfWeareMapFeature(weareMapFeature)
      console.debug(
        `Map.handleMarkerClick().setCurrentMarkerIndex(): findIndexOfWeareMapFeature() returned: ${idx}`,
        weareMapFeature
      )
      if (idx > -1) {
        let prevMarkerIndex = -1
        setCurrentMarkerIndex(prevValue => {
          prevMarkerIndex = prevValue
          if (debug)
            console.debug(
              `Map.handleMarkerClick().setCurrentMarkerIndex(): prevValue: ${prevValue}, setting new value to ${idx} for weareMapFeature`,
              weareMapFeature
            )

          //setCurrentMarkerIndex(idx) //TODO this causes error: Cannot read properties of undefined (reading 'toGeoJSON')
          if (debug)
            console.debug(
              `Map.handleMarkerClick().setCurrentMarkerIndex(): returning ${idx}`
            )
          return idx
        })

        if (debug)
          console.debug(
            `Map.handleMarkerClick(): prevMarkerIndex: ${prevMarkerIndex}, idx ${idx}`
          )

        if (prevMarkerIndex !== idx) {
          // if (debug)
          //   console.debug(
          //     `Map.handleMarkerClick(): prevMarkerIndex: ${prevMarkerIndex}, idx ${idx}, flyOnMarkerClick: ${flyOnMarkerClick}, flyToMarkersIn2d: ${flyToMarkersIn2d}`
          //   )

          // because this fn is called from a Mapbox callback it does not have access to local state, so get the
          // up-to-date state values by await-calling the setters
          let flyToMarkersIn2d
          let isEditing
          let preview
          await setFlyToMarkersIn2d(existingValue => {
            console.debug(
              `in setFlyToMarkersIn2d callback: existingValue: ${existingValue}`
            )
            flyToMarkersIn2d = existingValue
            return existingValue
          })
          //console.debug(`res from setFlyToMarkersIn2d callback: ${res}`)
          await setIsEditing(existingValue => {
            isEditing = existingValue
            return existingValue
          })
          await setPreview(existingValue => {
            preview = existingValue
            return existingValue
          })

          if (debug)
            console.debug(
              `Map.handleMarkerClick(): prevMarkerIndex2: ${prevMarkerIndex}, idx ${idx}, flyOnMarkerClick: ${flyOnMarkerClick}, flyToMarkersIn2d: ${flyToMarkersIn2d}`
            )
          if (flyOnMarkerClick) {
            if (flyToMarkersIn2d || (isEditing && !preview)) {
              flyTo2D(weareMapFeature)
            } else {
              flyToFeature3D(weareMapFeature)
            }
          }
        }

        setShowSidePanel(true)
        //setExpandSidePanel(true)
      }
    }
  }

  const fitBoundsWithPadding = (
    swCorner,
    neCorner,
    padding, // = 0.0005,
    animate = true
  ) => {
    swCorner[0] = Number(swCorner[0])
    swCorner[1] = Number(swCorner[1])
    neCorner[0] = Number(neCorner[0])
    neCorner[1] = Number(neCorner[1])

    console.debug(
      `fitBoundsWithPadding(): before padding, ne corner long/lat: ${neCorner[0]}, ${neCorner[1]}`
    )
    console.debug(
      `fitBoundsWithPadding(): before padding, sw corner long/lat: ${swCorner[0]}, ${swCorner[1]}`
    )

    var latiPadding
    var longPadding

    if (padding) {
      latiPadding = padding
      longPadding = padding
    } else {
      const latiDiff = neCorner[1] - swCorner[1] // lati - N/S - vertical - degrees from the equator, -90 to +90
      const longDiff = neCorner[0] - swCorner[0] // long - E/W - horizontal - degrees from Grenwich - -180 to 180

      // console.debug(
      //   `fitBoundsWithPadding(): diffs lati/long: ${latiDiff}, ${longDiff}`
      // )

      latiPadding = latiDiff / 4
      longPadding = longDiff / 4
    }

    swCorner[0] = swCorner[0] - longPadding
    swCorner[1] = swCorner[1] - latiPadding
    neCorner[0] = neCorner[0] + longPadding
    neCorner[1] = neCorner[1] + latiPadding

    if (debug) {
      console.debug(
        `fitBoundsWithPadding(): after ${longPadding}/${latiPadding} padding: ne corner long/lat: ${neCorner[0]}, ${neCorner[1]}`
      )
      console.debug(
        `fitBoundsWithPadding(): after ${longPadding}/${latiPadding} padding: sw corner long/lat: ${swCorner[0]}, ${swCorner[1]},`
      )
    }

    map.current.fitBounds([swCorner, neCorner], { animate: animate })
  }

  const fitViewToFeaturesAndImages = (
    padding, // = 0.0005,
    animate = true
  ) => {
    //const debug = true
    if (debug)
      console.debug(
        `Map.fitViewToFeaturesAndImages(): called with padding: ${padding}, animate: ${animate}`
      )

    if (!currentMap) {
      if (debug)
        console.debug(
          `Map.fitViewToFeaturesAndImages(): called when currentMap is not set, doing nothing.`
        )

      return
    }
    // 0.0005 is a good default for houses in a village
    //if (currentMap?.mapLinks?.length > 0) {
    // let swCorner = []
    // let neCorner = []

    if (debug)
      console.debug(
        `Map.fitViewToFeaturesAndImages(): called when currentMap is: `,
        currentMap
      )

    // const mapLinksNN = currentMap.mapLinks ?? []
    // // loop around all mapLinks and mapLayerImages
    // const allFeatures = mapLinksNN.concat(currentMap.mapLayerImages)
    const mapLayerImagesArray = currentMap?.mapLayerImages
      ? Object.values(currentMap.mapLayerImages)
      : []
    // loop around all mapLinks and mapLayerImages
    const allFeatures = (currentMap.mapLinks ?? []).concat(mapLayerImagesArray)

    let bounds = getWeAreFeaturesBounds(allFeatures)
    if (!bounds) {
      if (debug)
        console.debug(
          `fitViewToFeaturesAndImages(): currentMap did not contain any features or images to zoom to `,
          currentMap
        )
      return
    }
    if (debug)
      console.debug(
        `fitViewToFeaturesAndImages(): getWeAreFeaturesBounds() returned bounds (sw, ne):`,
        bounds
      )
    const [swCorner, neCorner] = bounds
    if (swCorner[0] === neCorner[0] && swCorner[1] === neCorner[1]) {
      const opts = {
        center: swCorner,
        zoom: singleCoordZoomLevel,
        animate: animate,
      }
      if (debug)
        console.debug(
          `fitViewToFeaturesAndImages(): only one single point, singleCoordZoomLevel: ${singleCoordZoomLevel}, calling map.current.flyTo():`,
          opts
        )
      map.current.flyTo(opts)
    } else {
      fitBoundsWithPadding(swCorner, neCorner, padding, animate)
    }
  }

  const updateMarkerPopup = marker => {
    if (debug) console.debug(`updateMarkerPopup(): called with marker`, marker)
    const popup = marker.getPopup()
    if (popup) {
      const lngLat = marker.getLngLat()

      popup.setText(formatLatiLongAsString(lngLat.lat, lngLat.lng))
    }
  }

  function onMarkerDragEnd(marker, index) {
    console.debug(
      `onMarkerDragEnd(): isEditing: ${isEditing}, preview: ${preview}`
    )
    if (isEditing && !preview) {
      const lngLat = marker.getLngLat()

      // update the popup on the marker to show the new coordinates
      /* marker.setPopup(
        new mapboxgl.Popup({
          offset: 25,
          closeButton: false,
        }).setText(formatLatiLongAsString(lngLat.lat, lngLat.lng))
      )*/

      updateMarkerPopup(marker)

      // setLocations(prevState => {
      //   let newState = [...prevState]
      //   newState[index].marker = marker
      //   newState[index].lat = lngLat.lat
      //   newState[index].lng = lngLat.lng
      //   // address isn't used in singlePoint mode
      //   newState[index].address = formatLatiLongAsString(lngLat.lat, lngLat.lng)

      //   return newState
      // })

      //locations aren't in a slice just local state
      const weareFeature = locations[index]
      if (!weareFeature.target) {
        weareFeature.target = {}
      }
      weareFeature.target.lati = lngLat.lat
      weareFeature.target.long = lngLat.lng
      //   // address isn't used in singlePoint mode
      weareFeature.target.addressText = formatLatiLongAsString(
        lngLat.lat,
        lngLat.lng
      )
    }
  }

  const getReadOnlyShapeSourceId = weareFeatureId => {
    return `READ_ONLY_SHAPES_SOURCE_ID_${weareFeatureId}`
  }
  const getReadOnlyShapeLayerId = weareFeatureId => {
    return `READ_ONLY_SHAPES_LAYER_ID_${weareFeatureId}`
  }
  const getReadOnlyShapeOutlineLayerId = weareFeatureId => {
    return `READ_ONLY_SHAPES_OUTLINE_LAYER_ID_${weareFeatureId}`
  }

  // only called from the JSX
  const createMarker = (
    weareMapFeature,
    forLocationIndex,
    htmlElement,
    htmlElementVerticalOffsetPx
  ) => {
    if (!map.current) {
      return
    }
    if (!weareMapFeature.id) {
      console.error(
        `Map.createMarker(): called with weareMapFeature that has no id:`,
        weareMapFeature
      )
      return
    }
    if (!markersByFeatureIdRef.current[weareMapFeature.id]) {
      const coords = getWeareMapFeatureLongLat(weareMapFeature) // [long, lat]
      //if (location?.lng && location?.lat && !location?.marker) {

      // this skips shapes because they won't have a long/lat, instead they
      // have a feature containing geometry.
      if (coords) {
        const popupText = preferAddressAsMarkerPopup
          ? weareMapFeature?.address || formatLocationCoords(weareMapFeature)
          : weareMapFeature?.title ||
            weareMapFeature?.description ||
            (weareMapFeature?.instanceType === 'address' &&
              weareMapFeature.target.freeText) ||
            (['location', 'event', 'artefact'].includes(
              weareMapFeature?.instanceType
            ) && // a Place
              weareMapFeature.target.address?.freeText) ||
            weareMapFeature?.address ||
            formatLocationCoords(weareMapFeature)

        const popup = new mapboxgl.Popup({
          offset: 25,
          closeButton: false,
        }).setText(popupText)
        // use an empty div as the HTML contents of this 'marker'.
        // The createPortal() code in this component's JSX will set the contents of the div
        // with some custom HTML
        let newMarker
        if (htmlElement) {
          newMarker = new mapboxgl.Marker(htmlElement, {
            //const marker = new mapboxgl.Marker({
            draggable: isEditing && !preview,
          })

          if (htmlElementVerticalOffsetPx) {
            newMarker.setOffset([0, htmlElementVerticalOffsetPx])
          }
        } else {
          newMarker = new mapboxgl.Marker({
            //const marker = new mapboxgl.Marker({
            draggable: isEditing && !preview,
          })
        }

        newMarker.setLngLat(coords).setPopup(popup).addTo(map.current)

        if (debug)
          console.debug(
            `Map.createMarker(): added new Marker at coords: ${coords} for weareMapFeature, newMarker:`,
            weareMapFeature,
            newMarker
          )

        newMarker._element.key = weareMapFeature.id

        if (clickableMarkers) {
          newMarker.getElement().addEventListener('click', e => {
            if (debug) console.debug(`Map.Marker.OnClick(): called`, e)

            e.preventDefault()
            e.stopPropagation()
            handleMarkerClick(weareMapFeature)
          })
        }

        if (isEditing && !preview) {
          newMarker.on('dragend', () =>
            onMarkerDragEnd(newMarker, forLocationIndex)
          )

          newMarker.on('drag', evt => {
            const marker = evt.target
            updateMarkerPopup(marker)
          })
        }

        // don't store the reference to the Marker in currentMap because currentMap is used in other
        // Map instances at the same time
        markersByFeatureIdRef.current[weareMapFeature.id] = newMarker

        //changedLocations = true

        const markerEl = newMarker.getElement()

        markerEl.addEventListener('mouseenter', () => newMarker.togglePopup())
        markerEl.addEventListener('mouseleave', () => newMarker.togglePopup())

        if (debug)
          console.debug(`Map.createMarker(): offset: `, newMarker.getOffset())

        return newMarker
      }
    }
  }

  const create3DTerrain = () => {
    if (!map.current) return // wait for map to initialize
    if (threeDTerrainConfiguredRef.current) {
      return
    }
    threeDTerrainConfiguredRef.current = true
    //map.current.on('style.load', () => {
    // Custom atmosphere styling
    map.current.setFog({
      color: 'rgb(220, 159, 159)', // Pink fog / lower atmosphere
      'high-color': 'rgb(36, 92, 223)', // Blue sky / upper atmosphere
      'horizon-blend': 0.4, // Exaggerate atmosphere (default is .1)
    })

    map.current.addSource('mapbox-dem', {
      type: 'raster-dem',
      url: 'mapbox://mapbox.terrain-rgb',
    })

    map.current.setTerrain({
      source: 'mapbox-dem',
      exaggeration: 1.5,
    })
    //})
  }

  // shapes are added as map sources/layers in read only mode
  // in edit mode they are added as MapboxDraw features so they can be dragged around and resized
  // so the sources/layers need to be removed
  const removeShapeLayers = mapinst => {
    console.debug(
      `removeShapeLayers(): mapinst.getStyle():`,
      mapinst.getStyle()
    )
    mapinst.getStyle()
    mapinst.getStyle().layers.forEach(layer => {
      console.debug(`removeShapeLayers(): considering layer id: ${layer.id}`)
      if (
        layer.id.startsWith('READ_ONLY_SHAPES_LAYER_ID_') ||
        layer.id.startsWith('READ_ONLY_SHAPES_OUTLINE_LAYER_ID_')
      ) {
        mapinst.removeLayer(layer.id)
      }
    })
    Object.keys(mapinst.getStyle().sources).forEach(sourceId => {
      console.debug(`removeShapeLayers(): considering source id: ${sourceId}`)
      if (sourceId.startsWith('READ_ONLY_SHAPES_SOURCE_ID_')) {
        mapinst.removeSource(sourceId)
      }
    })
  }

  const addShapesAsLayers = (mapinst, currentMap) => {
    if (currentMap?.mapLinks?.length > 0) {
      if (debug)
        console.debug(
          `initMap().mapinst.onLoad(): there are mapLinks:`,
          currentMap.mapLayerImages
        )
      currentMap.mapLinks.forEach(weareMapFeature => {
        if (debug)
          console.debug(
            `initMap().mapinst.onLoad(): checking weareMapFeature for shape:`,
            weareMapFeature
          )

        if (weareMapFeature.target?.feature) {
          const featureJson = weareMapFeature.target?.feature
          console.debug(
            `initMap().mapinst.onLoad(): adding featureJson as new source and layer to map:`,
            featureJson
          )
          const sourceId = getReadOnlyShapeSourceId(weareMapFeature.id)
          mapinst.addSource(sourceId, {
            type: 'geojson',
            data: featureJson,
          })

          const layerId = getReadOnlyShapeLayerId(weareMapFeature.id)

          const layerConfig = {
            id: layerId,
            source: sourceId, // reference the data source
            layout: {},
          }

          const outlineLayerId = getReadOnlyShapeOutlineLayerId(
            weareMapFeature.id
          )
          const outlineLayerConfig = {
            ...layerConfig,
            type: 'line',
            id: outlineLayerId,
            paint: {
              'line-color': '#ffffff', // white colour
              'line-width': 2,
            },
            layout: { visibility: 'none' },
          }

          if (featureJson.geometry?.type === 'Polygon') {
            layerConfig.type = 'fill'
            layerConfig.paint = {
              'fill-color': '#0080ff', // blue color fill
              'fill-opacity': 0.5,
            }
          } else {
            layerConfig.type = 'line'
            layerConfig.paint = {
              'line-color': '#0080ff', // blue color
              'line-width': 2,
            }
          }
          mapinst.addLayer(layerConfig)

          mapinst.addLayer(outlineLayerConfig)

          mapinst.on('click', layerId, e => {
            if (debug)
              console.debug(
                `mapinst.onClick(layerId: '${layerId}'): clicked on layer '${layerId}'`,
                e
              )

            // according to the html docs, stopImmediatePropagation is what we need to stop the event
            // triggering other listeners on the same HTML element, but Mapbox seems to ignore that.
            // instead call preventDefault() and in the other listener - handleMapClickNonDropPinMode() -
            // check originalEvent.defaultPrevented
            e.originalEvent.preventDefault()

            handleMarkerClick(weareMapFeature)
          })
          mapinst.on('mouseenter', layerId, () => {
            mapinst.getCanvas().style.cursor = 'pointer'
          })

          // Change it back to a pointer when it leaves.
          mapinst.on('mouseleave', layerId, () => {
            mapinst.getCanvas().style.cursor = ''
          })
          console.debug(
            `initMap().mapinst.onLoad(): added onClick to layerId '${layerId}' for weareMapFeature`,
            weareMapFeature
          )
        }
      })
    } else {
      if (debug) console.debug(`initMap(): no mapLinks:`, currentMap)
    }
  }

  const onGeocoderBoxResult = result => {
    //this is called from a Mapbox event so outside the React context, we don't have access to up-to-date local state
    const isEditing = isEditingRef.current

    console.debug(
      `Map.onGeocoderBoxResult(): isEditing: ${isEditing}, called with result:`,
      result
    )

    geocoderResultRef.current = result

    const popup = new mapboxgl.Popup({
      //offset: 25,
      closeButton: true,
      closeOnClick: true,
    }) //.setText(result.result.place_name)

    //this is deleted by a useEffect return fn
    // document.addMarkerForGeocodeSearchResult = () => {
    //   // console.debug(
    //   //   `addMarkerForAddress(): mostRecentResult:`,
    //   //   mostRecentResult
    //   // )
    //   console.debug(`document.addMarkerForAddress(): result:`, result)
    //   addFeatureFromGeocoderResult(result)
    //   popup.remove()
    // }

    //this is deleted by a useEffect return fn
    // document.updateFactLocation = () => {
    //   // console.debug(
    //   //   `updateFactLocation(): mostRecentResult:`,
    //   //   mostRecentResult
    //   // )
    //   console.debug(`document.updateFactLocation(): result:`, result)
    //   updateFactLocation(result)
    // }

    const Icon = getIconForLinkType(result.properties?.weare_link?.instanceType)

    const iconHtml = ReactDOMServer.renderToString(<Icon fontSize="small" />)
    const iconPopupWrapperHtml = `<div style="vertical-align: middle; display: inline-block; width: 24px; height: 24px;">${iconHtml}</div>`

    let html = `<div style="text-align: center; margin-top: 10px;">
        ${
          // result.properties?.weare_link?.type === 'location'
          //   ? houseIconPopupWrapperHtml
          //   : result.properties?.weare_link?.type === 'fact'
          //   ? birthIconPopupWrapperHtml
          //   : ''
          iconPopupWrapperHtml
        }

          ${
            result.properties?.weare_link?.display
              ? `${result.properties.weare_link.display}<br/><br/>`
              : ''
          }
          ${result.place_name}<br/>
        ${
          result.properties?.weare_link?.photo?.fileThumbnail
            ? `<img src='${result.properties.weare_link.photo?.fileThumbnail}' />`
            : ''
        }
        <br/>
        ${
          // if weare_geocoding_of is set that means the user first looked up an archive item that has no
          // geocoding, then searched again with an address
          isEditing && result.properties?.weare_geocoding_of
            ? ` <button onClick='document.updateItemLocation()'>Update location of ${getTypeDisplayText(
                result.properties.weare_geocoding_of.instanceType
              )} '${
                result.properties.weare_geocoding_of.title
              }' and add to map</button>`
            : ''
        }
        ${
          isEditing && !result.properties?.weare_geocoding_of
            ? singlePoint
              ? `
        <button onClick='document.addMarkerForGeocodeSearchResult({singlePoint: true})' ${
          result.properties?.weare_geocoding_of ? 'disabled=true' : ''
        }>Select this location</button>
       `
              : `
        <button onClick='document.addMarkerForGeocodeSearchResult({})' ${
          result.properties?.weare_geocoding_of ? 'disabled=true' : ''
        }>Add ${getTypeDisplayText(
                  result.properties?.weare_link?.instanceType
                )} to map</button>
       `
            : ''
        }
      </div>`

    if (debug) console.debug(`Map.onGeocoderBoxResult(): popup html:`, html)

    popup.setHTML(html)

    popup.setLngLat(result.geometry.coordinates)
    //add it again to the map to make it pop up
    //}

    if (debug)
      console.debug(`Map.onGeocoderBoxResult(): adding popup to map...`)
    popup.addTo(map.current)
    addMarkerPopupRef.current = popup
    popup.on('close', () => {
      console.debug(
        `Map.onGeocoderBoxResult().popup.onClose(): closing popup, clearing addMarkerPopupRef and geocodingItem local state...`
      )
      addMarkerPopupRef.current = undefined
      setGeocodingItem(undefined)
    })
  }

  // called by geocoder.on('result')
  // not much point in this fn really, onGeocoderBoxResult will show a popup, user hasn't clicked a button yet
  const onGeocodedExistingItemHandler = (
    itemToSetLocationOf,
    geocodedResult
  ) => {
    console.debug(
      `Map.onGeocodedExistingItemHandler(): user selected address after selecting un-located ${itemToSetLocationOf.instanceType} id '${itemToSetLocationOf.id}' to '${geocodedResult.place_name}': ${geocodedResult.geometry.coordinates}`,
      geocodedResult
    )
  }

  const initMap = () => {
    if (!mapContainer.current) {
      // can't init the map until the JSX is rendered
      return
    }
    if (initMapStartedRef.current) return
    initMapStartedRef.current = true
    if (map.current) return // initialize map only once
    console.debug(`Map.initMap(): running... currentMap:`, currentMap)
    var mapInitialCenterCoords = null
    const willFitToFeaturesOrImages =
      currentMap?.mapLinks?.length >= 1 ||
      (currentMap?.mapLayerImages &&
        Object.keys(currentMap.mapLayerImages).length >= 1)
    if (debug)
      console.debug(
        `Map.initMap(): willFitToMarkers: ${willFitToFeaturesOrImages} - currentMap?.mapLinks?.length: ${currentMap?.mapLinks?.length}`
      )
    if (!willFitToFeaturesOrImages) {
      // need to select a point to center on, using the provided zoom
      if (currentMap?.mapLinks?.length === 1) {
        mapInitialCenterCoords = {
          // lat: currentMap?.mapLinks[0].target.latiGed ?? currentMap?.mapLinks[0].target.lati,
          // lon: currentMap?.mapLinks[0].target.longGed ?? currentMap?.mapLinks[0].target.long,
          lat: currentMap?.mapLinks[0].target.lati,
          lon: currentMap?.mapLinks[0].target.long,
        } // [lng, lat]
      } else {
        // map has no features/images yet
        //mapInitialCenterCoords = await getUserLatLng()
        // this can fail if user uses an adblocker
        // in which case the coordinates for central USA are returned
        mapInitialCenterCoords = userLatLng
        if (debug)
          console.debug(
            `Map.initMap(): willFitToMarkers: ${willFitToFeaturesOrImages} - currentMap?.mapLinks?.length: ${currentMap?.mapLinks?.length}`
          )
      }
    }

    var mapInitialCenter = null
    if (debug)
      console.debug(
        `Map.initMap(): mapInitialCenterCoords:`,
        mapInitialCenterCoords
      )
    if (
      mapInitialCenterCoords &&
      'lat' in mapInitialCenterCoords &&
      'lon' in mapInitialCenterCoords
    ) {
      mapInitialCenter = { center: mapInitialCenterCoords }
    } else {
      if (debug)
        console.debug(
          `Map.initMap(): mapInitialCenterCoords not set or has no lat/lon, not setting mapInitialCenter`,
          mapInitialCenterCoords
        )
    }
    if (debug)
      console.debug(
        `Map.initMap(): creating new mapboxgl.Map()... mapInitialCenter:`,
        mapInitialCenter
      )

    const mapinst = new mapboxgl.Map({
      container: mapContainer.current,
      //   style: `${MAP_STYLE}?optimise=true`,
      style: 'mapbox://styles/mapbox/satellite-streets-v12?optimize=true',
      zoom: zoom,
      antialias: false, // create the gl context with MSAA antialiasing, so custom layers are antialiased
      zoomControl: false, //interactive !== undefined ? interactive : true,
      ...mapInitialCenter,
      // not specifying 'interactive' here because we want to be able to toggle it without recreating the map
    })
    map.current = mapinst

    //    const styles = [
    //      {
    //        title: 'Standard',
    //        uri: 'mapbox://styles/mapbox/standard',
    //      },
    //      {
    //        title: 'Streets',
    //        uri: 'mapbox://styles/mapbox/streets-v12',
    //      },
    //      {
    //        title: 'Satellite',
    //        uri: 'mapbox://styles/mapbox/satellite-v9',
    //      },
    //      {
    //        title: 'Satellite-Streets',
    //        uri: 'mapbox://styles/mapbox/satellite-streets-v12',
    //      },
    //      {
    //        title: 'Outdoors',
    //        uri: 'mapbox://styles/mapbox/outdoors-v12',
    //      },
    //      {
    //        title: 'Dark',
    //        uri: 'mapbox://styles/mapbox/dark-v11',
    //      },
    //      {
    //        title: 'Light',
    //        uri: 'mapbox://styles/mapbox/light-v11',
    //      },
    //    ]

    //mapinst.addControl(new MapboxStyleSwitcherControl(styles, 'Standard'))

    // mapinst.on('move', args => {
    //   // detect if the user has flipped us between 2d (100% top down) or 3d (any sort of angle) modes
    //   const map = args.target
    //   const camera = map.getFreeCameraOptions()
    //   const cameraOrientation = camera.orientation
    //   const new3dValue =
    //     cameraOrientation[0] !== 0 || cameraOrientation[1] !== 0
    //   // console.debug(
    //   //   `Map.onMove(): threeD already: ${threeDRef.current}, new3dValue: ${new3dValue}`
    //   // )
    //   if (new3dValue !== threeDRef.current) {
    //     //setThreeD(cameraOrientation[0] !== 0 || cameraOrientation[1] !== 0)
    //     setThreeD(new3dValue)
    //   }
    // })

    //if (debug) console.debug(`initMap(): calling setupMapLinks()...`)
    //setupMapLinks()

    if (flyToMarkersIn2d) {
      //don't let user enter 3d mode with the right mouse button drag because
      //we haven't enabled 3d terrain yet
      mapinst['dragRotate'].disable()
    }

    mapinst.on('load', () => {
      if (!initialMapIsFullWindow && (mapIsFullScreen || mapIsFullWindow)) {
        // if map is mounted within a height-constrained parent (it is inline) but then gets
        // expanded because initialMapIsFullWindow is false, ensure it resizes itself to use its
        // new space
        if (mapinst) {
          if (debug)
            console.debug(
              `initMap().mapinst.onLoad(): initialMapIsFullWindow was set, calling mapinst.resize()...`
            )
          mapinst.resize()
        }
      }
      const mapLayerImagesArray = currentMap.mapLayerImages
        ? Object.values(currentMap.mapLayerImages)
        : []
      if (
        !isEditing &&
        (mapLayerImagesArray.length > 0 || currentMap?.mapLinks?.length > 0)
      ) {
        //in editing mode, useEditableMapImage.addImagesFromCurrentMap() will call addEditableImageToGlobe()

        if (debug)
          console.debug(
            `initMap().mapinst.onLoad(): not editing, looking for mapLayerImages...`,
            currentMap
          )
        if (mapLayerImagesArray.length > 0) {
          if (debug)
            console.debug(
              `initMap().mapinst.onLoad(): there are mapLayerImages:`,
              mapLayerImagesArray
            )
          mapLayerImagesArray.forEach(mapLayerImage => {
            if (debug)
              console.debug(
                `initMap().mapinst.onLoad(): calling addImageToGlobe() with mapLayerImage:`,
                mapLayerImage
              )
            mapImageHookResponse.addImageToGlobe(
              mapinst,
              mapLayerImage.id,
              mapLayerImage.fileName,
              mapLayerImage.coordinates
            )
          })
        } else {
          if (debug) console.debug(`initMap(): no mapLayerImages:`, currentMap)
        }

        addShapesAsLayers(mapinst, currentMap)
      }
    })

    if (willFitToFeaturesOrImages) {
      if (debug)
        console.debug(
          `Map.initMap(): willFitToMarkers set, calling fitViewToMarkers(fitViewToMarkersPadding: ${fitViewToMarkersPadding})...`
        )
      fitViewToFeaturesAndImages(fitViewToMarkersPadding, false)
    }
    if (interactive) {
      enableInteractive()
    } else {
      disableInteractive()
    }

    // const houseIconHtml = ReactDOMServer.renderToString(
    //   <HouseIcon fontSize="small" />
    // )
    // const birthIconHtml = ReactDOMServer.renderToString(
    //   <CribIcon fontSize="small" />
    // )

    // const houseIconPopupWrapperHtml = `<div style="vertical-align: middle; display: inline-block; width: 24px; height: 24px;">${houseIconHtml}</div>`
    // const birthIconPopupWrapperHtml = `<div style="vertical-align: middle; display: inline-block; width: 24px; height: 24px;">${birthIconHtml}</div>`

    if (!singlePoint && !isEditing) {
      //handleMapClickNonDropPinMode closes the side panel
      mapinst.on('click', handleMapClickNonDropPinMode)
    }

    // enter drop pin mode if we are in editing and singlePoint mode and there is no point already
    if (isEditing && singlePoint && mapIsEmpty(currentMap)) {
      // console.debug(
      //   `initMap(): currentMap has 0 links - currentMap?.mapLinks?.length: '${currentMap?.mapLinks?.length}', currentMap:`,
      //   currentMap
      // )
      setTimeout(() => {
        setDropPinMode(true)
      }, 150)
    }

    setInitMapCompleted(mapinst)
  } // end initMap()

  const mapIsEmpty = currentMap => {
    return (currentMap?.mapLinks?.length ?? 0) < 1
  }

  const handleMapClickDropPinMode = e => {
    if (debug)
      console.debug(
        `Map.handleMapClickDropPinMode: called; dropPinMode: ${dropPinMode}`,
        e
      )
    const lngLat = e.lngLat
    if (
      dropPinMode &&
      (!locations ||
        locations.length === 0 ||
        (locations[locations.length - 1]?.lat !== lngLat.lat &&
          locations[locations.length - 1]?.lng !== lngLat.lng))
    ) {
      if (debug)
        console.debug(
          `Map.handleMapClickDropPinMode: dropPinMode and the location is different to the last item's location`
        )
      //const latiLongString = formatLatiLongAsString(lngLat.lat, lngLat.lng)
      //let newLocations = [...locations]
      const addedLocation = {
        id: createTemporaryMarkerId(lngLat.lat, lngLat.lng),
        instanceType: 'address',
        //newFeatureTemporaryTitle: 'New marker',
        target: {
          long: lngLat.lng,
          lati: lngLat.lat,
          coordinatesSource: 'P', // P=picked
        },
      }

      if (!locations[locations?.length - 1]?.id) {
        //there is no location or the last location has no id
        const index = locations?.length - 1 < 0 ? 0 : locations?.length - 1
        locations[index] = addedLocation
      } else {
        locations.push(addedLocation)
      }
      if (debug)
        console.debug(
          `Map.handleMapClickDropPinMode: added clicked point to locations, locations:`,
          locations
        )
      //setLocations(newLocations)
      // setCurrentMap(existing => {
      //   existing.locations = newLocations
      //   return existing
      // })
      if (debug)
        console.debug(
          `Map.handleMapClickDropPinMode: added clicked point to locations, currentMap:`,
          currentMap
        )
      const newMarkerIndex = locations.length - 1
      if (currentMarkerIndex !== newMarkerIndex) {
        if (debug)
          console.debug(
            `Map.handleMapClickDropPinMode(): currentMarkerIndex: ${currentMarkerIndex}, calling setCurrentMarkerIndex(newMarkerIndex: ${newMarkerIndex})...`
          )
        setCurrentMarkerIndex(newMarkerIndex)
      }
      setDropPinMode(false)
      if (debug) {
        console.debug(`Map.handleMapClickDropPinMode: locations:`, locations)
        console.debug(
          `Map.handleMapClickDropPinMode: calling layoutMarkers()... currentMap:`,
          currentMap
        )
      }
      //TODO when marker clicked deselect anything in Draw
    }
  }

  // callback to map.on('click')
  // as a Mapbox callback local state vars will be out of date!
  const handleMapClickNonDropPinMode = async e => {
    const debug = true

    let isEditing
    await setIsEditing(existingValue => {
      isEditing = existingValue
      return existingValue
    })

    if (e.originalEvent?.defaultPrevented) {
      if (debug)
        console.debug(
          `Map.handleMapClickNonDropPinMode: called; defaultPrevented is true, doing nothing`,
          e
        )
      return
    }
    if (debug)
      console.debug(
        `Map.handleMapClickNonDropPinMode: called; dropPinMode: ${dropPinMode}, isEditing: ${isEditing}, fullwindowOnMapClick: ${fullwindowOnMapClick}, mapIsFullWindow: ${mapIsFullWindow}, mapIsFullScreen: ${mapIsFullScreen}`,
        e
      )

    // if map background clicked and prop 'fullwindowOnMapClick' was set to true, fill the window
    if (fullwindowOnMapClick && !mapIsFullWindow && !mapIsFullScreen) {
      setMapIsFullWindow(true)
      return
    }

    //see if the user clicked on any features or just the background
    //(this event callback can fire before any other event callback like handleShapeSelected can set defaultPrevented)
    const featuresUnderPointer = map.current.queryRenderedFeatures(e.point)
    if (debug)
      console.debug(
        `Map.handleMapClickNonDropPinMode: featuresUnderPointer: `,
        featuresUnderPointer
      )

    if (featuresUnderPointer && featuresUnderPointer.length > 0) {
      return
    }

    // clicked on map background, deselect any selected feature and close side panel
    if (debug)
      console.debug(
        `Map.handleMapClickNonDropPinMode: no features under pointer, calling setShowSidePanel(false)`,
        e
      )
    //hide the side panel when clicking on the map background
    setShowSidePanel(false)

    // wait for the close animation to finish before deselecting the feature
    // because the sidebar flips from the single feature to showing the list
    // and this looks quite janky
    setTimeout(() => {
      setCurrentMarkerIndex(-1)
    }, 200) // 400 is ok
  }
  // // called by initMap() and useEffect[currentMap?.mapLinks]
  // const setupMapLinks = useCallback(() => {
  //   if (currentMap?.mapLinks?.length > 0) {
  //     let mapLocations = []
  //     currentMap?.mapLinks?.forEach((mapLink, index) => {
  //       mapLocations.push({
  //         ...mapLink,
  //         icon: getIconForLinkType(mapLink.type),
  //       })
  //     })

  //     setLocations(mapLocations)
  //   }
  // }, [currentMap?.mapLinks])

  // useEffect(() => {
  //   setupMapLinks()
  // }, [currentMap?.mapLinks, setupMapLinks])

  useEffect(() => {
    if (map?.current) {
      if (dropPinMode) {
        map.current.getCanvas().style.cursor = 'crosshair'
        map.current?.off('click', handleMapClickNonDropPinMode)
        map.current?.on('click', handleMapClickDropPinMode)
        // console.debug(
        //   `Map.useEffect([dropPinMode]): set cursor style to 'crosshair' and registered handleMapClick...'`
        // )
      } else {
        map.current.getCanvas().style.cursor = 'auto'
        map.current?.off('click', handleMapClickDropPinMode)
        map.current?.on('click', handleMapClickNonDropPinMode)
      }
    }

    return () => {
      if (map?.current) {
        map?.current?.off('click', handleMapClickDropPinMode)
        map.current.getCanvas().style.cursor = 'auto'
      }
    }
    // eslint-disable-next-line
  }, [dropPinMode])

  // useEffect(() => {
  //   console.debug(`Map.useEffect([]): calling initMap()...`)
  //   initMap()
  //   // create3DTerrain is now called in map onLoad create3DTerrain()
  // }, []) //only want initMap to be called the first time Map is rendered

  initMap() //uses a Ref so it won't run multiple times

  const fullscreenChangeEventListener = useCallback(
    event => {
      // console.debug(`Map.fullscreenChangeEventListener() fired`)
      if (!document.fullscreenElement) {
        setMapIsFullScreen(false)
        if (mapRoot.current) {
          mapRoot.current.removeEventListener(
            'fullscreenchange',
            fullscreenChangeEventListener
          )
        }
      }
    },
    [setMapIsFullScreen]
  )

  useEffect(() => {
    if (mapRoot.current) {
      if (mapIsFullScreen === true) {
        mapRoot.current.requestFullscreen()
        mapRoot.current.addEventListener(
          'fullscreenchange',
          fullscreenChangeEventListener
        )
      } else {
        if (document.fullscreenElement) {
          document.exitFullscreen()
        }
      }
    }
  }, [mapIsFullScreen, fullscreenChangeEventListener])

  const fullWindowStyle = useMemo(() => {
    return {
      position: 'fixed',
      top: 0,
      left: 0,
      //height: '100%',
      //width: '100%',
      zIndex: ZINDEX_MAP_FULL_WINDOW, // BELOW the exit dialog and ABOVE the top bar, side panel, 'ask family' widget
    }
  }, [])

  const nonFullWindowStyle = useMemo(
    () => {
      return {
        position: 'relative', // this needs to be relative or our controls (up/down/3d) disappear
        //height: inlineMapHeight,
      }
    },
    [
      //inlineMapHeight
    ]
  )

  useEffect(() => {
    if (debug)
      console.debug(
        `Map.useEffect([mapIsFullWindow=${mapIsFullWindow}]): calling setMapHeight() then setMapRootStyle()...`
      )
    setMapHeight(mapIsFullWindow ? '100%' : inlineMapHeight)
    setMapRootStyle(mapIsFullWindow ? fullWindowStyle : nonFullWindowStyle)
    // the above style change puts the map in a much larger container so ask the MapBox SDK
    // to resize itself.
    if (map.current) {
      // we need the reactions to the above setxxx()s to execute to apply the changed styles BEFORE we ask MapBox to resize itself
      // so use a setTimeout() with a 0ms delay to run on the next tick.
      const mc = map.current
      setTimeout(() => {
        mc.resize()
      }, 0)
      // } else {
      //   console.debug(
      //     `Map.useEffect([mapIsFullWindow=${mapIsFullWindow}]): map.current is NOT set, cannot call resize()...`
      //   )
    }
  }, [
    mapIsFullWindow,
    fullWindowStyle,
    nonFullWindowStyle,
    inlineMapHeight,
    debug,
  ])

  const getIconForLinkType = type => {
    let icon
    switch (type) {
      case 'location':
        icon = HouseIcon
        break
      case 'event':
        icon = EventIcon
        break
      case 'artefact':
        icon = MilitaryTechIcon
        break
      case 'fact':
        icon = CribIcon
        break
      default:
        icon = PlaceIcon
    }

    return icon
  }

  //called by button in popup created in onGeocoderBoxResult()
  const addFeatureFromGeocoderResult = geocoderResult => {
    if (debug)
      console.debug(
        `Map.addFeatureFromGeocoderResult: called with geocoderResult:`,
        geocoderResult
      )
    if (!geocoderResult.geometry?.coordinates) {
      console.error(
        `addFeatureFromGeocoderResult(): passed geocoderResult has no .geometry.coordinates:`,
        geocoderResult
      )
    }

    // // we might have overwritten the place_name to put some styling/formatting in for display in the autocomplete list
    // if (geocoderResult.properties.original_place_name) {
    //   geocoderResult.place_name = geocoderResult.properties.original_place_name
    // }

    //const coords = geocoderResult.geometry.coordinates
    //const hasLink = !!geocoderResult.properties?.weare_link

    const icon = useDefaultMarkerIconsOnMap
      ? PlaceIcon
      : getIconForLinkType(geocoderResult?.properties?.weare_link?.instanceType)

    const newWeareMapFeature = {
      id: `SORTABLE-ID-${Math.random()}`,
      icon: icon,
      ...(geocoderResult.properties?.weare_link
        ? {
            // a Place or something
            target: geocoderResult.properties?.weare_link,
            instanceType: geocoderResult.properties?.weare_link.instanceType,
          }
        : geocoderResult.geometry.coordinates && {
            // a mapbox geo search result
            target: {
              instanceType: 'address',
              freeText: geocoderResult.place_name,
              long: geocoderResult.geometry.coordinates[0],
              lati: geocoderResult.geometry.coordinates[1],
              coordinatesSource: 'L', //looked up using Mapbox geocoder
            },
            instanceType: 'address',
          }),
    }
    // console.debug(
    //   `Map.addFeatureFromGeocoderResult(): newWeareMapFeature:`,
    //   newWeareMapFeature
    // )
    if (debug)
      console.debug(
        `Map.addFeatureFromGeocoderResult(): calling setLocations() including newWeareMapFeature:`,
        newWeareMapFeature
      )
    setLocations(prevState => [
      ...prevState, //.filter(locationItem => locationItem.lat && locationItem.lng),
      newWeareMapFeature,
    ])
  }

  const addAndSelectNewLocation = newLocation => {
    const debug = true
    // MapboxDraw callbacks don't have access to the up-to-date state so do this in a setter callback
    setLocations(existingLocations => {
      if (debug)
        console.debug(
          `addAndSelectNewLocation(): before replace, existingLocations are: `,
          existingLocations
        )
      //let newLocations = [...existingLocations]
      let newLocations = existingLocations
      if (debug)
        console.debug(
          `addAndSelectNewLocation(): before add, locations are:`,
          newLocations
        )
      // if last item in the locations array doesn't have an id then replace it with this one
      if (!newLocations[newLocations?.length - 1]?.id) {
        const index =
          newLocations?.length - 1 < 0 ? 0 : newLocations?.length - 1
        newLocations[index] = newLocation
      } else {
        newLocations.push(newLocation)
      }
      if (debug)
        console.debug(
          `addAndSelectNewLocation(): after add, replacing locations with:`,
          newLocations
        )
      if (debug)
        console.debug(
          `Map.addAndSelectNewLocation(): calling setCurrentMarkerIndex(${
            newLocations.length - 1
          })...`
        )
      setCurrentMarkerIndex(newLocations.length - 1)
      if (debug)
        console.debug(
          `Map.addAndSelectNewLocation(): calling setShowSidePanel(true)...`
        )
      setShowSidePanel(true)
      return newLocations
    })
  }

  //TODO move Map.handleDrawAction to MapEdit
  //
  // passed to MapEditComponent, called from useMapEdit.onNewShapeDrawn(), which is the 'draw.create' event callback
  const handleDrawAction = (action, feature) => {
    if (debug)
      console.debug(
        `Map.handleDrawAction(): action: '${action}', feature:`,
        feature
      )
    if (action === 'FeatureAdded') {
      if (feature.geometry) {
        if (['Polygon', 'LineString'].includes(feature.geometry.type)) {
          if (debug)
            console.debug(
              `Map.handleDrawAction(): feature is a polygon or linestring`,
              feature
            )
          const addedLocation = {
            id: feature.id,
            instanceType: 'address', // geometry gets saved in an Address record
            newFeatureTemporaryTitle: 'New shape',
            target: {
              feature: feature,
            },
          }
          addAndSelectNewLocation(addedLocation)
        } else {
          console.debug(
            `Map.handleDrawAction(): feature is not a polygon or linestring, treating as a point/marker. feature.geometry.type: '${feature.geometry.type}'`,
            feature
          )
          //treat as a point/marker
          //const lngLat = feature.geometry.coordinates
          const coords = coordsToArray(feature.geometry.coordinates)
          const addedLocation = {
            id: feature.id ?? createTemporaryMarkerId(coords[0], coords[1]),
            instanceType: 'address',
            newFeatureTemporaryTitle: 'New marker',
            target: {
              long: coords[0],
              lati: coords[1],
              coordinatesSource: 'P', // P=picked
            },
          }

          addAndSelectNewLocation(addedLocation)
        }
      } else {
        if (debug)
          console.debug(
            `Map.handleDrawAction(): action: '${action}', feature has no geometry:`,
            feature
          )
      }
    } else {
      if (debug)
        console.debug(
          `Map.handleDrawAction(): action: '${action}' not handled by handleDrawAction().`
        )
    }
  }

  const onCurrentMarkerIndexChangedBySidebar = newIndex => {
    if (debug)
      console.debug(
        `Map.onCurrentMarkerIndexChangedBySidebar(): existing currentMarkerIndex: ${currentMarkerIndex}, calling setCurrentMarkerIndex(${newIndex})...`
      )
    setCurrentMarkerIndex(newIndex)
    // if (response  handleFeatureSelected) {
    //   let currentMarker
    //   if (newIndex !== null && newIndex !== undefined && newIndex > -1 && locations && newIndex < locations.length) {
    //     currentMarker = locations[newIndex]
    //   }
    //   handleFeatureSelected(currentMarker)
    // }

    //MapEditComponent should be re-rendered with a new currentFeature
  }

  const findIndexOfWeareMapFeature = weareMapFeature => {
    console.debug(
      `findIndexOfWeareMapFeature(): looking for feature id '${weareMapFeature.id}' in`,
      locations
    )
    if (weareMapFeature.id) {
      return locations.findIndex(wamf => wamf.id === weareMapFeature.id)
    } else {
      return locations.findIndex(wamf => wamf === weareMapFeature)
    }
  }

  console.debug(
    `Map: render returning JSX. isEditing: '${isEditing}', mapImageHookResponse': ${mapImageHookResponse}', locations: '${locations}', currentMap`,
    currentMap
  )

  return (
    <Box
      id="map-root"
      ref={mapRoot}
      sx={{
        height: mapHeight,
        width: '100%',
        display: 'flex',
        ...mapRootStyle, // either fullWindowStyle or nonFullWindowStyle
      }}
    >
      <div
        id="map-container"
        ref={mapContainer}
        className={classes.mapContainer}
        style={{ width: '100%' }}
      />

      <div key={`map_marker_html_overrides`}>
        {
          // if any of the locations have a Mapbox Marker defined for them, generate the HTML
          //  for the marker and set it inside the Marker's element.
          !hideUi &&
            locations &&
            Array.isArray(locations) &&
            locations.map((item, index) => {
              if (item && item.id) {
                let marker = markersByFeatureIdRef.current[item.id]
                if (!marker) {
                  marker = createMarker(
                    item,
                    index,
                    document.createElement('div'),
                    -13 // this bumps the marker svg up by 13 pixels so that the arrow at the bottom points to the location
                  )
                }
                if (marker) {
                  if (debug)
                    console.debug(
                      `Map JSX: currentMarkerIndex: ${currentMarkerIndex}, item:`,
                      item
                    )

                  if (debug)
                    console.debug(
                      `Map JSX: marker.getElement():`,
                      marker.getElement()
                    )
                  const isSelected =
                    item?.id === locations?.[currentMarkerIndex]?.id
                  if (debug) console.debug(`Map JSX: isSelected: ${isSelected}`)
                  if (!item.icon) {
                    item.icon = useDefaultMarkerIconsOnMap
                      ? PlaceIcon
                      : getIconForLinkType(item.instanceType)
                  }
                  const MarkerIcon = item.icon || PlaceIcon
                  return (
                    <div key={`map_marker_portal_wrapper_${item.id}`}>
                      <>
                        {/* executes the given JSX and overwrites the marker's element with it */}
                        {createPortal(
                          <div
                            key={`map_marker_${item.id}`}
                            style={{
                              transform: isSelected ? 'scale(1.5)' : 'scale(1)',
                              transformOrigin: 'bottom',
                              transitionDuration: '0.3s',
                            }}
                          >
                            <MarkerIcon
                              fontSize="large"
                              color="error"
                              stroke={isSelected ? 'white' : 'black'}
                            />{' '}
                          </div>,
                          marker.getElement()
                        )}
                      </>
                    </div>
                  )
                }
              }
              return null //<div key={`empty_map_marker_${item?.id}`}></div>
            })
        }
      </div>

      {interactive && (
        <>
          <MapControls
            map={map.current}
            allowThreeD={allowThreeD && !isEditing}
            flyToMarkersIn2d={flyToMarkersIn2d}
            setFlyToMarkersIn2d={setFlyToMarkersIn2d}
          />
        </>
      )}
      <Box
        id="weAreMapOverlay"
        sx={{
          position: 'absolute', // absolute allows this box to overlay the map
          width: '100%',
          height: '100%',
          //marginLeft: '10px',
          //marginTop: `${singlePoint || addSearchBoxMapControl ? 40 : 10}px`, //bump down below the search box
          //marginTop: '10px',
          display: 'flex',
          flexDirection: 'column',
          // pointerEvents: 'none',
        }}
        className={classes.passClicks}
      >
        <Box
          id="expandButtonAndEditComponentBar"
          sx={{
            display: 'flex',
            alignItems: 'stretch', // makes the button container be the same height as the maximise button container
            gap: '8px',
          }}
          className={classes.passClicks} // gives direct child elements 'pointerEvents: auto'
        >
          <>
            {((showMaximizeToFullwindowButton && !mapIsFullWindow) ||
              (showMaximizeToFullscreenButton && !mapIsFullScreen)) && (
              // maximise button
              <Box
                sx={{
                  backgroundColor: 'rgba(255,255,255,0.6)',
                  padding: 0.3,
                  display: 'flex',
                  borderRadius: '4px',
                }}
              >
                <IconButton
                  permissionAction={ACTION_ALL_ACCESS}
                  onClick={e => {
                    if (onFullWindowButtonClick) {
                      return onFullWindowButtonClick(e)
                    }
                    if (mapIsFullScreen) {
                      setMapIsFullScreen(false)
                      setMapIsFullWindow(false)
                    } else {
                      if (mapIsFullWindow) {
                        setMapIsFullScreen(true)
                      } else {
                        if (showMaximizeToFullwindowButton) {
                          setMapIsFullWindow(true)
                        } else {
                          setMapIsFullScreen(true)
                        }
                      }
                    }
                  }}
                >
                  {/* putting a close button in the same place is confusing when there are three levels of zoom {mapIsFullScreen ? (
                    <CloseFullscreenIcon size="large" />
                  ) : ( */}
                  <FullscreenIcon size="large" />
                  {/* )} */}
                </IconButton>
                {/* </Box> */}
              </Box>
            )}
            {(singlePoint ||
              addSearchBoxMapControl ||
              ((mapIsFullScreen || mapIsFullWindow) &&
                addSearchBoxMapControlWhenFullWindow)) && (
              <Box
                id="searchbox-container"
                sx={{ pt: '10px', ml: '10px', zIndex: 10 }}
              >
                {/* needs a zIndex to position the pick-list above the sidebar */}
                <GeocoderBox
                  mapinst={map.current}
                  resultHandler={onGeocoderBoxResult}
                  onGeocodedExistingItemHandler={onGeocodedExistingItemHandler}
                  geocodingItem={geocodingItem}
                  setGeocodingItem={setGeocodingItem}
                />
              </Box>
            )}
            {isEditing && mapImageHookResponse && map.current && !singlePoint && (
              <MapEditComponent
                mapinst={map.current}
                currentMap={currentMap}
                weAreMapFeatures={locations}
                currentFeature={locations && locations[currentMarkerIndex]}
                handleShapeSelected={(
                  e,
                  selectedWeareMapFeatureIndex,
                  selectedWeareMapFeature
                ) => {
                  if (debug)
                    console.debug(
                      `Map.MapEditComponent props.handleShapeSelected(): called with idx '${selectedWeareMapFeatureIndex}' and feature`,
                      selectedWeareMapFeature
                    )
                  if (
                    selectedWeareMapFeatureIndex !== null &&
                    selectedWeareMapFeatureIndex > -1
                  ) {
                    if (debug)
                      console.debug(
                        `Map.handleShapeSelected(): calling setCurrentMarkerIndex(${selectedWeareMapFeatureIndex})...`
                      )

                    // this callback could be called from a Mapbox or MapboxDraw callback so not have access to the latest React local state
                    setCurrentMarkerIndex(selectedWeareMapFeatureIndex)
                  }
                }}
                mapImageHookResponse={mapImageHookResponse}
                handleDrawAction={handleDrawAction}
                setHideUi={setHideUi}
              />
            )}
          </>
        </Box>

        {/* <TransitionGroup> */}
        {(clickableMarkers || mapIsFullScreen || mapIsFullWindow) && (
          // this includes the Close button
          <Box
            id="mapsidepanel-container"
            style={{
              position: 'absolute', // don't get pushed below the expandButtonAndEditComponentBar
              top: 0,
              width: '100%',
              height: '100%',
              textAlign: 'left', // when used as an embedded inline map - not in a dialog - a containing div might have set textAlign to center
            }}
            className={classes.passClicks}
          >
            <MapSidePanel
              id={id}
              closeMap={closeMapParams => {
                if (debug)
                  console.debug(
                    `Map.MapSidePanel props.closeMap(): called with closeMapParams:`,
                    closeMapParams
                  )

                const reduceMapToInline = () => {
                  if (mapIsFullScreen) {
                    setMapIsFullScreen(false)
                  }
                  if (mapIsFullWindow) {
                    setMapIsFullWindow(false)
                  }
                }
                if (closeMap) {
                  closeMap(closeMapParams, reduceMapToInline)
                } else {
                  reduceMapToInline()
                }
              }}
              hideUi={hideUi} // hides everything - title, left panel, bottom-right buttons
              currentMap={currentMap}
              isEditing={isEditing}
              navigateMarkers={navigateMarkers}
              setLocations={setLocations}
              locations={locations}
              currentMarker={locations && locations[currentMarkerIndex]}
              onSave={onSave}
              resetMap={resetMap}
              setCurrentMarkerIndex={onCurrentMarkerIndexChangedBySidebar}
              flyToEditMode={flyTo2D}
              allowThreeD={allowThreeD}
              flyToMarkersIn2d={flyToMarkersIn2d}
              setFlyToMarkersIn2d={setFlyToMarkersIn2d}
              preview={preview}
              setPreview={setPreview}
              dropPinMode={dropPinMode}
              setDropPinMode={setDropPinMode}
              //maxHeight={mapHeight === '100%' ? undefined : mapHeight - 50}
              showLatLong={sidePanelShowLatLong}
              mapIsFullScreen={mapIsFullScreen}
              mapIsFullWindow={mapIsFullWindow}
              maxTitleLines={maxSidepanelTitleLines}
              markerInfoPanelEnabled={markerInfoPanelEnabled}
              showSidePanel={showSidePanel && !geocodingItem}
              setShowSidePanel={setShowSidePanel}
              //expandSidePanel={expandSidePanel}
              //setExpandSidePanel={setExpandSidePanel}
              singlePoint={singlePoint}
              treeSlug={treeSlug}
              //markerItemType={markerItemType} // used when creating link to navigate to item when clicked
              mapinst={map.current}
              allowForSearchBoxMapControl={
                addSearchBoxMapControl ||
                ((mapIsFullScreen || mapIsFullWindow) &&
                  addSearchBoxMapControlWhenFullWindow)
              }
              //extraMarginTopPx={singlePoint || addSearchBoxMapControl ? 40 : 0}
              showEditButton={allowEditButton && !isEditing}
              setIsEditing={param => {
                if (param === true) {
                  removeShapeLayers(map.current)
                  //makeImagesEditable(map.current, currentMap)
                }
                setIsEditing(param)
              }}
              navigateToMarkerIndex={navigateToMarkerIndex}
              smallMode={!(mapIsFullScreen || mapIsFullWindow)}
            />
          </Box>
        )}
        {/* </TransitionGroup> */}
      </Box>
    </Box>
  )
}

export default Map
