import React, { useEffect, useState } from 'react'
import { ACTION_ALL_ACCESS } from '../app/appConstants'
import { Button } from '../ui'
import Clamp from 'react-multiline-clamp'
import { formatLocationCoords } from './Map'
import { generateLinkForObject } from 'src/modules/app/links'
import { Link } from 'src/modules/ui'
import { getWeareFeatureTitle } from './MapSidePanel'
import { Box, IconButton, Stack, Typography } from '@mui/material'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import ListIcon from '@mui/icons-material/List'
import PlaceIcon from '@mui/icons-material/Place'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
const debug = false

const FeatureRow = ({
  weareMapFeature,
  handleClickRow, // called with rowIndex
  isSelected,
  rowIndex,
  smallMode,
}) => {
  if (debug)
    console.debug(`FeatureRow(): called with weareMapFeature`, weareMapFeature)
  const displayTitle = getWeareFeatureTitle(weareMapFeature)

  if (debug)
    console.debug(
      `FeatureRow(): getWeareFeatureTitle() returned '${displayTitle}' for weareMapFeature`,
      weareMapFeature
    )

  const MarkerIcon = weareMapFeature.icon || PlaceIcon

  return (
    <Box
      id={`featureRow-${weareMapFeature?.id}`}
      onClick={() => handleClickRow(rowIndex)}
      sx={{
        display: 'flex',
        cursor: 'pointer',
        backgroundColor: isSelected ? 'rgba(204,204,204, 0.4)' : 'transparent',
        alignItems: 'center',
        borderRadius: 4,
        mt: 1,
        mb: 1,
      }}
    >
      <MarkerIcon fontSize={smallMode ? '16px' : 'small'} />
      <Box
        sx={{
          ml: smallMode ? 1 : 2,
          flexGrow: 1,
        }}
      >
        {displayTitle ? (
          <Typography variant={smallMode ? 'body3' : 'subtitle1'}>
            {displayTitle}
          </Typography>
        ) : (
          ''
        )}
      </Box>
    </Box>
  )
}

const FeatureList = ({ weareMapFeatures, onItemClick, smallMode }) => {
  //const debug = true
  if (debug) {
    console.debug(
      `FeatureList: called with weareMapFeatures:`,
      weareMapFeatures
    )
  }

  return (
    <Stack
      direction="column"
      sx={{
        width: '100%',
        pl: 1,
        pr: 1,
      }}
    >
      {weareMapFeatures?.map((weareMapFeature, index) => {
        return (
          <FeatureRow
            key={`feature_row_${index}_${weareMapFeature.id}`}
            weareMapFeature={weareMapFeature}
            handleClickRow={() => {
              onItemClick(index)
            }}
            rowIndex={index}
            smallMode={smallMode}
          />
        )
      })}
    </Stack>
  )
}

/**
 * Show the list of features if there is no currently selected, if one is selected
 * then show the navigator
 */
export const ReadOnlyLeftPanel = ({
  weareMapFeatures,
  navigateMarkers, // prev/next
  navigateToMarkerIndex,
  currentSelectedFeature,
  treeSlug,
  initialGoBackToList = false,
  allowThreeD,
  flyToMarkersIn2d,
  setFlyToMarkersIn2d,
  smallMode,
  allowList = true,
  allowPrevNext = true,
}) => {
  //const debug = true
  if (debug) {
    console.debug(
      `ReadOnlyLeftPanel: called with treeSlug: ${treeSlug}, weareMapFeatures:`,
      weareMapFeatures
    )
    console.debug(
      `ReadOnlyLeftPanel: called with currentSelectedFeature:`,
      currentSelectedFeature
    )
  }

  const [goBackToList, setGoBackToList] = useState(initialGoBackToList)

  // useEffect(() => {
  //   return () => {
  //     //dismounting ReadOnlyWeareFeaturesListContainer
  //     setGoBackToList(false)
  //   }
  // }, [])

  if (!weareMapFeatures) {
    return null
  }
  return (
    <>
      {currentSelectedFeature ? (
        <MarkerNavigator
          weareMapFeatures={weareMapFeatures}
          weareMapFeature={currentSelectedFeature}
          navigateMarkers={navigateMarkers}
          navigateToMarkerIndex={navigateToMarkerIndex}
          treeSlug={treeSlug}
          goBackToList={goBackToList} // show 'back' button rather than list button
          allowThreeD={allowThreeD}
          flyToMarkersIn2d={flyToMarkersIn2d}
          setFlyToMarkersIn2d={setFlyToMarkersIn2d}
          smallMode={smallMode}
          allowList={allowList}
          allowPrevNext={allowPrevNext}
        />
      ) : (
        allowList && (
          <FeatureList
            weareMapFeatures={weareMapFeatures}
            onItemClick={idx => {
              setGoBackToList(true)
              navigateToMarkerIndex(idx)
            }}
            smallMode={smallMode}
          />
        )
      )}
    </>
  )
}

const MarkerNavigator = ({
  weareMapFeature,
  weareMapFeatures,
  navigateMarkers,
  navigateToMarkerIndex,
  treeSlug,
  goBackToList,
  showLatLong = true,
  maxTitleLines = 2,
  allowThreeD,
  flyToMarkersIn2d,
  setFlyToMarkersIn2d,
  smallMode,
  allowList = true,
  allowPrevNext = true,
}) => {
  if (debug)
    console.debug(
      `MarkerNavigator: called with treeSlug: ${treeSlug}, showLatLong: ${showLatLong}, smallMode: ${smallMode}, weareMapFeature:`,
      weareMapFeature
    )
  const [itemUrl, setItemUrl] = useState()

  useEffect(() => {
    if (
      weareMapFeature &&
      weareMapFeature.instanceType &&
      ['location', 'event', 'artefact', 'individual'].includes(
        weareMapFeature.instanceType
      ) &&
      weareMapFeature.target &&
      weareMapFeature.target.id
    ) {
      try {
        const newItemUrl = generateLinkForObject(
          treeSlug,
          weareMapFeature.instanceType,
          weareMapFeature.target.id
        )
        if (debug)
          console.debug(
            `MarkerNavigator: generated link to feature: '${newItemUrl}', weareMapFeature:`,
            weareMapFeature
          )
        setItemUrl(newItemUrl)
      } catch (err) {
        console.error(
          `MarkerNavigator: error from generateLinkForObject(): '${err}' for weareMapFeature:`,
          weareMapFeature
        )
        setItemUrl(null)
      }
    } else {
      if (debug)
        console.debug(
          `MarkerNavigator: cannot generate link to feature as it has no id. weareMapFeature:`,
          weareMapFeature
        )
      setItemUrl(null)
    }
  }, [weareMapFeature, treeSlug])

  const displayTitle = getWeareFeatureTitle(weareMapFeature)
  const imageUrl =
    weareMapFeature?.photo?.fileMedium ??
    weareMapFeature?.target?.photo?.fileMedium ??
    weareMapFeature?.target?.photo?.fileThumbnail
  const imageAlt =
    weareMapFeature?.photo?.title ?? weareMapFeature?.target?.photo?.title

  const displayLatLong = formatLocationCoords(weareMapFeature)

  const moreThanOneFeature = weareMapFeatures && weareMapFeatures.length > 1
  return (
    <>
      {weareMapFeature ? (
        <Box
          id="marker-navigator-outer"
          sx={{
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {(allowList || allowPrevNext) && (
            <Box
              id="marker-navigator-inner"
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                width: '100%',
                justifyContent: 'start',
                p: 1,
              }}
            >
              <IconButton
                color="primary"
                onClick={() => navigateToMarkerIndex(-1)}
                sx={{
                  mr: 'auto',
                  p: 0,
                }}
              >
                {goBackToList ? <ArrowBackIcon /> : <ListIcon />}
              </IconButton>

              {allowThreeD && (
                <FormControlLabel
                  sx={{
                    mr: 'auto',
                    ml: 'auto',
                  }}
                  control={
                    <Switch
                      checked={!flyToMarkersIn2d}
                      onChange={() => setFlyToMarkersIn2d(!flyToMarkersIn2d)}
                    />
                  }
                  label="3D flyby"
                />
              )}

              <Button
                size={smallMode ? 'small' : 'medium'}
                permissionAction={ACTION_ALL_ACCESS}
                onClick={() => navigateMarkers('prev')}
                sx={{ visibility: moreThanOneFeature ? 'visible' : 'hidden' }}
              >
                Prev
              </Button>
              <Button
                size={smallMode ? 'small' : 'medium'}
                permissionAction={ACTION_ALL_ACCESS}
                onClick={() => navigateMarkers('next')}
                sx={{ visibility: moreThanOneFeature ? 'visible' : 'hidden' }}
              >
                Next
              </Button>
            </Box>
          )}
          {/* </Box> */}
          <Link
            to={itemUrl}
            key={weareMapFeature.id ?? weareMapFeature.target?.id}
          >
            <Box p={1}>
              {displayTitle && (
                <Typography variant={smallMode ? 'subtitle5' : 'h4'}>
                  <Clamp lines={maxTitleLines}>{displayTitle}</Clamp>
                </Typography>
              )}
              {weareMapFeature?.address && (
                <Typography
                  variant={smallMode ? 'subtitle6' : 'subtitle1'}
                  color="primary"
                  //lineHeight={1.4}
                >
                  {weareMapFeature?.address}
                </Typography>
              )}
              {showLatLong && displayLatLong && (
                <Typography
                  variant={smallMode ? 'body2' : 'subtitle2'}
                  mt={0.5}
                >
                  {displayLatLong}
                </Typography>
              )}
              <Typography mt={1}>{weareMapFeature?.desc}</Typography>
            </Box>

            {imageUrl && (
              <Box
                style={{
                  flexGrow: 1, // will make this div grow to fill remaining height in the column
                }}
              >
                <img
                  src={imageUrl}
                  //width="100%"
                  width="auto"
                  //height="auto"
                  style={{
                    objectFit: 'contain',
                    //maxWidth: '100px',
                    width: '100%',
                    //maxWidth: '100%',
                    maxHeight: '100%',
                    //maxHeight: '600px',
                    margin: 'auto',
                    display: 'block',
                  }}
                  alt={imageAlt || 'Marker Image'}
                />
              </Box>
            )}
          </Link>
        </Box>
      ) : (
        <>
          {/* {flybyButtonText && (
            <Box
              sx={{
                display: 'flex',
                width: 'fit-content',
                backgroundColor: 'white',
                padding: '4px',
              }}
            >
              <Button
                permissionAction={ACTION_ALL_ACCESS}
                onClick={() => {
                  if (onClickStartFlyby) {
                    onClickStartFlyby()
                  }
                  navigateMarkers('next')
                }}
                //sx={{ margin: 0 }}
              >
                {flybyButtonText}
              </Button>
            </Box>
          )} */}
        </>
      )}
    </>
  )
}

export default MarkerNavigator
