import { Box, Input } from '@mui/material'
import React, { useState, useCallback, useContext } from 'react'
import { ReadOnlyLeftPanel } from './MarkerNavigator'
import MarkerEditor from './MarkerEditor'
import { Button, ConfirmDialog } from '../ui'
import { ACTION_ALL_ACCESS } from '../app/appConstants'
import { createMap, updateMap } from './mapSlice'
import { useActionDispatcher, useNotification } from '../app'
import { PublicContext } from 'src/modules/public/contexts'
import ListIcon from '@mui/icons-material/List'
import { makeStyles } from '@mui/styles'
import Slide from '@mui/material/Slide'

import Grow from '@mui/material/Grow'
import { updateMapLayerImageCoordinates } from './MapImageHelpers'

export const getWeareFeatureTitle = weareMapFeature => {
  if (!weareMapFeature) {
    return
  }
  return (
    weareMapFeature?.title ||
    //marker?.linkTarget?.display || //TODO linkTarget is removed?
    weareMapFeature?.address ||
    (weareMapFeature?.instanceType === 'address' &&
      weareMapFeature.target.freeText) ||
    (['location', 'event', 'artefact', 'individual'].includes(
      weareMapFeature?.instanceType
    ) && // a Place / Occasion
      (weareMapFeature.target.title ??
        weareMapFeature.target.address?.freeText)) ||
    weareMapFeature.newFeatureTemporaryTitle
  )
}

const EXPANDED_SIDE_PANEL_WIDTH_SMALL_MODE = 250
const EXPANDED_SIDE_PANEL_WIDTH = 400

const MapSidePanel = ({
  id,
  currentMap,
  isEditing = false,
  navigateMarkers,
  setLocations,
  locations,
  currentMarker,
  onSave,
  closeMap,
  resetMap,
  setCurrentMarkerIndex,
  flyToEditMode,
  allowThreeD,
  flyToMarkersIn2d,
  setFlyToMarkersIn2d,
  preview,
  setPreview,
  dropPinMode,
  setDropPinMode,
  //maxHeight = 'calc(100vh - 48px)', // default to browser window height - 48px
  showLatLong,
  mapIsFullScreen,
  mapIsFullWindow,
  maxTitleLines,
  markerInfoPanelEnabled,
  showSidePanel,
  setShowSidePanel,
  singlePoint,
  treeSlug,
  //markerItemType, // used when creating link to navigate to item when clicked
  //draw,
  mapinst,
  //extraMarginTopPx = 0, // leave room for search box
  hideUi,
  allowForSearchBoxMapControl,
  showEditButton,
  setIsEditing,
  navigateToMarkerIndex,
  smallMode, // if true restrict width a bit and use a smaller font
}) => {
  const publicContext = useContext(PublicContext)
  const debug = false
  console.debug(
    `MapSidePanel: rendering with showSidePanel: ${showSidePanel}, treeSlug: ${treeSlug}, isEditing: ${isEditing}, currentMarker:`,
    currentMarker
  )
  const [editableMapTitle, setMapTitle] = useState(currentMap?.title || '')
  const [hidePanel, setHidePanel] = useState(false)
  const dispatchCreateMap = useActionDispatcher(createMap)
  const dispatchUpdateMap = useActionDispatcher(updateMap)
  const { showError } = useNotification()

  const useStyles = makeStyles(theme => ({
    mapTitle: {
      padding: 0,
      border: 'none',
      outline: 'none',

      '& .MuiInput-input': {
        color: theme.palette.primary.main,
        ...theme.typography.h4,
        outline: 'none',
        border: 'none',
      },
    },
  }))

  const preventTitleEnterPress = useCallback(e => {
    if (e.code === 'Enter' || e.code === 'NumpadEnter') {
      e.preventDefault()
    }
    return e
  }, [])

  const togglePreview = () => {
    setHidePanel(true)
    if (preview) {
      resetMap()
    } else {
      if (!locations[locations.length - 1]?.id) {
        setLocations(prevState => {
          let newLocations = [...prevState]
          newLocations.pop()
          return newLocations
        })
      }
      navigateMarkers('next')
    }
    setTimeout(() => {
      if (preview) {
        setCurrentMarkerIndex(-1)
      }
      setPreview(!preview)
      setHidePanel(false)
    }, 800)
  }

  const weAreMapFeatureToSaveableJson = (weareMapFeature, index) => {
    const debug = true
    if (!weareMapFeature) {
      return {}
    }
    if (debug)
      console.debug(
        `MapSidePanel.weAreMapFeatureToSaveableJson(): converting weareMapFeature to saveable json:`,
        weareMapFeature
      )
    const res = {
      ...(weareMapFeature.id &&
        !weareMapFeature.id.includes('SORTABLE-ID') && {
          id: weareMapFeature.id,
        }),
      mapLinkOrder: index,
      title: weareMapFeature.title,
      description: weareMapFeature.description,
      instanceType:
        weareMapFeature.instanceType ??
        // (weareMapFeature.feature ? 'feature' : 'address'),
        'address',
      target:
        weareMapFeature.instanceType === 'address'
          ? { ...weareMapFeature.target, setPhoto: weareMapFeature.photo?.id }
          : {
              id: weareMapFeature.target.id,
            },
    }
    if (debug)
      console.debug(
        `MapSidePanel.weAreMapFeatureToSaveableJson(): converted weareMapFeature to saveable json:`,
        res
      )
    return res
  }

  /**
   * Only the image info is stored, the API stores it as a MapLink which
   * has the image as a target so wrap it.
   *
   * Don't need to send all the image properties to the API, just the id
   * and coords will do.
   */
  const weAreMapLayerImagesToSaveableJson = mapLayerImages => {
    if (!mapLayerImages) {
      return
    }
    return Object.values(mapLayerImages).map(mapLayerImage => {
      return {
        target: {
          id: mapLayerImage.id,
          coordinates: mapLayerImage.coordinates,
        },
      }
    })
  }
  const createNewMap = async () => {
    const debug = true
    try {
      if (!editableMapTitle) {
        showError('You must add a title to your map before saving')
        return
      }
      if (debug) {
        console.debug(
          `MapSidePanel.createNewMap(): creating new map from currentMap:`,
          currentMap
        )
        console.debug(
          `MapSidePanel.createNewMap(): creating new map from locations:`,
          locations
        )
      }

      currentMap.title = editableMapTitle

      let updatedMapLayerImages = currentMap.mapLayerImages
      if (mapinst && currentMap.mapLayerImages) {
        //update the geo-positions of the images from the Mapbox Map's source that is moved around by the user
        updatedMapLayerImages = updateMapLayerImageCoordinates(
          mapinst,
          currentMap.mapLayerImages
        )

        console.debug(
          `MapSidePanel.createNewMap(): updateMapLayerImageCoordinates() returned updatedMapLayerImages:`,
          updatedMapLayerImages
        )
      }

      currentMap.mapLayerImages = updatedMapLayerImages

      const saveableMapData = {
        title: editableMapTitle,
        // mapLinks: locations.map((weareMapFeature, index) =>
        //   weAreMapFeatureToSaveableJson(weareMapFeature, index)
        // ),
        // mapLayerImageLinks: weAreMapLayerImagesToSaveableJson(
        //   currentMap.mapLayerImages
        // ),
      }
      if (locations) {
        if (debug)
          console.debug(
            `MapSidePanel.createNewMap(): calling weAreMapFeatureToSaveableJson() with locations:`,
            locations
          )
        const saveableMapLinks = locations.map((weareMapFeature, index) =>
          weAreMapFeatureToSaveableJson(weareMapFeature, index)
        )
        if (saveableMapLinks) {
          saveableMapData['mapLinks'] = saveableMapLinks
        }
      }
      if (updatedMapLayerImages) {
        if (debug)
          console.debug(
            `MapSidePanel.createNewMap(): calling weAreMapLayerImagesToSaveableJson() with currentMap.mapLayerImages:`,
            currentMap.mapLayerImages
          )
        const saveableMapImages = weAreMapLayerImagesToSaveableJson(
          updatedMapLayerImages
        )
        if (saveableMapImages) {
          saveableMapData['mapLayerImageLinks'] = saveableMapImages
        }
      } else {
        if (debug)
          console.debug(
            `MapSidePanel.createNewMap(): currentMap.mapLayerImages not set:`,
            currentMap
          )
      }
      if (debug)
        console.debug(
          `MapSidePanel.createNewMap(): calling dispatchCreateMap() with saveableMapData:`,
          saveableMapData
        )
      const fulfilmentResponse = await dispatchCreateMap(saveableMapData)
      if (debug)
        console.debug(
          `MapSidePanel.createNewMap(): dispatchCreateMap() returned with fulfilmentResponse, if onSave is set calling it... fulfilmentResponse:`,
          fulfilmentResponse
        )

      currentMap.id = fulfilmentResponse.payload.id

      if (onSave) {
        //onSave(newMap?.payload, closeMap)
        onSave(currentMap)
        if (debug)
          console.debug(`MapSidePanel.createNewMap(): onSave() returned`)
      }

      //closeMap()
    } catch (error) {
      console.log(error)
    }
  } // end createNewMap

  const saveMap = async () => {
    try {
      if (debug)
        console.debug(
          `MapSidePanel.saveMap(): called with currentMap:`,
          currentMap
        )

      if (!editableMapTitle) {
        showError('You must add a title to your map before saving')
        return
      }

      currentMap.title = editableMapTitle

      let updatedMapLayerImages = currentMap.mapLayerImages
      if (mapinst && currentMap.mapLayerImages) {
        //update the geo-positions of the images from the Mapbox Map's source that is moved around by the user
        updatedMapLayerImages = updateMapLayerImageCoordinates(
          mapinst,
          currentMap.mapLayerImages
        )

        console.debug(
          `MapSidePanel.saveMap(): updateMapLayerImageCoordinates() returned updatedMapLayerImages:`,
          updatedMapLayerImages
        )
      }

      currentMap.mapLayerImages = updatedMapLayerImages

      // console.debug(
      //   `MapSidePanel.saveMap(): transforming locations to API payload - locations:`,
      //   locations
      // )

      const saveableMapData = {
        id: currentMap?.id,
        title: editableMapTitle,
        mapLinks: locations.map((locationItem, index) =>
          weAreMapFeatureToSaveableJson(locationItem, index)
        ),
        mapLayerImageLinks: weAreMapLayerImagesToSaveableJson(
          updatedMapLayerImages
        ),
      }
      if (debug)
        console.debug(
          `MapSidePanel.saveMap(): calling dispatchUpdateMap() with saveableMapData:`,
          saveableMapData
        )
      const fulfilmentResponse = await dispatchUpdateMap({
        id: currentMap?.id,
        map: saveableMapData,
      })

      if (debug)
        console.debug(
          `MapSidePanel.saveMap(): dispatchUpdateMap() returned fulfilmentResponse, if onSave is set calling it`,
          fulfilmentResponse
        )
      if (onSave) {
        //onSave(updatedMap?.payload, closeMap)
        onSave(currentMap)
        if (debug) console.debug(`MapSidePanel.saveMap(): onSave() returned`)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const editMode = !preview && isEditing

  const classes = useStyles()

  if (debug) console.debug(`MapSidePanel: returning JSX...`)
  return (
    <>
      <Box
        id="sidepanelTopBar"
        style={{
          width: '100%',
          pointerEvents: 'none',
          '& > *': {
            //this child selector isn't working?
            pointerEvents: 'auto',
          },
          minHeight: allowForSearchBoxMapControl ? '48px' : '0',
        }}
      >
        {isEditing && !singlePoint && (
          <Slide direction="down" in={!hideUi}>
            <Box
              style={{
                backgroundColor: 'white',
                borderRadius: '4px',
                padding: 8,
                margin: 'auto',
                marginTop: 8,
                marginBottom: 2,
                width: '400px',
                pointerEvents: 'auto', // here because the child selector above isn't working?
              }}
            >
              <Input
                multiline
                value={editableMapTitle}
                onChange={e => setMapTitle(e.target.value)}
                placeholder="Add a map title..."
                className={classes.mapTitle}
                onKeyPress={preventTitleEnterPress}
              />
            </Box>
          </Slide>
        )}
      </Box>
      {markerInfoPanelEnabled &&
      locations &&
      locations.length > 0 &&
      !singlePoint ? (
        <>
          <Slide direction="right" in={!hideUi} mountOnEnter unmountOnExit>
            <Box
              sx={{
                background: 'white',
                m: 1,
                display: 'inline-flex',
                ml: '10px',
                borderRadius: '4px',
                p: 1,
                position: 'absolute', // so this button can be behind the panel
              }}
              onClick={() => {
                setShowSidePanel(true)
              }}
            >
              <ListIcon size="large" />
            </Box>
          </Slide>

          <Grow
            in={!hideUi && showSidePanel}
            mountOnEnter
            unmountOnExit
            style={{ transformOrigin: '0 0 0' }}
          >
            <Box
              id="sidePanel"
              sx={{
                backgroundColor: 'white',
                //position: 'absolute',
                //left: hidePanel ? -400 : 10,
                //transitionDuration: '0.6s',
                //transition: 'fade',
                //transitionDuration: '5s',
                //top: 11,
                visibility: hidePanel ? 'hidden' : 'visible',
                //marginTop: `${10 + extraMarginTopPx}px`,
                borderRadius: '4px',
                //width: fixedWidth ?? (editMode ? 400 : 'fit-content'),
                width: smallMode
                  ? EXPANDED_SIDE_PANEL_WIDTH_SMALL_MODE
                  : EXPANDED_SIDE_PANEL_WIDTH,
                //overflowY: 'auto',
                maxHeight: '100%', // don't grow to taller than the window
                overflowX: 'hidden',
                // '&::-webkit-scrollbar': {
                //   display: 'none',
                // },
                //scrollbarWidth: 'none',
                //maxHeight: maxHeight,
                marginTop: 1,
                marginBottom: 1,
                marginLeft: '10px', //needs to match Mapbox's search widget positioning which we're not in control of and doesn't use MUI theme

                //paddingBottom: 1,
                // disabled padding on sidePanel as we want full width image in flyby mode   paddingLeft: 1,
                //paddingRight: 1,
                position: 'absolute', //cover up list button
                zIndex: 1, // cover up list button
              }}
            >
              <>
                {editMode ? (
                  <>
                    {' '}
                    {!singlePoint && (
                      <MarkerEditor
                        id={id}
                        currentMap={currentMap}
                        locations={locations}
                        setLocations={setLocations}
                        editableMapTitle={editableMapTitle}
                        setMapTitle={setMapTitle}
                        currentMarker={currentMarker}
                        setCurrentMarkerIndex={setCurrentMarkerIndex}
                        flyToEditMode={flyToEditMode}
                        dropPinMode={dropPinMode}
                        setDropPinMode={setDropPinMode}
                      />
                    )}
                  </>
                ) : (
                  <ReadOnlyLeftPanel
                    weareMapFeatures={locations}
                    currentSelectedFeature={currentMarker}
                    navigateMarkers={navigateMarkers}
                    navigateToMarkerIndex={navigateToMarkerIndex}
                    showLatLong={showLatLong}
                    maxTitleLines={maxTitleLines}
                    treeSlug={treeSlug}
                    allowThreeD={allowThreeD}
                    flyToMarkersIn2d={flyToMarkersIn2d}
                    setFlyToMarkersIn2d={setFlyToMarkersIn2d}
                    smallMode={smallMode} // use smaller fonts
                  />
                )}
              </>
            </Box>
          </Grow>
        </>
      ) : (
        <></>
      )}

      <Slide direction="left" in={!hideUi}>
        <Box
          id="closeButtons"
          sx={{
            position: 'fixed',
            right: 11,
            bottom: 32,
            backgroundColor: 'white',
            padding: 1,
            borderRadius: '4px',
            display: 'flex',
            gap: 1,
            flexDirection: 'row-reverse',
          }}
        >
          {isEditing ? (
            <>
              {singlePoint ? (
                <>
                  {currentMarker && (
                    <Button
                      permissionAction={ACTION_ALL_ACCESS}
                      onClick={e => {
                        closeMap(currentMarker) // in singlepoint mode there is only the currentMarker
                      }}
                    >
                      Select and close
                    </Button>
                  )}
                  <Button
                    permissionAction={ACTION_ALL_ACCESS}
                    onClick={closeMap}
                    variant="outlined"
                  >
                    Cancel
                  </Button>
                </>
              ) : (
                <>
                  <ConfirmDialog
                    onConfirm={closeMap}
                    submitText="Exit"
                    cancelText="Continue editing"
                    submitColor="error"
                    trigger={props => (
                      <Button
                        color="error"
                        permissionAction={ACTION_ALL_ACCESS}
                        {...props}
                      >
                        Exit
                      </Button>
                    )}
                  >
                    All changes will be lost, are you sure you want to exit?
                  </ConfirmDialog>

                  <Button
                    permissionAction={ACTION_ALL_ACCESS}
                    onClick={togglePreview}
                    variant="outlined"
                  >
                    {!preview ? 'Preview' : 'Continue editing'}
                  </Button>
                  <Button
                    permissionAction={ACTION_ALL_ACCESS}
                    onClick={currentMap?.id ? saveMap : createNewMap}
                    disabled={
                      dispatchCreateMap.status === 'loading' ||
                      dispatchUpdateMap.status === 'loading'
                    }
                  >
                    {currentMap?.id ? 'Save' : 'Create'}
                  </Button>
                </>
              )}
            </>
          ) : (
            <>
              {(mapIsFullWindow || mapIsFullScreen) && !preview && (
                <Button permissionAction={ACTION_ALL_ACCESS} onClick={closeMap}>
                  Close
                </Button>
              )}
              {showEditButton && !publicContext && (
                <Button
                  variant="outlined"
                  permissionAction={ACTION_ALL_ACCESS}
                  onClick={() => {
                    setIsEditing(true)
                  }}
                >
                  Edit
                </Button>
              )}
            </>
          )}
        </Box>
      </Slide>
    </>
  )
}

export default MapSidePanel
