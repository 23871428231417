import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Stack, Typography, styled } from '@mui/material'

import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import CloseIcon from '@mui/icons-material/Close'
import CropRotateIcon from '@mui/icons-material/CropRotate'
import EditIcon from '@mui/icons-material/Edit'

import { withOverridableComponent } from 'src/overridable'
import { useActionDispatcher } from 'src/modules/app'
import { selectUser } from 'src/modules/auth/authSlice'
import { Comments } from 'src/modules/content/Comments'
import LinkChips from 'src/modules/content/LinkChips'
import ProfilePicture from 'src/modules/content/ProfilePicture'
import { setMediaOpen } from 'src/modules/informationRequest/informationRequestSlice'
import { Button, IconButton, LoadingIndicator } from 'src/modules/ui'
import InstanceVisibilityControl from 'src/modules/visibility/InstanceVisibilityControl'
import { formatDate } from 'src/utils'

import {
  createPhotoComment,
  deletePhotoComment,
  fetchMediaDetail,
  rotatePhotoLeft,
  rotatePhotoRight,
  selectMediaDetail,
} from './photoSlice'
import EditMedia from './EditMedia'
import FullMedia from './FullMedia'
import {
  MEDIA_TYPE_PDF,
  MEDIA_TYPE_PHOTO,
  MEDIA_TYPE_VIDEO,
  MEDIA_TYPE_VIDEO_YOUTUBE,
} from './index'
import { VideoPlayer } from './VideoPlayer'
import { CropExistingMediaDialog } from './ImageCropper'
import { convertUIGedDate } from '../common/gedcomDateParser'
import { formatIndividualName } from '../ui/individualUtils'
import { PDFViewer } from './PDFViewer'
import { handleImgName } from './MediaDropzone'

import TextBlock from '../content/TextBlock'

import { htmlToEditorState } from 'src/modules/ui/editor/convert'
import { Formik } from 'formik'
import { updatePhoto } from './photoSlice'

import { SavingFormikRichTextField } from '../ui/SavingFormikRichTextField'

import { CONTENT_BLOCK_LAYOUT_TEXT_BLOCK } from '../writeArticle/WriteArticle'
import { selectIsBlogTree } from '../auth/authSlice'
import { ACTION_ALL_ACCESS, ACTION_EDIT } from '../app/appConstants'
import { INSTANCE_TYPE_MEDIA } from '../app/links'
import { usePermissions } from '../auth/authHooks'
//import AnnotatedImage from './AnnotatedImageMapLibre'
//import AnnotatedImage from './AnnotatedImageMapLibreGlobe'
//import AnnotatedImage from './AnnotatedImageFabric'
import AnnotatedImage from './AnnotatedImageFabricTS'

export const Root = styled('div')(({ theme }) => ({
  display: 'flex',
  height: '100%',
  width: '100%',
  [theme.breakpoints.down('md')]: { flexDirection: 'column' },
  [theme.breakpoints.up('md')]: { flexDirection: 'row' },
}))

export const PhotoContainer = styled('div')(({ theme }) => ({
  background: '#1A1818',
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'row',
  position: 'relative',
  height: '100%',
  overflowY: 'scroll',
}))

export const SidePanel = styled('div')(({ theme, sidePanelWidth }) => {
  return {
    flexShrink: 0,
    [theme.breakpoints.down('md')]: { width: '100%' },
    [theme.breakpoints.up('md')]: {
      width: sidePanelWidth,
      maxWidth: sidePanelWidth,
    },
    overflow: 'hidden',
    overflowY: 'scroll',
  }
})

export const SidePanelMeta = styled('div')(({ theme }) => ({
  borderBottom: `1px solid ${theme.palette.lightGrey.main}`,
  padding: theme.spacing(3),
}))

export const TranscriptionMeta = styled('div')(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.lightGrey.main}`,
  paddingTop: theme.spacing(1),
}))

export const CloseButton = styled(IconButton)(({ theme }) => ({
  [theme.breakpoints.down('md')]: { position: 'fixed' },
  [theme.breakpoints.up('md')]: { position: 'absolute' },
  top: theme.spacing(2),
  left: theme.spacing(2),
  zIndex: 1,
}))

const TopRightButtons = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: theme.spacing(2),
  right: theme.spacing(2),
  zIndex: 1,
  [theme.breakpoints.down('md')]: { display: 'none' },
  display: 'flex',
  flexDirection: 'column',
}))

// const CropButton = styled(IconButton)(({ theme }) => ({
//   position: 'absolute',
//   top: theme.spacing(2),
//   right: theme.spacing(2),
//   zIndex: 1,
//   [theme.breakpoints.down('md')]: { display: 'none' },
// }))

// const AnnotateImageButton = styled(IconButton)(({ theme }) => ({
//   position: 'absolute',
//   top: theme.spacing(2),
//   right: theme.spacing(2),
//   zIndex: 1,
//   [theme.breakpoints.down('md')]: { display: 'none' },
// }))

export const PrevButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  left: theme.spacing(2),
  top: '50%',
  zIndex: 1,
}))

export const NextButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  right: theme.spacing(2),
  top: '50%',
  zIndex: 1,
}))

export const AuthorName = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontWeight: 400,
}))

export const imgTypes = [
  '.anpg',
  '.avif',
  '.gif',
  '.jpg',
  '.jpeg',
  '.jfif',
  '.pjpeg',
  '.pjp',
  '.png',
  '.svg',
  '.webp',
  '.pdf',
]

const stringContainsText = s => {
  return !!s && s.trim() !== '' && s.trim() !== '<p></p>'
}

const hasTranscriptionContent = (md, ti) => {
  return md && md.transcription && stringContainsText(md.transcription[ti])
}

export const calcSidePanelWidth = ({
  showTranscription,
  transcriptionContentPresent,
  clickedEditTranscription,
  expandTranscription,
}) => {
  /**
   * showTranscription must be true, else this is not a document page and so does not get transcribed
   * if clickedEditTranscription is true then be wide
   * if expandTranscription is true then be wide
   * if expandTranscription is null then default to wide panel if there is transcription text
   *
   */
  const widerSidePanel =
    showTranscription &&
    (clickedEditTranscription === true ||
      expandTranscription === true ||
      (expandTranscription === null && transcriptionContentPresent))

  // console.debug(
  //   `calcSidePanelWidth(): showTranscription: ${showTranscription}, clickedEditTranscription: ${clickedEditTranscription}, expandTranscription: ${expandTranscription}, transcriptionContentPresent: '${transcriptionContentPresent}'; calculated widerSidePanel: ${widerSidePanel}`
  // )

  const res = widerSidePanel ? '50%' : 350

  return [res, widerSidePanel]
}

const MediaDetail = ({
  photoListItem,
  treeSlug,
  reportDeleted,
  canRequestPrev,
  canRequestNext,
  onClose,
  onClickPrev,
  onClickNext,
  refreshMedia,
  isArticle,
  editDialogTitle,
  showTranscription = true,
  offerEnterTranscription,
  showTakenOnDate,
  hideTags = false,
  defaultAllowNoTags = false,
  //annotationEditor,
  canEditAnnotations = true,
}) => {
  const checkPermissions = usePermissions()
  const dispatch = useDispatch()
  const dispatchFetchMediaDetail = useActionDispatcher(fetchMediaDetail)
  const dispatchRotatePhotoLeft = useActionDispatcher(rotatePhotoLeft)
  const dispatchRotatePhotoRight = useActionDispatcher(rotatePhotoRight)
  const [refreshRequired, setRefreshRequired] = useState(false)
  const isBlogTree = useSelector(selectIsBlogTree)
  const [editingImageAnnotations, setEditingImageAnnotations] = useState(false)

  useEffect(() => {
    // don't recalculate the sidebar width at this point because we need to fetch the media item details first

    if (photoListItem.isCropped) {
      dispatchFetchMediaDetail({ mediaId: photoListItem.original })
    } else {
      dispatchFetchMediaDetail({ mediaId: photoListItem.id })
    }

    if (refreshRequired) {
      setRefreshRequired(false)
    }
    dispatch(setMediaOpen(photoListItem))
    return () => dispatch(setMediaOpen(null))
  }, [dispatch, dispatchFetchMediaDetail, photoListItem, refreshRequired])

  const mediaDetail = useSelector(selectMediaDetail)
  const detailReady = dispatchFetchMediaDetail.status === 'fulfilled'
  const detailLoading = dispatchFetchMediaDetail.status === 'loading'

  const [clickedEditTranscription, setClickedEditTranscription] =
    useState(false)

  const [expandTranscription, setExpandTranscription] = useState(null) // if null calcSidePanelWidth will show wide if there's a transcription entered and narrow if not.

  //for photos transcriptionIndex is always 0 but PDFs can contain multiple pages and we want
  //a transcription per page. The database column Media.transcription is a Postgres Array
  //so can store multiple transcriptions.
  //This is set by onPdfPageNumberChanged() which is passed into PDFViewer
  const [transcriptionIndex, setTranscriptionIndex] = useState(0)

  const [sidePanelIsWide, setSidePanelIsWide] = useState(null)
  const [sidePanelWidth, setSidePanelWidth] = useState()

  const recalculateSidePanelWidth = useCallback(() => {
    const transcriptionContentPresent = hasTranscriptionContent(
      mediaDetail,
      transcriptionIndex
    )

    const [newSidePanelWidth, widerSidePanel] = calcSidePanelWidth({
      showTranscription,
      transcriptionContentPresent,
      clickedEditTranscription,
      expandTranscription,
    })

    setSidePanelIsWide(widerSidePanel)
    setSidePanelWidth(newSidePanelWidth)
  }, [
    mediaDetail,
    showTranscription,
    transcriptionIndex,
    clickedEditTranscription,
    expandTranscription,
  ])

  const pageChanged = () => {
    if (expandTranscription !== null) {
      setExpandTranscription(null)
    }
    if (clickedEditTranscription) {
      setClickedEditTranscription(false) // this will trigger useEffect3 below, which will call recalculateSidePanelWidth()
    } else {
      recalculateSidePanelWidth()
    }
  }

  // called when mediaDetail.id changes - a new media item has been navigated to
  useEffect(() => {
    if (transcriptionIndex !== 0) {
      setTranscriptionIndex(0)
    }

    pageChanged()
  }, [mediaDetail.id, setTranscriptionIndex]) //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    recalculateSidePanelWidth()
  }, [clickedEditTranscription]) //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    recalculateSidePanelWidth()
  }, [expandTranscription]) //eslint-disable-line react-hooks/exhaustive-deps

  //redrawPdf only exists as a trigger to get the PDF to re-render after this component has changed the width
  //of the sidebar.
  //Passing sidePanelWidth as a prop to PDFViewer doesn't work because <SidePanel> needs to fully render
  //before PDFViewer re-renders so that PDFViewer picks up the new width from the CSS layout
  const [redrawPdf, setRedrawPdf] = useState(0)

  useEffect(() => {
    setRedrawPdf(sidePanelWidth)
  }, [sidePanelWidth])

  const isRotating =
    dispatchRotatePhotoLeft.status === 'loading' ||
    dispatchRotatePhotoRight.status === 'loading'

  const handleRefresh = () => {
    setRefreshRequired(true)
    if (refreshMedia) {
      refreshMedia()
    }
  }

  const handleReportDeleted = instances => {
    if (reportDeleted) {
      reportDeleted(instances)
    }
  }

  const [photoToCrop, setPhotoToCrop] = useState(null)
  useEffect(() => {
    // Detect changes to mediaDetail and pass through to photo to crop
    if (photoToCrop && photoToCrop.fileMedium !== mediaDetail.fileMedium) {
      setPhotoToCrop(mediaDetail)
    }
  }, [photoToCrop, setPhotoToCrop, mediaDetail])

  const closeCropDialog = useCallback(() => {
    setPhotoToCrop(null)
  }, [setPhotoToCrop])

  const openCropDialog = useCallback(() => {
    setPhotoToCrop(photoListItem)
  }, [setPhotoToCrop, photoListItem])

  // const openAnnotateImageDialog = useCallback(() => {
  //   setPhotoToCrop(photoListItem)
  // }, [setPhotoToCrop, photoListItem])

  const dispatchUpdatePhoto = useActionDispatcher(updatePhoto)

  //called by SavingFormikRichTextField on user typing
  const saveTranscription = transcriptionHtml => {
    try {
      // copy the array because mediaDetail is read-only
      const transcriptionArray = mediaDetail.transcription
        ? [...mediaDetail.transcription]
        : []
      transcriptionArray[transcriptionIndex] = transcriptionHtml

      dispatchUpdatePhoto({
        mediaId: mediaDetail.id,
        transcription: transcriptionArray,
      }).unwrap()
    } catch (err) {
      console.log(err)
      return
    }
  }

  // called by AnnotatedImage when Fabric's canvas dismounts
  // if this is not wrapped in a useCallback then the useEffect dismount
  // and so this fn will be called when entering edit mode
  const onAnnotationSvgUpdated = useCallback(
    newSvgString => {
      console.debug(
        `MediaDetail.onAnnotationSvgUpdated(): called with newSvgString:`,
        newSvgString
      )

      if (newSvgString !== mediaDetail.annotationsSvg) {
        console.debug(
          `MediaDetail.onAnnotationSvgUpdated(): param newSvgString is different from mediaDetail.annotationSvg, going to save to the API... annotationSvg:`,
          newSvgString
        )

        if (!newSvgString) {
          console.trace(
            `MediaDetail.onAnnotationSvgUpdated(): called with param newSvgString not set: '${newSvgString}'`
          )
        }

        if (newSvgString || newSvgString === null) {
          try {
            dispatchUpdatePhoto({
              mediaId: mediaDetail.id,
              annotationsSvg: newSvgString,
            }).unwrap()
          } catch (err) {
            console.error(err)
            return
          }
          //mediaDetail is immutable
          //mediaDetail.annotationsSvg = newSvgString
        } else {
          console.error(
            `MediaDetail.onAnnotationSvgUpdated(): called with newSvgString undefined, not saving: '${newSvgString}'`
          )
        }
      } else {
        console.debug(
          `MediaDetail.onAnnotationSvgUpdated(): newSvgString is the same as mediaDetail.annotationSvg, not saving:`,
          newSvgString
        )
      }
    },
    [dispatchUpdatePhoto, mediaDetail.annotationsSvg, mediaDetail.id]
  )

  useEffect(() => {
    pageChanged()
  }, [transcriptionIndex]) //eslint-disable-line react-hooks/exhaustive-deps

  //passed to PDFViewer and called on prev/next page button click
  const onPdfPageNumberChanged = newPageNumber => {
    setTranscriptionIndex(newPageNumber - 1)
  }

  if (detailLoading || !detailReady) {
    return <LoadingIndicator />
  }

  const permissionAction = ACTION_EDIT
  const permissionParams = {
    instance: photoListItem,
    instanceType: INSTANCE_TYPE_MEDIA,
  }

  const canEdit = checkPermissions(permissionAction, permissionParams)

  return (
    <Root>
      <PhotoContainer>
        {isRotating && (
          <Box
            sx={{
              position: 'absolute',
              height: '100%',
              width: '100%',
              background: 'white',
              opacity: 0.5,
              zIndex: 100,
            }}
          >
            <LoadingIndicator />
          </Box>
        )}
        <CloseButton permissionAction={ACTION_ALL_ACCESS} onClick={onClose}>
          <CloseIcon />
        </CloseButton>
        {!canEdit ||
        isArticle ||
        photoListItem?.type !== MEDIA_TYPE_PHOTO ? null : (
          <>
            {/* <CropButton
              permissionAction={permissionAction}
              permissionParams={permissionParams}
              onClick={openCropDialog}
            >
              <CropRotateIcon />
            </CropButton> */}
            {!editingImageAnnotations && (
              <TopRightButtons>
                <IconButton
                  permissionAction={permissionAction}
                  permissionParams={permissionParams}
                  onClick={openCropDialog}
                >
                  <CropRotateIcon />
                </IconButton>

                {canEditAnnotations && (
                  <IconButton
                    permissionAction={permissionAction}
                    permissionParams={permissionParams}
                    onClick={e => {
                      console.debug(
                        `MediaDetail.TopRightButtons.IconButton.onClick(): calling setEditingImageAnnotations(true)...`
                      )
                      setEditingImageAnnotations(true)
                    }}
                    sx={{
                      mt: 1,
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                )}
              </TopRightButtons>
            )}

            <CropExistingMediaDialog
              photo={photoToCrop}
              onDoneCropping={closeCropDialog}
              onCroppingCancelled={closeCropDialog}
            />
          </>
        )}
        <PrevButton
          permissionAction={ACTION_ALL_ACCESS}
          disabled={!canRequestPrev}
          onClick={onClickPrev}
        >
          <ChevronLeftIcon />
        </PrevButton>
        <MediaFile
          mediaDetail={mediaDetail}
          onPdfPageNumberChanged={onPdfPageNumberChanged}
          redrawPdf={redrawPdf}
          treeSlug={treeSlug}
          editingImageAnnotations={editingImageAnnotations}
          setEditingImageAnnotations={setEditingImageAnnotations}
          onAnnotationSvgUpdated={onAnnotationSvgUpdated}
        />
        <NextButton
          permissionAction={ACTION_ALL_ACCESS}
          disabled={!canRequestNext}
          onClick={onClickNext}
        >
          <ChevronRightIcon />
        </NextButton>
      </PhotoContainer>
      <SidePanel sidePanelWidth={sidePanelWidth}>
        <SidePanelMeta>
          <PhotoAuthor
            mediaDetail={mediaDetail}
            showTakenOnDate={showTakenOnDate}
            isBlog={isBlogTree}
          >
            {canEdit && (
              <EditMedia
                photo={mediaDetail}
                treeSlug={treeSlug}
                onFinishedDeleting={handleReportDeleted}
                onFinishedUpdating={handleRefresh}
                dialogTitle={editDialogTitle}
                showTranscription={showTranscription}
                showTakenOnDate={showTakenOnDate}
                hideTags={isBlogTree || hideTags}
                defaultAllowNoTags={defaultAllowNoTags}
                trigger={props => (
                  <Button
                    permissionAction={permissionAction}
                    permissionParams={permissionParams}
                    sx={{
                      ml: 1,
                    }}
                    size="small"
                    color="primary"
                    {...props}
                  >
                    Edit details
                  </Button>
                )}
              />
            )}
          </PhotoAuthor>
          <PhotoName mediaDetail={mediaDetail} />
          <MediaChips mediaDetail={mediaDetail} />
          <PhotoDescription mediaDetail={mediaDetail} />
          <PhotoDates mediaDetail={mediaDetail} />
          {showTranscription && (
            <TranscriptionMeta>
              {canEdit && clickedEditTranscription ? (
                <EditablePhotoTranscription
                  doSave={saveTranscription}
                  mediaDetail={mediaDetail}
                  transcriptionIndex={transcriptionIndex}
                  setClickedEditTranscription={setClickedEditTranscription}
                  // sidePanelIsWide always true
                />
              ) : (
                // not in edit mode
                <PhotoTranscription
                  transcriptionText={
                    mediaDetail.transcription
                      ? mediaDetail.transcription[transcriptionIndex]
                      : ''
                  }
                  treeSlug={treeSlug}
                  canEdit={canEdit}
                  offerEnterTranscription={offerEnterTranscription}
                  setExpandTranscription={setExpandTranscription}
                  setClickedEditTranscription={setClickedEditTranscription}
                  handleCollapseTranscriptionOnClick={() => {
                    setExpandTranscription(false)
                  }}
                  sidePanelIsWide={sidePanelIsWide}
                />
              )}
            </TranscriptionMeta>
          )}
        </SidePanelMeta>
        {!isBlogTree && (
          <Box>
            <PhotoComments
              mediaId={mediaDetail.id}
              comments={mediaDetail.comments}
            />
          </Box>
        )}
      </SidePanel>
    </Root>
  )
}

export const MediaFile = ({
  mediaDetail,
  onPdfPageNumberChanged,
  redrawPdf,
  treeSlug,
  editingImageAnnotations,
  setEditingImageAnnotations,
  onAnnotationSvgUpdated,
}) => {
  console.debug(
    `MediaDetail.MediaFile rendering: editingImageAnnotations: ${editingImageAnnotations}, mediaDetail.type: ${mediaDetail.type}, mediaDetail:`,
    mediaDetail
  )

  if (mediaDetail.type === MEDIA_TYPE_PHOTO) {
    if (mediaDetail.annotationsSvg || editingImageAnnotations) {
      // console.debug(
      //   `MediaDetail.MediaFile: annotationSvg is set, rendering AnnotatedImage... editingImageAnnotations: ${editingImageAnnotations}, geoJson:`,
      //   geoJson
      // )
      return (
        <AnnotatedImage
          treeSlug={treeSlug}
          file={mediaDetail.fileMedium}
          type={mediaDetail.type}
          imageWidthPx={mediaDetail.width}
          imageHeightPx={mediaDetail.height}
          annotationSvg={mediaDetail.annotationsSvg}
          //originalImageWidthPx={mediaDetail.width}
          //originalImageHeightPx={mediaDetail.height}
          originalImageWidthPx={mediaDetail.mediumImageWidth}
          originalImageHeightPx={mediaDetail.mediumImageHeight}
          editingImageAnnotations={editingImageAnnotations}
          setEditingImageAnnotations={setEditingImageAnnotations}
          onAnnotationSvgUpdated={onAnnotationSvgUpdated}
        />
      )
    } else {
      return <FullMedia file={mediaDetail.fileMedium} type={mediaDetail.type} />
    }
  } else if (
    mediaDetail.type === MEDIA_TYPE_VIDEO ||
    mediaDetail.type === MEDIA_TYPE_VIDEO_YOUTUBE
  ) {
    return <VideoPlayer {...mediaDetail} />
  } else if (mediaDetail.type === MEDIA_TYPE_PDF) {
    return (
      <PDFViewer
        {...mediaDetail}
        onPageNumberChanged={onPdfPageNumberChanged}
        redraw={redrawPdf}
      />
    )
  } else {
    return null
  }
}

export const PhotoAuthor = ({
  mediaDetail,
  children,
  showTakenOnDate,
  isBlog,
}) => {
  const { uploadedBy } = mediaDetail

  const displayName = formatIndividualName(uploadedBy)

  return (
    <>
      <Stack direction="row" alignItems="center">
        <ProfilePicture user={uploadedBy} size={40} sx={{ mr: 2 }} />
        <AuthorName>{displayName}</AuthorName>
      </Stack>
      <Stack direction="row">
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            // alignItems: 'center',
          }}
        >
          {showTakenOnDate && <PhotoOriginDisplay {...mediaDetail} />}
          <Box
            mt={1}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              // justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            {mediaDetail.visibility != null && !isBlog && (
              <InstanceVisibilityControl
                id={mediaDetail.id}
                author={mediaDetail.uploadedBy}
                visibility={mediaDetail.visibility}
                type="media"
                iconSize="small"
                sx={{ ml: 0.5 }}
              />
            )}
          </Box>
        </Box>
        <Box
          mt={1}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          {children}
        </Box>
      </Stack>
    </>
  )
}

export const PhotoDates = ({ mediaDetail }) => {
  return (
    <Box display="flex" flexDirection={'column'} mt={0.6}>
      {mediaDetail?.dateTakenGed && (
        <Typography variant="hint" color="textSecondary">
          Date taken: {convertUIGedDate(mediaDetail.dateTakenGed)}
        </Typography>
      )}
      <Typography variant="hint" color="textSecondary">
        Date added: {formatDate(mediaDetail.created)}
      </Typography>
    </Box>
  )
}

export const PhotoName = ({ mediaDetail }) => {
  const imgDisplayName = handleImgName(mediaDetail.title)
  return (
    <Typography
      variant="h4"
      color="primary"
      my={1.4}
      sx={{ wordBreak: 'break-word', fontSize: 20 }}
    >
      {imgDisplayName}
    </Typography>
  )
}

export const PhotoDescription = ({ mediaDetail }) => {
  if (!mediaDetail.description) {
    return null
  }
  return (
    <Box sx={{ mt: 2 }}>
      <Typography color="textSecondary">{mediaDetail.description}</Typography>
    </Box>
  )
}

export const EditablePhotoTranscription = ({
  mediaDetail,
  transcriptionIndex,
  doSave,
  setClickedEditTranscription,
}) => {
  return (
    <>
      <Formik
        initialValues={{
          transcription: htmlToEditorState(
            mediaDetail.transcription
              ? mediaDetail.transcription[transcriptionIndex] ?? ''
              : ''
          ),
        }}
      >
        {({ handleSubmit, isSubmitting, setFieldValue, values }) => {
          return (
            <SavingFormikRichTextField
              fieldName="transcription"
              label="Transcription"
              placeholderText="Transcribe the page text..."
              dontOverrideRichTextBodyStyle={true}
              //this style gets un-applied when the block is saved so it looks jumpy  richTextBodyClass={classes.richTextBody}
              doSave={doSave}
            />
          )
        }}
      </Formik>
      <Button
        permissionAction={ACTION_ALL_ACCESS}
        sx={{ mt: 2 }}
        size="small"
        color="primary"
        onClick={() => setClickedEditTranscription(false)}
      >
        Save transcription
      </Button>
    </>
  )
}

export const PhotoTranscription = ({
  transcriptionText,
  treeSlug,
  setExpandTranscription,
  canEdit,
  offerEnterTranscription,
  setClickedEditTranscription,
  handleCollapseTranscriptionOnClick,
  sidePanelIsWide,
}) => {
  const TextOnlyBlock = styled('div')(({ theme }) => ({
    fontFamily: "At Hauss Std', sans - serif",
    fontWeight: '100',
  }))

  const ButtonBar = styled('div')(({ theme }) => ({
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    backgroundColor: theme?.colors?.background,
  }))

  const hasTranscription = stringContainsText(transcriptionText)

  return (
    <>
      {hasTranscription && (
        <>
          Transcription
          <br />
        </>
      )}
      {sidePanelIsWide ? (
        <ButtonBar>
          <Button
            permissionAction={ACTION_ALL_ACCESS}
            size="small"
            color="primary"
            onClick={() => handleCollapseTranscriptionOnClick()}
          >
            Collapse transcription &gt;
          </Button>

          {canEdit && (
            <Button
              permissionAction={ACTION_ALL_ACCESS}
              size="small"
              color="primary"
              onClick={() => {
                setClickedEditTranscription(true)
              }}
            >
              Edit transcription
            </Button>
          )}
        </ButtonBar>
      ) : // side panel collapsed
      hasTranscription ? (
        <Button
          permissionAction={ACTION_ALL_ACCESS}
          size="small"
          color="primary"
          onClick={() => setExpandTranscription(true)}
        >
          Expand transcription
        </Button>
      ) : (
        // side panel collapsed, no transcription yet
        canEdit &&
        offerEnterTranscription && (
          <Button
            permissionAction={ACTION_ALL_ACCESS}
            size="small"
            color="primary"
            onClick={() => {
              setExpandTranscription(true)
              setClickedEditTranscription(true)
            }}
          >
            Enter transcription
          </Button>
        )
      )}
      {hasTranscription && (
        <TextOnlyBlock>
          <TextBlock
            layout={CONTENT_BLOCK_LAYOUT_TEXT_BLOCK}
            textContent={transcriptionText}
            //sources
            isPublic={false}
            treeSlug={treeSlug}
          ></TextBlock>
        </TextOnlyBlock>
      )}
    </>
  )
}

export const MediaChips = ({ mediaDetail }) => {
  return <LinkChips links={mediaDetail.links} sx={{ mt: 1 }} />
}

export const PhotoOriginDisplay = ({ created, dateTakenGed }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      {dateTakenGed ? (
        <Typography>Taken {convertUIGedDate(dateTakenGed)}</Typography>
      ) : null}
      {/* <Tooltip title={`Added ${formatDate(created)}`}>
        <AccessTimeFilledIcon
          fontSize="small"
          sx={{ ml: 1, verticalAlign: 'middle', display: 'inline-flex' }}
        />
      </Tooltip> */}
    </Box>
  )
}

const PhotoComments = ({ mediaId, comments }) => {
  const dispatchCreatePhotoCommment = useActionDispatcher(createPhotoComment)
  const dispatchDeletePhotoCommment = useActionDispatcher(deletePhotoComment)

  // NewComment component expects a return value that it can `await`
  // to know when the comment has been added.
  const createComment = ({ contentId, text }) =>
    dispatchCreatePhotoCommment({ text, mediaId: contentId })

  const handleDeletePhotoComment = ({ id, contentId }) => {
    dispatchDeletePhotoCommment({ commentId: id, mediaId: contentId })
  }

  const user = useSelector(selectUser)
  return (
    <Comments
      contentId={mediaId}
      canComment={true}
      comments={comments || []}
      user={user}
      onDelete={handleDeletePhotoComment}
      onSubmitComment={createComment}
      gutter={3}
    />
  )
}

export default withOverridableComponent(MediaDetail, 'MediaDetail')
